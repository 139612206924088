import { Search } from "@mui/icons-material";
import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers";
import { setStatus } from "../../redux/reducers/config";
import { enqueueSnackbar } from "../../redux/reducers/snackbar";
import LiftService from "../../services/LiftService";
import { parseDate } from "../../utils/parse";
import InfoBox from "../Info/InfoBox";
import InfoList from "../Info/InfoList";
import InfoRow from "../Info/InfoRow";
import useEnum from "../../hooks/useEnum";

interface Props {
  lift?: Lift;
  hideDetails?: boolean;
  hideTitle?: boolean;
  syncCallback?: () => void;
}

const LiftInfo = ({ lift, hideDetails = false, hideTitle = false, syncCallback }: Props) => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentLift", { returnObjects: true });

  const enumName = useEnum(auth);

  const dispatch = useDispatch();
  const handleClickSync = async () => {
    try {
      if (!auth || !lift) return;
      dispatch(setStatus("loading"));
      await LiftService.syncLift(auth, lift.id);
      dispatch(setStatus("ok"));
      syncCallback?.();
    } catch (error: any) {
      dispatch(setStatus("error"));
      dispatch(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
    }
  };

  return (
    <>
      {!hideTitle && (
        <Typography variant="subtitle2" gutterBottom>
          {lang.LiftInfo.승강기정보} ({lift?.dataSyncYmd})
        </Typography>
      )}
      <InfoList>
        <InfoRow>
          <InfoBox title={lang.LiftInfo.승강기번호} flex={1} valueSx={{ flex: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2">{lift?.equipmentNo}</Typography>
              {auth && auth.auth !== "O" && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Search />}
                  onClick={handleClickSync}
                >
                  {lang.LiftInfo.승강기정보_갱신}
                </Button>
              )}
            </Box>
          </InfoBox>
          <InfoBox title={lang.LiftInfo.제조업체} flex={1} valueSx={{ flex: 2 }}>
            <Typography variant="body2">{lift?.mfCpnyNm}</Typography>
          </InfoBox>
        </InfoRow>
        <InfoRow>
          <InfoBox title={lang.LiftInfo.건물명} flex={1}>
            <Typography variant="body2">{lift?.site?.buldNm}</Typography>
          </InfoBox>
          <InfoBox title={lang.LiftInfo.설치위치_호기} flex={1}>
            <Typography variant="body2">{lift?.itlpc}</Typography>
          </InfoBox>
          <InfoBox title={lang.LiftInfo.모델명} flex={1}>
            <Typography variant="body2">{lift?.modelName}</Typography>
          </InfoBox>
        </InfoRow>
        <InfoRow>
          <InfoBox title={lang.LiftInfo.소재지} flex={2} valueSx={{ flex: 3 }}>
            <Typography variant="body2">
              {lift?.site?.address1}
              {/* lift?.site?.address2 */}
            </Typography>
          </InfoBox>
          <InfoBox title={lang.LiftInfo.종류} flex={1}>
            <Typography variant="body2">{enumName("LiftType", lift?.liftType)}</Typography>
          </InfoBox>
        </InfoRow>
        {!hideDetails && (
          <>
            <InfoRow>
              <InfoBox title={lang.LiftInfo.적재하중} flex={1}>
                <Typography variant="body2">{lift?.loadngLd}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.최대정원} flex={1}>
                <Typography variant="body2">{lift?.ratedCap}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.정격속도} flex={1}>
                <Typography variant="body2">{lift?.ratedSpd}</Typography>
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.LiftInfo.운행구간} flex={1}>
                <Typography variant="body2">{lift?.operSctn}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.운행층수} flex={1}>
                <Typography variant="body2">{lift?.operFlrCnt}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.상태} flex={1}>
                <Typography variant="body2">{lift?.status}</Typography>
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.LiftInfo.지상층수} flex={1}>
                <Typography variant="body2">{lift?.groundFlrCnt}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.지하층수} flex={1}>
                <Typography variant="body2">{lift?.undgrndFloorCnt}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.유효종료일} flex={1}>
                <Typography variant="body2">{parseDate(lift?.validEndYmd)}</Typography>
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.LiftInfo.설치일} flex={1}>
                <Typography variant="body2">{parseDate(lift?.instlYmd)}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.최초설치일} flex={1}>
                <Typography variant="body2">{parseDate(lift?.frstInstlYmd)}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.최종검사일_결과} flex={1}>
                <Typography variant="body2">{parseDate(lift?.lastInspctYmd)}</Typography>
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.LiftInfo.관리주체명} flex={1}>
                <Typography variant="body2">{lift?.mangeNm}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.관리주체연락처} flex={1}>
                <Typography variant="body2">{lift?.mangeTelno}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.검사예정일} flex={1}>
                <Typography variant="body2">{parseDate(lift?.fdrmChkYmd)}</Typography>
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.LiftInfo.유지보수_업체명} flex={1}>
                <Typography variant="body2">{lift?.mntCpnyNm}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.유지보수_업체_연락처} flex={1}>
                <Typography variant="body2">{lift?.mntCpnyTelno}</Typography>
              </InfoBox>
              <InfoBox title={lang.LiftInfo.하도_업체명} flex={1}>
                <Typography variant="body2">{lift?.subcntrCpny}</Typography>
              </InfoBox>
            </InfoRow>
          </>
        )}
      </InfoList>
    </>
  );
};

LiftInfo.defaultProps = {
  lift: undefined,
  hideDetails: false,
  hideTitle: false,
  syncCallback: undefined,
};

export default LiftInfo;
