import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers";
import ControllerService from "../../services/ControllerService";
import StateService, { GetLiftLastestDiagnosisesResponse } from "../../services/StateService";
import { parseDate, parseValue } from "../../utils/parse";
import Paper from "../Paper";

interface Props {
  liftId: number | string;
}

const LiftLatestDiagnosises = ({ liftId }: Props) => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentLift", { returnObjects: true });

  const [sensorDiagnosis, setSensorDiagnosis] = useState<GetLiftLastestDiagnosisesResponse>();
  const [controllerDiagnosis, setControllerDiagnosis] = useState<ControllerDiagnosis[]>([]);

  useEffect(() => {
    (async () => {
      if (!auth.auth) return;
      const sensor = await StateService.getLiftLatestDiagnosises(liftId, auth);
      const controller = await ControllerService.getControllerStatus({ liftId, size: 1 }, auth);
      setSensorDiagnosis(sensor);
      setControllerDiagnosis(controller._embedded ? controller._embedded.resultLiftCntlrs : []);
    })();
  }, [liftId, auth]);

  if (!sensorDiagnosis) return null;

  return (
    <Box pb={2}>
      <Typography variant="subtitle2" gutterBottom>
        {lang.LiftLatestDiagnosises.상태정보}
        {sensorDiagnosis.liftDiagnosisExecution
          ? `(${lang.LiftLatestDiagnosises.점검일} : ${parseDate(
              sensorDiagnosis.liftDiagnosisExecution.dgnssAt,
            )})`
          : null}
      </Typography>
      <Paper p={2}>
        {/* <Box>
          <Typography variant="subtitle2" gutterBottom>
            센서
          </Typography>
          {sensorDiagnosis.dCodes && sensorDiagnosis.dCodes.length > 0 ? (
            sensorDiagnosis.dCodes.map((code) => (
              <Typography key={code.id} variant="body2">
                {code.diagnosisMsg}
              </Typography>
            ))
          ) : (
            <Typography variant="body2">
              {sensorDiagnosis.liftDiagnosisExecution
                ? `[ ${parseValue(sensorDiagnosis.liftDiagnosisExecution.dgnssAt)} ] 정상입니다.`
                : "등록된 데이터가 없습니다."}
            </Typography>
          )}
        </Box>
        <Divider sx={{ my: 1 }} /> */}
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            {lang.LiftLatestDiagnosises.제어반}
          </Typography>
          {controllerDiagnosis.length > 0 ? (
            controllerDiagnosis.map((controller) => (
              <Typography variant="body2" key={controller.id}>
                [ {parseValue(controller.checkedAt)} ] {lang.LiftLatestDiagnosises.정상}
              </Typography>
            ))
          ) : (
            <Typography variant="body2">{lang.LiftLatestDiagnosises.데이터없음}</Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default LiftLatestDiagnosises;
