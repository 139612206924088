import { Box, Chip, CircularProgress, Divider, styled, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DashboardService, { GetLiftCountListResponse } from "../../../services/DashboardService";
import Paper from "../../Paper";
import { RootState } from "../../../redux/reducers";
import ApexDonutChart from "../../Chart/ApexDonutChart";

const StyledBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

interface Props {
  liftId?: number;
}

type Statistics = (string | number)[];

/* 부품수명통계 */
const DashboardSummaryDiagnosis2 = ({ liftId }: Props) => {
  const { auth } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const lang: any = t("componentDashboard", { returnObjects: true });

  const theme = useTheme();
  const [statistics, setStatistics] = useState<Statistics[]>();

  useEffect(() => {
    (async () => {
      if (!auth.auth) return;
      if (!liftId) {
        const response = await DashboardService.getEquipmentPartGroupByStatus(auth);
        setStatistics(response);
      } else {
        const response = await DashboardService.getEquipmentPartGroupByLiftStatus(auth, liftId);
        setStatistics(response);
      }
    })();
  }, [auth, liftId]);

  return (
    <Paper marginTop="27px">
      <Box
        px={2}
        py={1.5}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Chip
          label={
            <Typography variant="subtitle2" fontFamily={'"Noto Sans KR"'} fontWeight={400}>
              {lang.DashboardSummaryDiagnosis2.부품_수명통계}
            </Typography>
          }
          color="success"
        />

        <div className="leglegend_circle" style={{ display: "flex" }}>
          <p>
            <span className="legend_circle_r" />
            <span>{lang.DashboardSummaryDiagnosis2.경과}</span>
          </p>
          <p>
            <span className="legend_circle_o" />
            <span />
            <span>{lang.DashboardSummaryDiagnosis2.임박}</span>
          </p>
          <p>
            <span className="legend_circle_g" />
            <span />
            <span>{lang.DashboardSummaryDiagnosis2.유효}</span>
          </p>
        </div>
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="space-around" flexWrap="wrap">
        {statistics?.map((d, i) => (
          <Box key={i}>
            <Box textAlign="center" pb={1}>
              <Typography variant="subtitle2" fontFamily={'"Noto Sans KR"'} fontWeight={300}>
                {d[0]}
              </Typography>
            </Box>
            <Box position="relative" className="chartbox">
              {/* <CircularProgress
                variant="determinate"
                value={100}
                size={150}
                thickness={4}
                sx={{
                  color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
                }}
              /> */}
              <ApexDonutChart
                name={String(d[0])}
                value={[Number(d[3]), Number(d[2]), Number(d[1])]}
              />
              {/* <CircularProgress
                color="error"
                variant="determinate"
                value={
                  count ? (count["cntlrStatus-11"] / count["cntlrStatus--total"]) * 100 || 0 : 0
                }
                size={90}
                thickness={4}
                sx={{
                  top: 0,
                  left: 0,
                  position: "absolute",
                }}
              /> */}
              {/* <StyledBox textAlign="center">
                <Typography variant="subtitle1" color="error.dark">
                  {d[3]}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  / {d[2]} / {d[1]}
                </Typography>
              </StyledBox> */}
            </Box>
          </Box>
        ))}

        {/* <Box>
          <Box textAlign="center" pb={1}>
            <Typography variant="subtitle2">{!owner ? "구동부" : "장애 상태"}</Typography>
          </Box>
          <Box position="relative">
            <CircularProgress
              variant="determinate"
              value={100}
              size={150}
              thickness={4}
              sx={{
                color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              }}
            />
            <CircularProgress
              color="error"
              variant="determinate"
              value={count ? (count["cntlrStatus-11"] / count["cntlrStatus--total"]) * 100 || 0 : 0}
              size={90}
              thickness={4}
              sx={{
                top: 0,
                left: 0,
                position: "absolute",
              }}
            />
            <StyledBox textAlign="center">
              <Typography variant="subtitle1" color="error.dark">
                {count && count["cntlrStatus-11"] ? count["cntlrStatus-11"] : 0}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                / {count && count.totalCount ? count.totalCount : 0}
              </Typography>
            </StyledBox>
          </Box>
        </Box>

        <Box>
          <Box textAlign="center" pb={1}>
            <Typography variant="subtitle2">{!owner ? "구동부" : "장애 상태"}</Typography>
          </Box>
          <Box position="relative">
            <CircularProgress
              variant="determinate"
              value={100}
              size={150}
              thickness={4}
              sx={{
                color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              }}
            />
            <CircularProgress
              color="error"
              variant="determinate"
              value={count ? (count["cntlrStatus-11"] / count["cntlrStatus--total"]) * 100 || 0 : 0}
              size={90}
              thickness={4}
              sx={{
                top: 0,
                left: 0,
                position: "absolute",
              }}
            />
            <StyledBox textAlign="center">
              <Typography variant="subtitle1" color="error.dark">
                {count && count["cntlrStatus-11"] ? count["cntlrStatus-11"] : 0}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                / {count && count.totalCount ? count.totalCount : 0}
              </Typography>
            </StyledBox>
          </Box>
        </Box>

        <Box>
          <Box textAlign="center" pb={1}>
            <Typography variant="subtitle2">{!owner ? "구동부" : "장애 상태"}</Typography>
          </Box>
          <Box position="relative">
            <CircularProgress
              variant="determinate"
              value={100}
              size={150}
              thickness={4}
              sx={{
                color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              }}
            />
            <CircularProgress
              color="error"
              variant="determinate"
              value={count ? (count["cntlrStatus-11"] / count["cntlrStatus--total"]) * 100 || 0 : 0}
              size={90}
              thickness={4}
              sx={{
                top: 0,
                left: 0,
                position: "absolute",
              }}
            />
            <StyledBox textAlign="center">
              <Typography variant="subtitle1" color="error.dark">
                {count && count["cntlrStatus-11"] ? count["cntlrStatus-11"] : 0}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                / {count && count.totalCount ? count.totalCount : 0}
              </Typography>
            </StyledBox>
          </Box>
        </Box>

        <Box>
          <Box textAlign="center" pb={1}>
            <Typography variant="subtitle2">{!owner ? "구동부" : "장애 상태"}</Typography>
          </Box>
          <Box position="relative">
            <CircularProgress
              variant="determinate"
              value={100}
              size={150}
              thickness={4}
              sx={{
                color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              }}
            />
            <CircularProgress
              color="error"
              variant="determinate"
              value={count ? (count["cntlrStatus-11"] / count["cntlrStatus--total"]) * 100 || 0 : 0}
              size={90}
              thickness={4}
              sx={{
                top: 0,
                left: 0,
                position: "absolute",
              }}
            />
            <StyledBox textAlign="center">
              <Typography variant="subtitle1" color="error.dark">
                {count && count["cntlrStatus-11"] ? count["cntlrStatus-11"] : 0}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                / {count && count.totalCount ? count.totalCount : 0}
              </Typography>
            </StyledBox>
          </Box>
        </Box>

        <Box>
          <Box textAlign="center" pb={1}>
            <Typography variant="subtitle2">{!owner ? "구동부" : "장애 상태"}</Typography>
          </Box>
          <Box position="relative">
            <CircularProgress
              variant="determinate"
              value={100}
              size={150}
              thickness={4}
              sx={{
                color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              }}
            />
            <CircularProgress
              color="error"
              variant="determinate"
              value={count ? (count["cntlrStatus-11"] / count["cntlrStatus--total"]) * 100 || 0 : 0}
              size={90}
              thickness={4}
              sx={{
                top: 0,
                left: 0,
                position: "absolute",
              }}
            />
            <StyledBox textAlign="center">
              <Typography variant="subtitle1" color="error.dark">
                {count && count["cntlrStatus-11"] ? count["cntlrStatus-11"] : 0}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                / {count && count.totalCount ? count.totalCount : 0}
              </Typography>
            </StyledBox>
          </Box>
        </Box> */}
      </Box>
    </Paper>
  );
};

DashboardSummaryDiagnosis2.defaultProps = {
  liftId: undefined,
};

export default DashboardSummaryDiagnosis2;
