import { Box, Grid, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Paper from "../../components/Paper";
import Select from "../../components/Select";
import { RootState } from "../../redux/reducers";
import LiftService from "../../services/LiftService";
import QuarterlyReport from "./QuarterlyReport";
import SiteService from "../../services/SiteService";
import ReportService, { QuarterList } from "../../services/ReportService";

const ReportPage = () => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [siteId, setSiteId] = useState<number | undefined>(
    Number(searchParams.get("siteId")) || undefined,
  );
  const [liftId, setLiftId] = useState<number | undefined>(
    Number(searchParams.get("liftId")) || undefined,
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [equipmentNo, setEquipmentNo] = useState<string | undefined>();
  const [year, setYear] = useState<number | undefined>();
  const [quarter, setQuarter] = useState<number | undefined>();
  const [siteOption, setSiteOption] = useState<SelectOption[]>([
    {
      label: "건물 선택",
      value: "",
    },
  ]);
  const [liftOption, setLiftOption] = useState<SelectOption[]>([
    {
      label: "승강기 선택",
      value: "",
    },
  ]);
  const [quarterList, setQuarterList] = useState<QuarterList[]>([]);

  // 최초에만 URL 파라미터에서 siteId, liftId 설정
  useEffect(() => {
    if (!isInitialized && searchParams.get("siteId") !== undefined) {
      setSiteId(Number(searchParams.get("siteId")) || undefined);
      setLiftId(Number(searchParams.get("liftId")) || undefined);
      setIsInitialized(true);
    }
  }, [searchParams, isInitialized]);

  // 사이트 목록 로드
  useEffect(() => {
    (async () => {
      const site = await SiteService.getAllSites(
        { page: 0, size: 2000, sortKey: "id", sortDirection: "asc", searchKey: "" },
        auth,
      );
      if (site._embedded && site._embedded.sites.length > 0) {
        setSiteOption([
          {
            label: "건물 선택",
            value: "",
          },
          ...site._embedded.sites.map((r) => ({
            label: r.buldNm,
            value: r.id,
          })),
        ]);
      }
    })();
  }, [auth]);

  // 리프트 목록 로드
  useEffect(() => {
    (async () => {
      if (!siteId) {
        setLiftOption([{ label: "승강기 선택", value: "" }]);
        return;
      }
      const lift = await LiftService.getAllLifts2(
        {
          page: 0,
          size: 2000,
          sortKey: "id",
          sortDirection: "asc",
          searchKey: "equipment.site.id",
          searchValue: siteId,
        },
        auth,
      );
      if (lift._embedded && lift._embedded.lifts.length > 0) {
        const newLiftOption = [
          { label: "승강기 선택", value: "" },
          ...lift._embedded.lifts.map((r) => ({
            label: `${r.itlpc}`,
            value: r.id,
            data: r.equipmentNo,
          })),
        ];
        setLiftOption(newLiftOption);

        // 리프트 옵션이 로드된 후에 liftId가 반영되도록
        if (isInitialized && liftId) {
          setLiftId(liftId);
        }
      } else {
        setLiftOption([{ label: "승강기 선택", value: "" }]);
      }
    })();
  }, [auth, siteId, liftId, isInitialized]);

  // 리프트 선택 시 보고서 데이터 로드
  useEffect(() => {
    (async () => {
      if (!liftId) {
        setYear(undefined);
        setQuarter(undefined);
        setQuarterList([]);
        return;
      }
      const result = await ReportService.getReportList(liftId, auth);
      setQuarterList(result);
    })();
  }, [auth, liftId]);

  return (
    <Grid item container lg={12}>
      <Grid item lg={10}>
        <Paper>
          <Box mb="10px" boxShadow="0 0 1px">
            <Box display="inline-block" width="50%">
              <Select
                options={siteOption}
                value={siteId || ""}
                onChange={(name, option) => setSiteId(Number(option.value))}
                name="site"
              />
            </Box>
            <Box display="inline-block" width="50%">
              <Select
                options={liftOption}
                value={liftId || ""}
                onChange={(name, option) => {
                  setLiftId(Number(option.value));
                  setEquipmentNo(option.data);
                }}
                name="lift"
              />
            </Box>
          </Box>
          <QuarterlyReport
            siteId={siteId}
            liftId={liftId}
            equipmentNo={equipmentNo}
            year={year}
            quarter={quarter}
          />
        </Paper>
      </Grid>
      <Grid item lg={2}>
        <Paper>
          <List dense>
            {quarterList.length === 0 ? (
              <ListItem>
                <ListItemText>불러올 보고서가 없습니다.</ListItemText>
              </ListItem>
            ) : (
              quarterList.map((r) => (
                <ListItemButton
                  key={r.key}
                  onClick={() => {
                    setYear(r.year);
                    setQuarter(r.quarter);
                  }}
                >
                  <ListItemText>{r.label}</ListItemText>
                </ListItemButton>
              ))
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ReportPage;
