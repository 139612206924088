import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers";
import LiftService from "../../services/LiftService";
import LiftInfo from "../Lift/LiftInfo";
import LiftMaintainenceCompanyInfo from "../Lift/LiftMaintainenceCompanyInfo";

interface Props {
  liftId?: number | string;
}

const SensorDetailLift = ({ liftId }: Props) => {
  const [lift, setLift] = useState<Lift>();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentSensor", { returnObjects: true });

  useEffect(() => {
    (async () => {
      if (liftId && auth) {
        const response = await LiftService.getLift(liftId, auth);
        setLift(response);
      }
    })();
  }, [liftId, auth]);

  // const company = useMemo(() => {
  //   return getMaintenanceCompany(lift);
  // }, [lift]);

  if (!lift) return null;

  return (
    <Box>
      <Box pb={2}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.SensorDetailLift.승강기_정보}
        </Typography>
        <LiftInfo lift={lift} />
      </Box>
      <LiftMaintainenceCompanyInfo lift={lift} />
    </Box>
  );
};

SensorDetailLift.defaultProps = {
  liftId: undefined,
};

export default SensorDetailLift;
