import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers";
import SiteService from "../../services/SiteService";
import Table from "../../components/Table/Table";
import { liftUserColumns } from "../../utils/columns";
import useTable from "../../hooks/useTable";
import SiteInfo from "../../components/Site/SiteInfo";
import SiteOwnerInfo from "../../components/Site/SiteOwnerInfo";
import useTableParams from "../../hooks/useTableParams";
import { setStatus } from "../../redux/reducers/config";

const SiteDetail = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("common", { returnObjects: true });

  const navigate = useNavigate();
  const { id } = useParams();
  const [site, setSite] = useState<Site | undefined>(undefined);
  // const [lifts, setLifts] = useState<Lift>();
  const { data, onChangeData, totalElement } = useTable();
  const { page, rowsPerPage, sortKey, sortDirection, onChangeOrder, onChangeOption } =
    useTableParams();

  const handleClickBack = () => {
    navigate(`/site`);
  };

  const getSite = useCallback(async () => {
    try {
      if (!id || !auth) return;
      dispatch(setStatus("loading"));
      const newSite = await SiteService.getSite(id, auth);
      setSite(newSite);
      dispatch(setStatus("ok"));
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      dispatch(setStatus("error"));
    }
  }, [auth, id, setSite, dispatch]);

  const getLifts = useCallback(async () => {
    try {
      if (!id || !auth) return;
      dispatch(setStatus("loading"));
      const lifts = await SiteService.getAllLiftBySite(
        {
          page,
          size: rowsPerPage,
          sortKey,
          sortDirection,
          siteId: id,
        },
        auth,
      );
      onChangeData({
        data: lifts._embedded ? lifts._embedded.lifts : [],
        totalPage: lifts.page.totalPages,
        totalElement: lifts.page.totalElements,
      });
      dispatch(setStatus("ok"));
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      dispatch(setStatus("error"));
    }
  }, [id, auth, onChangeData, page, rowsPerPage, sortDirection, sortKey, dispatch]);

  useEffect(() => {
    getSite();
  }, [id, getSite]);

  useEffect(() => {
    getLifts();
  }, [site, getLifts]);

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>
      <Grid item lg={6}>
        <SiteInfo site={site} />
      </Grid>
      <Grid item lg={6}>
        <SiteOwnerInfo siteId={site?.id} owner={site?.owner} onAfterSubmit={getSite} />
      </Grid>
      <Grid item lg={12}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.승강기} {lang.정보}
        </Typography>
        <Paper>
          <Table
            order={sortDirection}
            orderBy={sortKey}
            page={page}
            rowsPerPage={rowsPerPage}
            data={data}
            columns={liftUserColumns}
            totalElement={totalElement}
            onChangeOption={onChangeOption}
            onChangeOrder={onChangeOrder}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default SiteDetail;
