import { ArrowBack, Edit, Save } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoBox from "../../components/Info/InfoBox";
import InfoList from "../../components/Info/InfoList";
import InfoRow from "../../components/Info/InfoRow";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { RootState } from "../../redux/reducers";
import GatewayService from "../../services/GatewayService";

/** 커스텀 해야함 */
const options: SelectOption[] = [
  { label: "NAON", value: "NAON" },
  { label: "ERC", value: "ERC" },
];

const GatewayRegist = () => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("GatewayDetail", { returnObjects: true });

  const { id } = useParams();
  const navigate = useNavigate();
  const handleClickBack = () => navigate("/gateway");
  const [value, setValue] = useState<Partial<IotGateway>>({
    sn: "",
    madeBy: "",
    bleMac: "",
    wifiMac: "",
    apSsid: "",
    apPassword: "",
    apGwIp: "",
    apPort: "",
    apChannel: "",
    fwVer: "",
    intrvl: 0,
    // equipments: [],
  });

  useEffect(() => {
    (async () => {
      if (!id || !auth) return;
      const response = await GatewayService.getGateway(id, auth);
      setValue(response);
    })();
  }, [id, auth]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: inputValue } = event.target;
    setValue((prev) => ({ ...prev, [name]: inputValue }));
  }, []);

  const {
    madeBy,
    sn,
    bleMac,
    wifiMac,
    apSsid,
    apPassword,
    apGwIp,
    apPort,
    apChannel,
    intrvl,
    fwVer,
  } = value;

  const isEdit = Boolean(id);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      event.preventDefault();

      if (!auth.auth) return;
      let response: IotGateway;

      if (id) {
        response = await GatewayService.updateGateway(id, value as IotGateway, auth);
      } else {
        response = await GatewayService.addGateway(value as IotGateway, auth);
      }

      navigate(`/gateway/detail/${response.id}`);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const onChangeSelect = (name: string, option: SelectOption) => {
    setValue((prev) => ({ ...prev, [name]: option.value }));
  };

  const handleClickCancel = () => navigate(-1);

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>
      <Grid item lg={8}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.게이트웨이_정보}
        </Typography>
        <form onSubmit={onSubmit}>
          <InfoList>
            <InfoRow>
              <InfoBox title={lang.제조사} flex={1} valueSx={{ flex: 3 }}>
                <Select
                  value={madeBy}
                  name="madeBy"
                  options={options}
                  onChange={onChangeSelect}
                  required
                />
              </InfoBox>
              <InfoBox title={`${lang.SN}*`} flex={1} valueSx={{ flex: 3 }}>
                <Input name="sn" value={sn} onChange={onChange} required fullWidth />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.BLE_MAC} flex={1} valueSx={{ flex: 3 }}>
                <Input name="bleMac" value={bleMac} onChange={onChange} fullWidth />
              </InfoBox>
              <InfoBox title={lang.WIFI_MAC} flex={1} valueSx={{ flex: 3 }}>
                <Input name="wifiMac" value={wifiMac} onChange={onChange} fullWidth />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.SSID} flex={1} valueSx={{ flex: 3 }}>
                <Input name="apSsid" value={apSsid} onChange={onChange} fullWidth />
              </InfoBox>
              <InfoBox title={lang.PW} flex={1} valueSx={{ flex: 3 }}>
                <Input name="apPassword" value={apPassword} onChange={onChange} fullWidth />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.IP} flex={1} valueSx={{ flex: 3 }}>
                <Input name="apGwIp" value={apGwIp} onChange={onChange} fullWidth />
              </InfoBox>
              <InfoBox title={lang.PORT} flex={1} valueSx={{ flex: 3 }}>
                <Input name="apPort" value={apPort} onChange={onChange} fullWidth />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.AP_Channel} flex={1} valueSx={{ flex: 3 }}>
                <Input name="apChannel" value={apChannel} onChange={onChange} fullWidth />
              </InfoBox>
              <InfoBox title={lang.펌웨어_버전} flex={1} valueSx={{ flex: 3 }}>
                <Input name="fwVer" value={fwVer} onChange={onChange} fullWidth />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.진단주기} flex={1} valueSx={{ flex: 3 }}>
                <Input
                  name="intrvl"
                  value={intrvl ? intrvl.toString() : ""}
                  onChange={onChange}
                  fullWidth
                />
              </InfoBox>
              <InfoBox title="" flex={1} valueSx={{ flex: 3 }}>
                <Typography variant="body2"> </Typography>
              </InfoBox>
            </InfoRow>
          </InfoList>
          <Box pt={2} display="flex" justifyContent="end">
            {isEdit ? (
              <Button variant="contained" color="secondary" type="submit" startIcon={<Edit />}>
                {lang.수정}
              </Button>
            ) : (
              <Button variant="contained" type="submit" startIcon={<Save />}>
                {lang.등록}
              </Button>
            )}
            <Button onClick={handleClickCancel} sx={{ ml: 1 }}>
              {lang.취소}
            </Button>
          </Box>
        </form>
      </Grid>
    </>
  );
};

export default GatewayRegist;
