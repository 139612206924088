import React from "react";
import { Typography, Box, Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoList from "../../../Info/InfoList";
import InfoRow from "../../../Info/InfoRow";
import InfoBox from "../../../Info/InfoBox";
import Paper from "../../../Paper";

interface Props {
  vibrations: LiftVibration[];
}

const StyledHead = styled(Grid)(({ theme }) => ({
  background: theme.palette.grey[100],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledCell = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DiagnosisDetailVibrations = ({ vibrations }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  return (
    <>
      {vibrations.map((vibration) => (
        <Box key={vibration.id} pt={2}>
          <InfoList>
            <InfoRow>
              <InfoBox title={lang.DiagnosisDetailVibrations.센서ID} flex={1}>
                <Typography variant="body2">{vibration.sensor?.id}</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailVibrations.구분} flex={1}>
                <Typography variant="body2">{lang.DiagnosisDetailVibrations.진동}</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailVibrations.에러코드} flex={1}>
                <Typography variant="body2">{vibration.errCode}</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailVibrations.진단_요약} flex={1}>
                <Typography variant="body2">{vibration.dcode?.diagnosisMsg}</Typography>
              </InfoBox>
            </InfoRow>
          </InfoList>

          <Paper sx={{ mt: 2 }}>
            <Grid container>
              <StyledRow container item md={12}>
                <StyledHead item md={4}>
                  <Typography variant="subtitle2">
                    {lang.DiagnosisDetailVibrations.진동량}
                  </Typography>
                </StyledHead>
                <StyledHead item md={3}>
                  <Typography variant="subtitle2">
                    {lang.DiagnosisDetailVibrations.이상진동}
                  </Typography>
                </StyledHead>
                <StyledHead item md={2}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailVibrations.등급}</Typography>
                </StyledHead>
                <StyledHead item md={3}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailVibrations.결과}</Typography>
                </StyledHead>
              </StyledRow>
              <StyledRow container item md={12}>
                <StyledHead item md={1.5}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailVibrations.축}</Typography>
                </StyledHead>
                <StyledCell item md={2.5}>
                  <Typography variant="body2">{vibration.shaftVal}</Typography>
                </StyledCell>
                <StyledCell item md={3}>
                  <Typography variant="body2">{vibration.shaftAbnrmlYn ? "Y" : "N"}</Typography>
                </StyledCell>
                <StyledCell item md={2}>
                  <Typography variant="body2">{vibration.shaftGrd}</Typography>
                </StyledCell>
                <StyledCell item md={3}>
                  <Typography variant="body2">{vibration.shaftDMsg}</Typography>
                </StyledCell>
              </StyledRow>
              <StyledRow container item md={12}>
                <StyledHead item md={1.5}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailVibrations.반경}</Typography>
                </StyledHead>
                <StyledCell item md={2.5}>
                  <Typography variant="body2">{vibration.horizonVal}</Typography>
                </StyledCell>
                <StyledCell item md={3}>
                  <Typography variant="body2">{vibration.horizonAbnrmlYn ? "Y" : "N"}</Typography>
                </StyledCell>
                <StyledCell item md={2}>
                  <Typography variant="body2">{vibration.horizonGrd}</Typography>
                </StyledCell>
                <StyledCell item md={3}>
                  <Typography variant="body2">{vibration.horizonDMsg}</Typography>
                </StyledCell>
              </StyledRow>
              <Grid container item md={12}>
                <StyledHead item md={1.5}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailVibrations.수직}</Typography>
                </StyledHead>
                <StyledCell item md={2.5}>
                  <Typography variant="body2">{vibration.verticalVal}</Typography>
                </StyledCell>
                <StyledCell item md={3}>
                  <Typography variant="body2">{vibration.verticalAbnrmlYn ? "Y" : "N"}</Typography>
                </StyledCell>
                <StyledCell item md={2}>
                  <Typography variant="body2">{vibration.verticalGrd}</Typography>
                </StyledCell>
                <StyledCell item md={3}>
                  <Typography variant="body2">{vibration.verticalDMsg}</Typography>
                </StyledCell>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      ))}
    </>
  );
};

export default DiagnosisDetailVibrations;
