import React, { useCallback, useEffect, useRef, useState } from "react";
import "./css/print.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  BarControllerChartOptions,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { _DeepPartialObject } from "chart.js/types/utils";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReportService, { LiftError } from "../../services/ReportService";
import { comma, parseDate, parseKSTDate } from "../../utils/parse";
import LiftService from "../../services/LiftService";
import { RootState } from "../../redux/reducers";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type BarChartType =
  | _DeepPartialObject<
      CoreChartOptions<"bar"> &
        ElementChartOptions<"bar"> &
        PluginChartOptions<"bar"> &
        DatasetChartOptions<"bar"> &
        ScaleChartOptions<"bar"> &
        BarControllerChartOptions
    >
  | undefined;

type TableData = {
  label: string;
  data: number;
  dataAround?: number;
};

const option1 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right" as const,
      labels: {
        font: {
          family: "'Noto Sans KR', 'serif'",
          lineHeight: 1,
        },
      },
    },
  },
};

const option2 = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "left" as const,
      labels: {
        font: {
          family: "'Noto Sans KR', 'serif'",
          lineHeight: 1,
        },
      },
    },
  },
};

const option3: BarChartType = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      align: "end",
      text: "[높은 순서5개]",
      padding: {
        top: 0,
        bottom: 10,
      },
    },
    legend: {
      position: "bottom",
      align: "end",
      labels: {
        padding: 0,
        font: {
          family: "'Noto Sans KR', 'serif'",
          lineHeight: 1,
        },
      },
    },
  },
};

interface Props {
  siteId?: number;
  liftId?: number;
  equipmentNo?: string;
  year?: number;
  quarter?: number;
}

const QuarterlyReport = ({ siteId, liftId, equipmentNo, year, quarter }: Props) => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const print = () => {
    window.print();
  };
  const zoomIn = () => {
    setZoomLevel((prev) => prev + 0.1);
  };
  const zoomOut = () => {
    setZoomLevel((prev) => prev - 0.1);
  };

  const sencondToHour = (second: number) => Math.round(second / 360) / 10;

  const [lift, setLift] = useState<Lift>();
  const [datas, setDatas] = useState<ChartData<"bar">[]>([]);
  const [tableData1, setTableData1] = useState<TableData[]>([]);
  const [tableData2, setTableData2] = useState<TableData[]>([]);
  const [tableData3, setTableData3] = useState<LiftError[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  // const [chartLabels, setChartLabels] = useState<string[]>([]);

  /**
   * type: 1=운행횟수 2=주행시간 3=제어반에러코드
   * chartData: number[]
   * chartLabels: string[] - type 1,2=3개 3=5개
   */
  const printRef1 = useRef<HTMLDivElement>(null);
  const printRef2 = useRef<HTMLDivElement>(null);
  const printRef3 = useRef<HTMLDivElement>(null);
  const pdfSave = async () => {
    if (!printRef1.current) return;
    if (!printRef2.current) return;
    if (!printRef3.current) return;
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF("p", "mm", "a4");

    const addPageToPDF = async (ref: React.RefObject<HTMLDivElement>, addPage: boolean = false) => {
      if (ref.current) {
        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const position = 0;

        if (addPage) pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      }
    };

    // 페이지들을 PDF에 추가
    await addPageToPDF(printRef1);
    await addPageToPDF(printRef2, true);
    await addPageToPDF(printRef3, true);

    // PDF 다운로드
    pdf.save(`${year}년 ${quarter}분기 승강기 상태 분기 보고서(${equipmentNo}.pdf`);
  };

  const printPage = async () => {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>${year}년 ${quarter}분기 승강기 상태 분기 보고서(${equipmentNo})</title>
            <link rel="stylesheet" href="/css/print.css">
          </head>
          <body>
            ${document.getElementById("printArea")?.outerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();

      const preloadImages = (urls: any) => {
        const promises = urls.map((url: any) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = resolve;
            img.onerror = reject;
          });
        });
        return Promise.all(promises);
      };

      printWindow.onload = async () => {
        try {
          // CSS에서 사용하는 백그라운드 이미지 URL을 명시적으로 불러옴
          await preloadImages(["../img/bg.png", "../img/bg_front.png", "../img/bg_front2.png"]);

          printWindow.focus();
          printWindow.print();
          printWindow.close();
        } catch (error) {
          console.error("Error loading images:", error);
        }
      };
    }
  };

  const getChartData = useCallback(
    (type: 1 | 2 | 3, chartData: number[], chartData2: number[], chartLabels: string[]) => {
      if (type !== 3) {
        return {
          labels: chartLabels,
          datasets: [
            {
              label: type === 1 ? "운행횟수" : "주행시간",
              backgroundColor: "#5673e3",
              borderColor: "#5673e3",
              hoverBackgroundColor: "#5673e3",
              data: chartData,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
            },
            {
              label: type === 1 ? "운행횟수(주변)" : "주행시간(주변)",
              backgroundColor: "#60c6c4",
              borderColor: "#60c6c4",
              hoverBackgroundColor: "#60c6c4",
              data: chartData2,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
            },
          ],
        };
      }
      return {
        labels: chartLabels,
        datasets: [
          {
            label: "제어반 에러코드별 발생 횟수",
            backgroundColor: "#ffb6a7",
            borderColor: "#ffb6a7",
            hoverBackgroundColor: "#ffb6a7",
            data: chartData,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
          },
        ],
      };
    },
    [],
  );

  useEffect(() => {
    (async () => {
      try {
        if (!siteId || !liftId || !year || !quarter) {
          setLoading(false);
          return;
        }
        setLoading(true);
        let chartLabels = ["1월", "2월", "3월"];
        switch (Number(quarter)) {
          case 2:
            chartLabels = ["4월", "5월", "6월"];
            setStartDate(`${year}.4.1`);
            setEndDate(`${year}.6.30`);
            break;
          case 3:
            chartLabels = ["7월", "8월", "9월"];
            setStartDate(`${year}.7.1`);
            setEndDate(`${year}.9.30`);
            break;
          case 4:
            chartLabels = ["10월", "11월", "12월"];
            setStartDate(`${year}.10.1`);
            setEndDate(`${year}.12.31`);
            break;
          default:
            setStartDate(`${year}.1.1`);
            setEndDate(`${year}.3.31`);
            break;
        }
        const newLift = await LiftService.getLift(liftId, auth);
        setLift(newLift);
        const newData = await ReportService.getQuarterReportData(liftId, year, quarter, auth);
        const liftErrorList: string[] = [];
        const liftErrorCountList: number[] = [];

        for (let i = 0; i < 5; i += 1) {
          if (newData.lift[i] !== undefined) {
            liftErrorList.push(newData.lift[i].raw_cd);
            liftErrorCountList.push(newData.lift[i].cnt);
          } else {
            liftErrorList.push("");
            liftErrorCountList.push(0);
          }
        }

        setDatas([
          getChartData(
            1,
            [
              newData.usage[0].tot_usg_cnt,
              newData.usage[1].tot_usg_cnt,
              newData.usage[2].tot_usg_cnt,
            ],
            [
              newData.usageAround[0]._avg.tot_usg_cnt,
              newData.usageAround[1]._avg.tot_usg_cnt,
              newData.usageAround[2]._avg.tot_usg_cnt,
            ],
            chartLabels,
          ),
          getChartData(
            2,
            [
              sencondToHour(newData.time[0].tot_usg_time),
              sencondToHour(newData.time[1].tot_usg_time),
              sencondToHour(newData.time[2].tot_usg_time),
            ],
            [
              sencondToHour(newData.timeAround[0]._avg.tot_usg_time),
              sencondToHour(newData.timeAround[1]._avg.tot_usg_time),
              sencondToHour(newData.timeAround[2]._avg.tot_usg_time),
            ],
            chartLabels,
          ),
          getChartData(3, liftErrorCountList, [], liftErrorList),
        ]);

        setTableData1([
          ...chartLabels.map((label, index) => {
            return {
              label: String(label),
              data: newData.usage[index].tot_usg_cnt,
              dataAround: newData.usageAround[index]._avg.tot_usg_cnt,
            };
          }),
          {
            label: "합계",
            data:
              newData.usage[0].tot_usg_cnt +
              newData.usage[1].tot_usg_cnt +
              newData.usage[2].tot_usg_cnt,
            dataAround:
              newData.usageAround[0]._avg.tot_usg_cnt +
              newData.usageAround[1]._avg.tot_usg_cnt +
              newData.usageAround[2]._avg.tot_usg_cnt,
          },
        ]);

        setTableData2([
          ...chartLabels.map((label, index) => {
            return {
              label: String(label),
              data: sencondToHour(newData.time[index].tot_usg_time),
              dataAround: sencondToHour(newData.timeAround[index]._avg.tot_usg_time),
            };
          }),
          {
            label: "합계",
            data: sencondToHour(
              newData.time[0].tot_usg_time +
                newData.time[1].tot_usg_time +
                newData.time[2].tot_usg_time,
            ),
            dataAround: sencondToHour(
              newData.timeAround[0]._avg.tot_usg_time +
                newData.timeAround[1]._avg.tot_usg_time +
                newData.timeAround[2]._avg.tot_usg_time,
            ),
          },
        ]);
        setTableData3(newData.liftTable);
        setLoading(false);
        // setOptions랑 chart3 data 가져오기~~
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        if (error && error.code && error.code === "ERR_NETWORK") {
          alert("레포트 서버에 연결할 수 없습니다.");
        }
      }
    })();
  }, [auth, getChartData, liftId, quarter, siteId, year]);

  if (loading)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height={750}>
        <CircularProgress />
      </Box>
    );

  if (siteId === undefined || lift === undefined || year === undefined || quarter === undefined) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height={750}>
        <Typography>데이터를 선택해주세요.</Typography>
      </Box>
    );
  }

  return (
    <>
      <div id="printArea" style={{ zoom: zoomLevel }}>
        <div ref={printRef1} className="page front">
          <div className="innerPage">
            <div className="section">
              <h2>Quarterly Report</h2>
            </div>

            <div className="section wr60" style={{ height: "680px" }}>
              <h3>
                {lift?.liftType === "LT_EL" ? "Elevator" : "Escalator"} Status Quarterly Report
                <span className="koTitle">승강기 상태 분기 보고서</span>
                <span className="date">
                  작성일: <span>{new Date().toISOString().substring(0, 10)}</span>
                </span>
              </h3>

              <h4>기본정보</h4>
              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>승강기번호</th>
                      <td>{lift?.equipmentNo}</td>
                      <th>제조업체</th>
                      <td>{lift?.mfCpnyNm}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>건물명</th>
                      <td colSpan={3}>{lift?.site.buldNm}</td>
                    </tr>
                    <tr>
                      <th>소재지</th>
                      <td colSpan={3}>{lift?.site.address1}</td>
                    </tr>
                    <tr>
                      <th>설치위치</th>
                      <td>{lift?.itlpc}</td>
                      <th>종류</th>
                      <td>{lift?.liftType}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>모델명</th>
                      <td>{lift?.modelName}</td>
                      <th>적재하중</th>
                      <td>{lift?.loadngLd}</td>
                    </tr>
                    <tr>
                      <th>최대정원</th>
                      <td>{lift?.ratedCap}</td>
                      <th>정격속도</th>
                      <td>{lift?.ratedSpd}</td>
                    </tr>
                    <tr>
                      <th>운행구간</th>
                      <td>{lift?.operSctn}</td>
                      <th>운행층수</th>
                      <td>{lift?.operFlrCnt}</td>
                    </tr>
                    <tr>
                      <th>지상층수</th>
                      <td>{lift?.groundFlrCnt}</td>
                      <th>지하층수</th>
                      <td>{lift?.undgrndFloorCnt}</td>
                    </tr>
                    <tr>
                      <th>유효종료일</th>
                      <td>{parseDate(lift?.validEndYmd)}</td>
                      <th>설치일</th>
                      <td>{parseDate(lift?.instlYmd)}</td>
                    </tr>
                    <tr>
                      <th>최초설치일</th>
                      <td>{parseDate(lift?.frstInstlYmd)}</td>
                      <th>최종검사일</th>
                      <td>{parseDate(lift?.lastInspctYmd)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>관리주체</th>
                      <td colSpan={3}>{lift?.mangeNm}</td>
                    </tr>
                    <tr>
                      <th>유지보수업체</th>
                      <td colSpan={3}>{lift?.mntCpnyNm}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="section wr60">
              <div className="textType">
                <p>
                  다음 기간의
                  <span>
                    {startDate} ~ {endDate}
                  </span>
                  엘리베이터 원격 점검 보고서를 보고 드립니다.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div ref={printRef2} className="page">
          <div className="innerPage">
            <div className="section">
              <h2>Quarterly Report</h2>
            </div>
            <div className="section">
              <h3>운행횟수 및 주행시간</h3>
              <div className="whiteBox">
                <h4>
                  운행횟수 <span>[회]</span>
                </h4>
                <div className="tableWrap">
                  <table className="table type2">
                    <colgroup>
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                    </colgroup>
                    <thead>
                      <tr>
                        {tableData1.map((d, i) => (
                          <th key={String(i)}>{d.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {tableData1.map((d, i) => (
                          <td className="txtRight" key={String(i)}>
                            <span className="dotB" />
                            {comma(d.data)}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {tableData1.map((d, i) => (
                          <td className="txtRight" key={String(i)}>
                            <span className="dotG" />
                            {comma(d.dataAround)}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="chartArea" style={{ height: "205px" }}>
                  {datas[0] ? <Bar options={option1} data={datas[0]} /> : ""}
                </div>
              </div>

              <div className="whiteBox">
                <h4>
                  주행시간<span>[시간]</span>
                </h4>
                <div className="tableWrap">
                  <table className="table type2">
                    <colgroup>
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                    </colgroup>
                    <thead>
                      <tr>
                        {tableData2.map((d, i) => (
                          <th key={String(i)}>{d.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {tableData2.map((d, i) => (
                          <td className="txtRight" key={String(i)}>
                            <span className="dotB" />
                            {comma(d.data)}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {tableData2.map((d, i) => (
                          <td className="txtRight" id={String(i)}>
                            <span className="dotG" />
                            {comma(d.dataAround)}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="chartArea" style={{ height: "205px" }}>
                  {datas[1] ? <Bar options={option2} data={datas[1]} /> : ""}
                </div>
              </div>
            </div>
            <p className="pageNum">1</p>
          </div>
        </div>

        <div ref={printRef3} className="page">
          <div className="innerPage">
            <div className="section">
              <h2>Quarterly Report</h2>
            </div>
            <div className="section">
              <h3>제어반 에러코드 발생 현황</h3>
              <div className="whiteBox">
                <span className="subTxt txtRight">[기간내 최신 5개]</span>
                <div className="tableWrap">
                  <table className="table type3">
                    <colgroup>
                      <col width="26%" />
                      <col width="26%" />
                      <col width="48%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>발생일시</th>
                        <th>발생코드</th>
                        <th>에러메세지</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData3.map((d) => (
                        <tr key={String(d.id)}>
                          <td className="txtCenter">{parseKSTDate(d.checked_at)}</td>
                          <td className="txtCenter">{d.raw_cd}</td>
                          <td className="txtLeft">{d.status_msg}</td>
                        </tr>
                      ))}
                      {tableData3.length < 5 &&
                        Array.from({ length: 5 - tableData3.length }).map((d, i) => {
                          return (
                            <tr key={String(i)}>
                              <td className="txtCenter">&nbsp;</td>
                              <td className="txtCenter" />
                              <td className="txtLeft" />
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="chartArea" style={{ height: "230px" }}>
                  {datas[2] ? <Bar options={option3} data={datas[2]} /> : ""}
                  {/* <canvas id="errorChart" /> */}
                </div>
              </div>

              <div className="whiteBox">
                <h4>소견</h4>
                <p className="dec">
                  {/* 해당 승강기는 안정적으로 운행되고 있으나, 특정 에러 코드가 반복적으로 발생하는
                  것으로 보아 추가 점검이 필요할 수 있습니다. 특히 ER_DLS_RUN_UP과 ER_HATCH 코드는
                  승강기의 안전과 직결되는 문제일 수 있으므로, 해당 문제에 대해 전문가의 정밀 진단과
                  조치가 요구됩니다. */}
                </p>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>

            <div className="section">
              <div className="signArea">
                <span>서명</span>
              </div>
            </div>
            <p className="pageNum">2</p>
          </div>
        </div>
      </div>

      <div className="btnArea no-print">
        <button type="button" className="btnPrint" id="zoom-in" onClick={() => zoomIn()}>
          <i className="icoZoomIn" aria-hidden="true" />
          <span className="sr-only">확대</span>
        </button>
        <button type="button" className="btnPrint" id="zoom-out" onClick={() => zoomOut()}>
          <i className="icoZoomOut" aria-hidden="true" />
          <span className="sr-only">축소</span>
        </button>
        <button type="button" className="btnPrint" onClick={() => printPage()}>
          <i className="icoPrint" aria-hidden="true" />
          <span className="sr-only">프린트</span>
        </button>
        <a href="#settingPrint" className="btnPrint" onClick={() => pdfSave()}>
          <i className="icoQM" aria-hidden="true" />
          <span className="sr-only">PDF다운</span>
        </a>
      </div>

      {/* <Dialog open style={{ maxWidth: "375px", width: "90%" }}>
        <div id="settingPrint" className="modal">
          <h2>알림</h2>
          <p>배경색 및 이미지 인쇄가 안될 경우 아래와 같이 설정해 주세요.</p>
          <p>
            <strong className="chrome">크롬 브라우저</strong>인쇄 버튼 클릭 &rArr; 설정 더보기 클릭
            &rArr; 스크롤바를 아래로 내려서 옵션 <span>[배경 그래픽]</span>을 체크해 주세요.
          </p>
          <p>
            <strong className="edge">엣지 브라우저</strong>인쇄 버튼 클릭 &rArr; 기타 설정 클릭
            &rArr; 스크롤바를 아래로 내려서 옵션 <span>[배경 그래픽]</span>을 체크해 주세요.
          </p>
        </div>
      </Dialog> */}
    </>
  );
};

QuarterlyReport.defaultProps = {
  siteId: undefined,
  liftId: undefined,
  equipmentNo: undefined,
  year: undefined,
  quarter: undefined,
};

export default QuarterlyReport;
