import {
  ConfigAction,
  ConfigActionTypes,
  ConfigState,
  SetStatus,
  ToggleMapDialog,
  ToggleSidebar,
} from "../../@types/redux/config.interface";

const { TOGGLE_SIDE_BAR, TOGGLE_MAP_DIALOG, SET_STATUS } = ConfigActionTypes;

export const toggleSidebar = (): ToggleSidebar => ({
  payload: {},
  type: TOGGLE_SIDE_BAR,
});

export const toggleMapDialog = (): ToggleMapDialog => ({
  payload: {},
  type: TOGGLE_MAP_DIALOG,
});

export const setStatus = (status: Status): SetStatus => ({
  payload: { status },
  type: SET_STATUS,
});

const initialState: ConfigState = {
  sidebarOpen: true,
  mapDialog: false,
  status: "ok",
};

const ConfigReducer = (state = initialState, action: ConfigAction): ConfigState => {
  switch (action.type) {
    case TOGGLE_SIDE_BAR:
      return { ...state, sidebarOpen: !state.sidebarOpen };
    case TOGGLE_MAP_DIALOG:
      return { ...state, mapDialog: !state.mapDialog };
    case SET_STATUS:
      return { ...state, status: action.payload.status };
    default:
      return state;
  }
};

export default ConfigReducer;
