import { all, call, fork, put, select, takeLatest } from "@redux-saga/core/effects";
import { DashboardgActionTypes, SetDashboardLabel } from "../../@types/redux/dashboard.interface";
import DashboardService, { DashboardContentResponse } from "../../services/DashboardService";
import { getDashboardContentsSuccess, setDashboardBuildings } from "../reducers/dashboard";
import { enqueueSnackbar } from "../reducers/snackbar";
import { convertToMapSite } from "../../utils/parse";
import { RootState } from "../reducers";
import { Auth } from "../../services/AuthService";

const { SET_DASHBAROD_LABEL, GET_DASHBOARD_CONTENTS_REQUEST } = DashboardgActionTypes;

async function getDiagnosisesBuildings(rangType: DateRangeType, auth: Auth): Promise<Building[]> {
  if (!auth.auth) return [];
  const statusArr: DiagnosisStatus[] = [];
  if (rangType === "second") statusArr.push("01");
  else if (rangType === "third") statusArr.push("11");
  const response = await DashboardService.getDiagnosises(statusArr, auth);
  const buildings: Building[] = [];
  response.liftDiagnosisExecutions.forEach((d, index) => {
    if (buildings.every((builing) => builing.site.siteId !== d.site.id)) {
      buildings.push({
        type: "diagnosis",
        id: Math.random() + index,
        site: convertToMapSite(d.site),
      });
    }
  });
  return buildings;
}

async function getBuildings(
  type: DashboardLabelType,
  value: DateRangeType,
  auth: Auth,
): Promise<Building[]> {
  /* 용도 모르겠는데 계정관계없이 site array 0으로 가져옴 */
  let response: DashboardContentResponse;
  if (type === "inspection") response = await DashboardService.getInpections(value, auth);
  else response = await DashboardService.getSelfChecks(value, auth);
  return response.sites.map((site, index) => ({ type, id: Math.random() + index, site }));
}

function* getDashBoardContents() {
  try {
    const { auth } = yield select((state: RootState) => state.auth);
    if (!auth.auth) return;
    const promiseArray: Promise<Building[]>[] = [];
    promiseArray.push(getDiagnosisesBuildings("first", auth));
    promiseArray.push(getBuildings("inspection", "first", auth));
    promiseArray.push(getBuildings("selfCheck", "first", auth));
    const results: Building[][] = yield call(() => Promise.all(promiseArray));
    yield put(getDashboardContentsSuccess(results.flat()));
  } catch (error: any) {
    yield put(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
  }
}

function* setDashboardLabel({ payload }: SetDashboardLabel) {
  try {
    const { auth } = yield select((state: RootState) => state.auth);
    if (!auth.auth) return;
    const { type, value } = payload;
    let buildings: Building[] = [];

    if (value === "hide") {
      yield put(setDashboardBuildings(type, buildings));
      return;
    }
    if (type === "diagnosis") {
      buildings = yield call(getDiagnosisesBuildings, value, auth);
    } else {
      buildings = yield call(getBuildings, type, value, auth);
    }
    yield put(setDashboardBuildings(type, buildings));
  } catch (error: any) {
    yield put(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
  }
}

function* getDashboardContentsRequestWatcher() {
  yield takeLatest(GET_DASHBOARD_CONTENTS_REQUEST, getDashBoardContents);
}

function* setDashboardLabelWatcher() {
  yield takeLatest(SET_DASHBAROD_LABEL, setDashboardLabel);
}

export default function* DashboardSaga() {
  yield all([fork(setDashboardLabelWatcher), fork(getDashboardContentsRequestWatcher)]);
}
