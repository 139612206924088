import { Edit } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoBox from "../components/Info/InfoBox";
import InfoList from "../components/Info/InfoList";
import InfoRow from "../components/Info/InfoRow";
import Input from "../components/Input";
import { RootState } from "../redux/reducers";
import { enqueueSnackbar } from "../redux/reducers/snackbar";
import EnumService from "../services/EnumService";
import UserService from "../services/UserService";

const Profile = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { id } = useParams();
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
  const [pwdVerify, setPwdVerify] = useState({ password: "", text: "", color: "" });
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const common: any = t("common", { returnObjects: true });
  const lang: any = t("UserDetail", { returnObjects: true });

  const [value, setValue] = useState({
    name: "",
    password: "",
    loginId: "",
    enabledYn: true,
    email: "",
    telNo: "",
    loginUserYn: true,
    koeisaId: "",
    role: { name: "" },
    company: { name: "" },
  });

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: inputValue } = event.target;
    setValue((prev) => ({ ...prev, [name]: inputValue }));
  }, []);

  const getMyInfo = useCallback(async () => {
    if (!auth || !id) return;
    const roles = await EnumService.getEnum("RoleType", auth);
    const newOptions = roles.map((role) => ({
      label: role.title,
      value: role.code,
    }));
    setRoleOptions(newOptions);
    const response = await UserService.getUser(id, auth);
    setValue(response);
  }, [id, auth]);

  useEffect(() => {
    (async () => {
      await getMyInfo();
    })();
  }, [id, auth, getMyInfo]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      if (id && auth) {
        /** toast든 뭐든 수정완료했다고 띄워야함 */
        event.preventDefault();
        await UserService.updateUser(id, value as User, auth);
        dispatch(
          enqueueSnackbar({
            message: lang.수정완료,
            options: { variant: "info" },
          }),
        );
        getMyInfo();
      }
    } catch (error: any) {
      dispatch(enqueueSnackbar({ message: error, options: { variant: "error" } }));
    }
  };

  const { password, loginId, email, telNo, name, enabledYn, koeisaId } = value;

  const handleChangeSecondPwd: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const secondPwd = event.target.value;
    let message = "";
    let colorType = "";

    if (!password) {
      setPwdVerify({ password: event.target.value, color: colorType, text: message });
      return;
    }

    if (secondPwd !== password) {
      message = common.비밀번호불일치;
      colorType = "error.light";
    } else {
      message = common.비밀번호일치;
      colorType = "success.light";
    }

    setPwdVerify({ password: event.target.value, color: colorType, text: message });
  };

  return (
    <Grid item xl={8} sm={12}>
      <Typography variant="subtitle2" gutterBottom>
        {lang.사용자_정보}
      </Typography>
      <form onSubmit={onSubmit}>
        <InfoList>
          <InfoRow>
            <InfoBox title={lang.아이디} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{loginId}</Typography>
            </InfoBox>
            <InfoBox title={lang.역할_소속} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">
                ({roleOptions.find((role) => role.value === value.role.name)?.label}){" "}
                {value.company?.name}
              </Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.비밀번호} flex={1} valueSx={{ flex: 2 }}>
              <Input
                fullWidth
                type="password"
                name="password"
                value={password}
                onChange={onChange}
              />
            </InfoBox>
            <InfoBox
              title={lang.비밀번호_확인}
              subtitle={pwdVerify.text}
              subtitleProps={{ color: pwdVerify.color }}
              flex={1}
              valueSx={{ flex: 2 }}
            >
              <Input
                fullWidth
                type="password"
                value={pwdVerify.password}
                onChange={handleChangeSecondPwd}
              />
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.자체점검ID} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{koeisaId}</Typography>
            </InfoBox>
            <InfoBox title={lang.이름} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{name}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.연락처} flex={1} valueSx={{ flex: 2 }}>
              <Input
                fullWidth
                name="telNo"
                value={telNo}
                type="number"
                onChange={onChange}
                required
              />
            </InfoBox>
            <InfoBox title={lang.이메일} flex={1} valueSx={{ flex: 2 }}>
              <Input fullWidth name="email" type="email" value={email} onChange={onChange} />
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.등록일} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{null}</Typography>
            </InfoBox>
            <InfoBox title={lang.상태} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{enabledYn ? lang.활성 : lang.비활성}</Typography>
            </InfoBox>
          </InfoRow>
        </InfoList>
        <Box pt={2} display="flex" justifyContent="end">
          <Button variant="contained" color="secondary" type="submit" startIcon={<Edit />}>
            {lang.수정}
          </Button>
        </Box>
      </form>
    </Grid>
  );
};

export default Profile;
