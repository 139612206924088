import {
  Box,
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import React from "react";

interface Props<InputName = string> {
  options: SelectOption[];
  name?: InputName;
  value?: string | number | null;
  onChange?: (name: InputName, option: SelectOption) => void;
  required?: boolean;
  disabled?: boolean;
  maxWidth?: number;
}

const Select = ({ value, options, name, onChange, maxWidth, ...restProps }: Props) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    const { value: inputValue } = event.target;
    const option = options.find((opt) => opt.value === inputValue);
    if (option && name) onChange?.(name, option);
  };

  return (
    <Box sx={{ minWidth: 105, width: maxWidth ? `${maxWidth}px` : "100%" }}>
      <FormControl fullWidth size="small">
        <MuiSelect
          value={value?.toString()}
          displayEmpty
          sx={{
            borderRadius: 0,
            bgcolor: theme.palette.background.paper,
            fontSize: theme.typography.body2.fontSize,
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${theme.palette.divider}`,
            },
          }}
          inputProps={{
            sx: {
              padding: "6px 0 6px 8px",
              borderRadius: 0,
            },
          }}
          MenuProps={{ autoFocus: false }}
          onChange={handleChange}
          {...restProps}
        >
          {options.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

Select.defaultProps = {
  onChange: undefined,
  name: "",
  value: "",
  required: false,
  disabled: false,
  maxWidth: undefined,
};

export default Select;
