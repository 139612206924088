import { ArrowBack, Edit, Save, Search } from "@mui/icons-material";
import { debounce, Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoBox from "../../components/Info/InfoBox";
import InfoList from "../../components/Info/InfoList";
import InfoRow from "../../components/Info/InfoRow";
import Input from "../../components/Input";
import SearchDialog from "../../components/SearchDialog";
import Select from "../../components/Select";
import { RootState } from "../../redux/reducers";
import { enqueueSnackbar } from "../../redux/reducers/snackbar";
import CompanyService from "../../services/CompanyService";
import EnumService from "../../services/EnumService";
import UserService from "../../services/UserService";

interface InitalValue {
  name: string;
  password: string;
  loginId: string;
  enabledYn: boolean;
  email: string;
  telNo: string;
  loginUserYn: boolean;
  koeisaId: string;
  company?: {
    name?: string;
    id: number;
  };
  role?: any;
}

const UserRegist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickBack = () => navigate("/user");
  const { id } = useParams();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("UserDetail", { returnObjects: true });

  const statusOption = [
    {
      label: lang.활성,
      value: "enabled",
    },
    {
      label: lang.비활성,
      value: "disabled",
    },
  ];

  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
  const [pwdVerify, setPwdVerify] = useState({ password: "", text: "", color: "" });

  const infoRef = useRef({ loginId: "", email: "" });

  const [verifies, setVerifies] = useState<{ loginId: boolean; email: boolean }>({
    loginId: false,
    email: false,
  });

  const [values, setValue] = useState<InitalValue>({
    name: "",
    password: "",
    loginId: "",
    enabledYn: true,
    email: "",
    telNo: "",
    loginUserYn: true,
    koeisaId: "",
  });
  const [dialog, setDialog] = useState(false);

  const onChangeOption = (name: string, option: SelectOption) => {
    if (name === "role") {
      const targetIndex = roleOptions.findIndex((role) => role.value === option.value);
      setValue((prev) => ({
        ...prev,
        role: prev.role
          ? { ...prev.role, id: targetIndex + 1, name: option.value as string }
          : { id: targetIndex + 1, name: option.value as string },
      }));
    }
    if (name === "status") {
      const enabledYn = option.value === "enabled";
      setValue((prev) => ({ ...prev, enabledYn }));
    }
  };

  const delayQuery = useMemo(
    () =>
      debounce(async (inputValue: string, name: string) => {
        if (!auth.auth) return;
        if (inputValue.length > 4) {
          let response: boolean;
          if (name === "loginId") {
            response = await UserService.checkId(inputValue, auth);
          } else {
            response = await UserService.checkEmail(inputValue, auth);
          }
          setVerifies((prev) => ({ ...prev, [name]: response }));
        }
      }, 500),
    [auth],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value: inputValue } = event.target;
      setValue((prev) => ({ ...prev, [name]: inputValue }));
      if (name === "loginId" || name === "email") {
        delayQuery(inputValue, name);
      }
    },
    [delayQuery],
  );

  const isCompanyMember = values.role?.name === "CW" || values.role?.name === "CA";

  const isAdmin = values.role?.name === "A";

  const onOpenDialog = () => {
    if (isCompanyMember) {
      setDialog(true);
    }
  };

  const onCloseDialog = useCallback(() => {
    setDialog(false);
  }, []);

  const isEdit = Boolean(id);

  useEffect(() => {
    (async () => {
      try {
        if (id && auth) {
          const response = await UserService.getUser(Number(id), auth);
          infoRef.current = { loginId: response.loginId, email: response.email };
          setValue(response);
        }
      } catch (error: any) {
        dispatch(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
      }
    })();
  }, [id, auth, dispatch]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      if (!auth.auth) return;
      event.preventDefault();

      const requestValue = {
        ...values,
        role: {
          id: values.role.id,
        },
        ...(values.company && { company: { id: values.company.id } }),
      };

      if (id) {
        await UserService.updateUser(id, requestValue as any, auth);
      } else {
        await UserService.addUser(requestValue as any, auth);
      }

      navigate("/user");
    } catch (error: any) {
      dispatch(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
    }
  };

  const { password, loginId, email, telNo, name, enabledYn, koeisaId } = values;

  const handleChangeSecondPwd: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const secondPwd = event.target.value;
    let message = "";
    let colorType = "";

    if (!password) {
      setPwdVerify({ password: event.target.value, color: colorType, text: message });
      return;
    }

    if (secondPwd !== password) {
      message = lang.비밀번호불일치;
      colorType = "error.light";
    } else {
      message = lang.비밀번호일치;
      colorType = "success.light";
    }

    setPwdVerify({ password: event.target.value, color: colorType, text: message });
  };
  useEffect(() => {
    (async () => {
      if (!auth.auth) return;
      const roles = await EnumService.getEnum("RoleType", auth);
      const newOptions = roles
        .filter((role) => role.code !== "CA" || id) // 유지보수업체 관리자 목록에서 제외
        .map((role) => ({
          label: role.title,
          value: role.code,
        }));
      setRoleOptions(newOptions);
    })();
  }, [auth, id]);

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const getSearchList = debounce(async (keyword: string) => {
    if (!auth.auth) return;
    const response = await CompanyService.getCompanies(
      { searchKey: "name", searchValue: keyword },
      auth,
    );
    if (response._embedded) {
      const newSearchResults = response._embedded.liftMaintenanceCompanies.map((cpy) => ({
        id: cpy.id,
        title: cpy.name,
        subtitle: cpy.address,
        data: cpy,
      }));
      setSearchResults(newSearchResults);
    }
  }, 300);

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: inputValue } = event.target;
    getSearchList(inputValue);
  };

  const onClickItem = (item: Company) => {
    setValue((prev) => ({ ...prev, company: item }));
  };

  const idVerifyText = useMemo(() => {
    if (!loginId || infoRef.current.loginId === loginId) {
      return undefined;
    }

    if (loginId.length < 5) {
      return {
        subtitle: lang.다섯자이상,
        color: "error.light",
      };
    }

    if (!verifies.loginId) {
      return {
        subtitle: lang.사용중아이디,
        color: "error.light",
      };
    }

    return { subtitle: lang.사용가능아이디, color: "success.light" };
  }, [loginId, verifies, lang]);

  const emailVerify = useMemo(() => {
    if (!email || infoRef.current.email === email) {
      return undefined;
    }

    if (email.length < 5) {
      return {
        subtitle: lang.다섯자이상,
        color: "error.light",
      };
    }
    if (!verifies.email) {
      return {
        subtitle: lang.사용중메일,
        color: "error.light",
      };
    }

    return { subtitle: lang.사용가능메일, color: "success.light" };
  }, [email, verifies, lang]);

  const handleClickCancel = () => navigate(-1);

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>

      <Grid item xl={8} lg={12}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.사용자_정보}
        </Typography>
        <form onSubmit={onSubmit}>
          <InfoList>
            <InfoRow>
              <InfoBox
                title={`${lang.아이디} *`}
                flex={1}
                subtitle={idVerifyText?.subtitle}
                subtitleProps={{ color: idVerifyText?.color }}
                valueSx={{ flex: 2 }}
              >
                <Input fullWidth name="loginId" value={loginId} onChange={onChange} required />
              </InfoBox>
              <InfoBox title={`${lang.역할_소속} *`} flex={1} valueSx={{ flex: 2 }}>
                <Box display="flex">
                  {roleOptions.length && (
                    <Select
                      value={values.role?.name}
                      options={roleOptions}
                      name="role"
                      onChange={onChangeOption}
                      disabled={!!id}
                    />
                  )}
                  <Input
                    fullWidth
                    value={values.company?.name}
                    disabled={!isAdmin}
                    required={isCompanyMember}
                    name="company"
                    sx={{ ml: 1 }}
                    onClick={onOpenDialog}
                    endAdornment={
                      <InputAdornment position="end">
                        <Search
                          fontSize="small"
                          sx={{
                            mr: 1,
                            ...(!isCompanyMember && { opacity: 0.3, pointerEvents: "none" }),
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Box>
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={`${lang.비밀번호} *`} flex={1} valueSx={{ flex: 2 }}>
                <Input
                  fullWidth
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  required
                />
              </InfoBox>
              <InfoBox
                title={`${lang.비밀번호_확인} *`}
                subtitle={pwdVerify.text}
                subtitleProps={{ color: pwdVerify.color }}
                flex={1}
                valueSx={{ flex: 2 }}
              >
                <Input
                  fullWidth
                  type="password"
                  value={pwdVerify.password}
                  onChange={handleChangeSecondPwd}
                  required
                />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.자체점검ID} flex={1} valueSx={{ flex: 2 }}>
                <Input fullWidth name="koeisaId" value={koeisaId} onChange={onChange} />
              </InfoBox>
              <InfoBox title={`${lang.이름} *`} flex={1} valueSx={{ flex: 2 }}>
                <Input fullWidth name="name" value={name} onChange={onChange} required />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={`${lang.연락처} *`} flex={1} valueSx={{ flex: 2 }}>
                <Input
                  fullWidth
                  name="telNo"
                  value={telNo}
                  type="number"
                  onChange={onChange}
                  required
                />
              </InfoBox>
              <InfoBox
                title={`${lang.이메일} *`}
                flex={1}
                subtitle={emailVerify?.subtitle}
                subtitleProps={{ color: emailVerify?.color }}
                valueSx={{ flex: 2 }}
              >
                <Input
                  fullWidth
                  name="email"
                  type="email"
                  value={email}
                  onChange={onChange}
                  required
                />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.등록일} flex={1} valueSx={{ flex: 2 }}>
                <Typography variant="body2">{new Date().toLocaleDateString()}</Typography>
              </InfoBox>
              <InfoBox title={lang.상태} flex={1} valueSx={{ flex: 2 }}>
                <Select
                  value={enabledYn ? "enabled" : "disabled"}
                  options={statusOption}
                  name="status"
                  onChange={onChangeOption}
                />
              </InfoBox>
            </InfoRow>
          </InfoList>
          <Box pt={2} display="flex" justifyContent="end">
            <Box>
              {isEdit ? (
                <Button variant="contained" color="secondary" type="submit" startIcon={<Edit />}>
                  {lang.수정}
                </Button>
              ) : (
                <Button variant="contained" type="submit" startIcon={<Save />}>
                  {lang.등록}
                </Button>
              )}
              <Button onClick={handleClickCancel} sx={{ ml: 1 }}>
                {lang.취소}
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
      <SearchDialog
        open={dialog}
        onClose={onCloseDialog}
        onChange={onSearch}
        onClickItem={onClickItem}
        list={searchResults}
      />
    </>
  );
};

export default UserRegist;
