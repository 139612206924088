import {
  Assessment,
  Business,
  CheckCircle,
  Construction,
  Dashboard,
  Dns,
  Elevator,
  EventNote,
  HomeWork,
  Person,
  Sensors,
  Settings,
} from "@mui/icons-material";
import { List } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { RootState } from "../redux/reducers";
import NavLink from "./NavLink";

/* 좌측 메뉴 목록 */
const NavLinkList = () => {
  const { sidebarOpen } = useSelector((state: RootState) => state.config);
  const { auth } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const menu: any = t("menu", { returnObjects: true });
  if (!auth.auth) return null;

  // 업체관리자 일경우
  if (auth.auth === "CA") {
    return (
      <List>
        <NavLink open={sidebarOpen} to="/" title={menu.대시보드} icon={<Dashboard />} />
        <NavLink open={sidebarOpen} to="/state" title={menu.상태_관리} icon={<CheckCircle />} />
        <NavLink open={sidebarOpen} to="/report" title={menu.상태_보고서} icon={<Assessment />} />
        <NavLink
          open={sidebarOpen}
          to="/inspection"
          title={menu.점검_관리}
          icon={<Construction />}
        />
        <NavLink
          open={sidebarOpen}
          to="/maintenance"
          title={menu.유지보수목록}
          icon={<EventNote />}
        />
        <NavLink open={sidebarOpen} to="/lift" title={menu.승강기_관리} icon={<Elevator />} />
        <NavLink open={sidebarOpen} to="/company" title={menu.업체_관리} icon={<HomeWork />} />
        <NavLink open={sidebarOpen} to="/user" title={menu.사용자_관리} icon={<Person />} />
        {/* <NavLink
          open={sidebarOpen}
          to={`/profile/${auth.id}`}
          title="계정 관리"
          icon={<AccountCircle />}
        /> */}
      </List>
    );
  }

  if (auth.auth === "CW") {
    return (
      <List>
        <NavLink open={sidebarOpen} to="/" title={menu.대시보드} icon={<Dashboard />} />
        <NavLink open={sidebarOpen} to="/state" title={menu.상태_관리} icon={<CheckCircle />} />
        <NavLink open={sidebarOpen} to="/report" title={menu.상태_보고서} icon={<Assessment />} />
        <NavLink
          open={sidebarOpen}
          to="/inspection"
          title={menu.점검_관리}
          icon={<Construction />}
        />
        <NavLink
          open={sidebarOpen}
          to="/maintenance"
          title={menu.유지보수목록}
          icon={<EventNote />}
        />
        <NavLink open={sidebarOpen} to="/lift" title={menu.승강기_관리} icon={<Elevator />} />
        {/* <NavLink
          open={sidebarOpen}
          to={`/profile/${auth.id}`}
          title="계정 관리"
          icon={<AccountCircle />}
        /> */}
      </List>
    );
  }

  if (auth.auth === "O") {
    return (
      <List>
        {/* <NavLink open={sidebarOpen} to="/" title={menu.대시보드} icon={<Dashboard />} /> */}
        <NavLink open={sidebarOpen} to="/" title={menu.대시보드} icon={<Dashboard />} />
        <NavLink open={sidebarOpen} to="/report" title={menu.상태_보고서} icon={<Assessment />} />
        {/* <NavLink
          open={sidebarOpen}
          to={`/profile/${auth.id}`}
          title="계정 관리"
          icon={<AccountCircle />}
        /> */}
      </List>
    );
  }

  return (
    <List>
      <NavLink open={sidebarOpen} to="/" title={menu.대시보드} icon={<Dashboard />} />
      <NavLink open={sidebarOpen} to="/state" title={menu.상태_관리} icon={<CheckCircle />} />
      <NavLink open={sidebarOpen} to="/report" title={menu.상태_보고서} icon={<Assessment />} />
      <NavLink open={sidebarOpen} to="/inspection" title={menu.점검_관리} icon={<Construction />} />
      <NavLink
        open={sidebarOpen}
        to="/maintenance"
        title={menu.유지보수목록}
        icon={<EventNote />}
      />
      <NavLink open={sidebarOpen} to="/lift" title={menu.승강기_관리} icon={<Elevator />} />
      <NavLink open={sidebarOpen} to="/company" title={menu.업체_관리} icon={<HomeWork />} />
      <NavLink open={sidebarOpen} to="/site" title={menu.건물_관리} icon={<Business />} />
      <NavLink open={sidebarOpen} to="/user" title={menu.사용자_관리} icon={<Person />} />
      <NavLink open={sidebarOpen} to="/gateway" title={menu.게이트웨이_관리} icon={<Dns />} />
      <NavLink open={sidebarOpen} to="/sensor" title={menu.센서_관리} icon={<Sensors />} />
      <NavLink open={sidebarOpen} to="/setting" title={menu.설정} icon={<Settings />} />
    </List>
  );
};

export default NavLinkList;
