import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useTable from "../../../../hooks/useTable";
import { RootState } from "../../../../redux/reducers";
import { setStatus } from "../../../../redux/reducers/config";
import { enqueueSnackbar } from "../../../../redux/reducers/snackbar";
import ControllerService from "../../../../services/ControllerService";
import { parseKSTDate, parseStatus } from "../../../../utils/parse";
import Table from "../../../Table/Table";

interface Props {
  liftId: number | string;
  onChangeDiagnosis: (
    data: SensorDiagnosis | ControllerDiagnosis,
    type: "sensor" | "controller",
  ) => void;
}

const DiagnosisDetailController = ({ liftId, onChangeDiagnosis }: Props) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  const columns: Column<ControllerDiagnosis>[] = [
    { field: "checkedAt", heading: lang.DiagnosisDetailController.점검일시 },
    { field: "liftCntlrCode.rawCd", heading: lang.DiagnosisDetailController.발생코드 },
    { field: "liftCntlrCode.statusMsg", heading: lang.DiagnosisDetailController.에러메세지 },
    { field: "status", heading: lang.DiagnosisDetailController.상태 },
  ];

  const { onChangeOption, onChangeData, data, page, rowsPerPage, totalElement } = useTable(10);

  useEffect(() => {
    (async () => {
      if (!auth.auth) return;
      try {
        dispatch(setStatus("loading"));
        const response = await ControllerService.getControllerStatus(
          { liftId, size: rowsPerPage, page },
          auth,
        );
        const newData = response._embedded
          ? response._embedded.resultLiftCntlrs.map((d) => ({
              ...d,
              status: parseStatus(d.status),
              checkedAt: parseKSTDate(d.checkedAt),
            }))
          : [];
        onChangeData({
          data: newData,
          totalElement: response.page.totalElements,
          totalPage: response.page.totalPages,
        });
        onChangeDiagnosis(newData[0] as ControllerDiagnosis, "controller");
        dispatch(setStatus("ok"));
      } catch (error: any) {
        dispatch(setStatus("error"));
        dispatch(enqueueSnackbar({ message: error, options: { variant: "error" } }));
      }
    })();
  }, [liftId, page, rowsPerPage, auth, onChangeData, onChangeDiagnosis, dispatch]);

  const onClickRow = (row: ControllerDiagnosis) => onChangeDiagnosis(row, "controller");

  return (
    <Table
      columns={columns}
      data={data}
      page={page}
      rowsPerPage={rowsPerPage}
      totalElement={totalElement}
      onClickRow={onClickRow}
      onChangeOption={onChangeOption}
      activeRow
    />
  );
};

export default DiagnosisDetailController;
