import { AnyAction, combineReducers, Reducer } from "redux";
import { RootStateInterface } from "../../@types/redux/rootState";
import ConfigReducer from "./config";
import AuthReducer from "./auth";
import SnackbarReducer from "./snackbar";
import MapReducer from "./map";
import DashboradReducer from "./dashboard";
import RouteReducer from "./route";
import PathReducer from "./path";

const rootReducer: Reducer<RootStateInterface, AnyAction> = combineReducers<RootStateInterface>({
  config: ConfigReducer,
  auth: AuthReducer,
  snackbar: SnackbarReducer,
  map: MapReducer,
  dashboard: DashboradReducer,
  routes: RouteReducer,
  path: PathReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
