export enum ConfigActionTypes {
  SET_STATUS = "SET_STATUS",
  TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR",
  TOGGLE_MAP_DIALOG = "TOGGLE_MAP_DIALOG",
}

export type ConfigAction = SetStatus | ToggleSidebar | ToggleMapDialog;

export interface ConfigState {
  sidebarOpen: boolean;
  status: Status;
  mapDialog: boolean;
}

export interface SetStatus {
  payload: { status: Status };
  type: ConfigActionTypes.SET_STATUS;
}

export interface ToggleSidebar {
  payload: {};
  type: ConfigActionTypes.TOGGLE_SIDE_BAR;
}

export interface ToggleMapDialog {
  payload: {};
  type: ConfigActionTypes.TOGGLE_MAP_DIALOG;
}
