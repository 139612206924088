import { useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ArrowBack, Image, Info, Print } from "@mui/icons-material";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LiftService from "../../services/LiftService";
import StateService from "../../services/StateService";
import DiagnosisDetailList from "../../components/State/Diagnosis/DiagnosisDetailList";
// import DiagnosisDetailLiftSvg from "../../components/State/Diagnosis/DiagnosisDetailLiftSvg";
import DashboardSummaryDiagnosis2 from "../../components/Dashboard/summary/DashboardSummaryDiagnosis2";
import DiagnosisDetailSensorResult from "../../components/State/Diagnosis/sensor/DiagnosisDetailSensorResult";
import DiagnosisDetailControllerResult from "../../components/State/Diagnosis/controller/DiagnosisDetailControllerResult";
import LiftCheckDate from "../../components/Lift/LiftCheckDate";
import Paper from "../../components/Paper";
import { RootState } from "../../redux/reducers";
import LiftMaintainenceCompanyInfo from "../../components/Lift/LiftMaintainenceCompanyInfo";
import LiftInfo from "../../components/Lift/LiftInfo";
import MaintenanceDetailForm from "../../components/State/Maintenance/MaintenanceDetailForm";
import DiagnosisDetailLiftAnimation from "../../components/State/Diagnosis/DiagnosisDetailLiftAnimation";
import { SELF_URL } from "../../utils/const";
import { getQuarter } from "../../utils/parse";
import DiagnosisDetailESAnimation from "../../components/State/Diagnosis/DiagnosisDetailESAnimation";

/* 상태 관리 - 상세 */
const DiagnosisDetail = () => {
  const { id } = useParams();
  const [lift, setLift] = useState<Lift>();
  const [infoMode, toggleInfoMode] = useState(false);
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("DiagnosisDetail", { returnObjects: true });

  const navigate = useNavigate();
  const handleClickBack = () => (auth && auth.auth !== "O" ? navigate("/state") : navigate("/"));

  const isOwner = auth && auth.auth === "O";

  const getLift = useCallback(async (): Promise<Lift | undefined> => {
    if (!id || !auth) return undefined;
    const newLift = await LiftService.getLift(id, auth);
    setLift(newLift);
    return newLift;
  }, [id, auth]);

  useEffect(() => {
    getLift();
  }, [getLift]);

  const [sensorDiagnosis, setSensorDiagnosis] = useState<SensorDiagnosis>();
  const [sensorResult, setSensorResult] = useState<{
    vibrations: LiftVibration[];
    noises: LiftNoises[];
    esChains: EsChain[];
  }>({ vibrations: [], noises: [], esChains: [] });
  const [printOpen, setPrintOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (!auth.auth) return;
      if (!sensorDiagnosis) {
        setSensorResult({ vibrations: [], noises: [], esChains: [] });
        return;
      }

      const promiseArray: Promise<any>[] = [
        StateService.getVibrations(sensorDiagnosis.id, auth),
        StateService.getNoises(sensorDiagnosis.id, auth),
        StateService.getEsChains(sensorDiagnosis.id, auth),
      ];

      const [vibrations, noises, esChains] = await Promise.all(promiseArray);
      setSensorResult({ vibrations, noises, esChains });
    })();
  }, [auth, sensorDiagnosis]);

  const [controllerDiagnosis, setControllerDiagnosis] = useState<ControllerDiagnosis>();

  // useEffect(() => {
  //   (async () => {
  //     if (!auth.auth) return;
  //     if (!controllerDiagnosis) return;
  //     const response = await ControllerService.getResult(controllerDiagnosis.id, auth);
  //     console.log(response);
  //   })();
  // }, [auth, controllerDiagnosis]);

  const onChangeDiagnosis = useCallback(
    (data: SensorDiagnosis | ControllerDiagnosis, type: "controller" | "sensor") => {
      if (type === "sensor") {
        setSensorDiagnosis(data as SensorDiagnosis);
        setControllerDiagnosis(undefined);
      } else {
        setSensorDiagnosis(undefined);
        setControllerDiagnosis(data as ControllerDiagnosis);
      }
    },
    [],
  );

  const handleClickReport = () => {
    navigate(`/report?siteId=${lift?.site.id}&liftId=${lift?.id}`);
  };

  if (!id) return null;

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>

      <Grid item lg={8}>
        <Box display="flex" justifyContent="space-between" alignItems="end" mb={1}>
          <Typography variant="subtitle2">
            {lang.승강기정보} ({lift?.dataSyncYmd})
          </Typography>
          {!isOwner && (
            <Button
              variant="contained"
              startIcon={infoMode ? <Image /> : <Info />}
              size="small"
              onClick={() => toggleInfoMode((prev) => !prev)}
            >
              {lang.정보보기}
            </Button>
          )}
        </Box>
        <LiftInfo lift={lift} hideDetails={!infoMode} hideTitle syncCallback={getLift} />
      </Grid>

      {/* 정기검사 예정일 */}
      {!isOwner && (
        <Grid item lg={4} sm={12}>
          <Box mt={1.5}>
            <LiftCheckDate liftId={lift?.id} initValue={lift?.fdrmChkYmd} />
          </Box>
        </Grid>
      )}

      {!infoMode ? (
        <>
          <Grid item lg={8}>
            <Typography variant="subtitle2" gutterBottom>
              {lang.상태_이미지}
            </Typography>
            <Paper p={4}>
              {lift?.liftType === "LT_EL" && (
                <DiagnosisDetailLiftAnimation
                  liftItlpc={lift?.itlpc}
                  controllerDiagnosis={controllerDiagnosis}
                />
              )}

              {lift?.liftType === "LT_ES" && <DiagnosisDetailESAnimation />}

              {controllerDiagnosis && isOwner && (
                <Box pt={2}>
                  <DiagnosisDetailControllerResult diagnosis={controllerDiagnosis} />
                </Box>
              )}
            </Paper>
            {/* 정기검사 예정일 */}
            {!isOwner ? (
              <Box pt={2}>
                <LiftMaintainenceCompanyInfo lift={lift} />
                <Box textAlign="right" pt={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Print />}
                    onClick={handleClickReport}
                    // style={{ visibility: "hidden" }}
                  >
                    {lang.상태_보고서_보기}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box pt={2} />
            )}
            {/* 센서 상태 정보 */}
            {sensorDiagnosis && (
              <DiagnosisDetailSensorResult diagnosis={sensorDiagnosis} result={sensorResult} />
            )}
            <DiagnosisDetailList liftId={id} onChangeDiagnosis={onChangeDiagnosis} />
          </Grid>

          <Grid item lg={4}>
            {/* 부품 수명 통계 그래프 시작 */}
            <DashboardSummaryDiagnosis2 liftId={Number(id)} />
            {/* 부품 수명 통계 그래프 끝 */}

            {/* 제어반 상태 정보 */}
            {controllerDiagnosis && !isOwner && (
              <Box pt={2}>
                <DiagnosisDetailControllerResult diagnosis={controllerDiagnosis} />
              </Box>
            )}

            {lift && !isOwner && (
              <Box pt={2}>
                <MaintenanceDetailForm
                  liftId={id}
                  liftType={lift.liftType}
                  onClear={() => {}}
                  onAfterSubmit={() => {}}
                />
              </Box>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid item lg={8} sm={12}>
            <LiftMaintainenceCompanyInfo lift={lift} />
            <Box textAlign="right" py={2}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<Print />}
                onClick={handleClickReport}
              >
                {lang.상태_보고서_보기}
              </Button>
            </Box>
            {sensorDiagnosis && (
              <DiagnosisDetailSensorResult diagnosis={sensorDiagnosis} result={sensorResult} />
            )}
            {controllerDiagnosis && (
              <DiagnosisDetailControllerResult diagnosis={controllerDiagnosis} />
            )}
          </Grid>
          <Grid item lg={4}>
            <DiagnosisDetailList liftId={id} onChangeDiagnosis={onChangeDiagnosis} />
            {lift && (
              <MaintenanceDetailForm
                liftId={id}
                liftType={lift.liftType}
                onClear={() => {}}
                onAfterSubmit={() => {}}
              />
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default DiagnosisDetail;
