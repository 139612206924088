import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { trimRequest } from "../utils/parse";
import { Auth } from "./AuthService";

interface GetAllGatewaysResponse extends ServerResponse {
  _embedded: {
    iotGateways: IotGateway[];
  };
}

interface GetGatewaysRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: "asc" | "desc";
  searchKey?: string;
  searchValue?: string | number;
  installedYn?: string;
  madeBy?: string;
  exact?: boolean;
}

class GatewayService {
  private static GATEWAY = "api/admin/iotGateways";

  public static async getAllGateways(
    {
      page,
      size,
      sortKey,
      sortDirection,
      searchKey,
      searchValue,
      installedYn,
      madeBy,
      exact,
    }: GetGatewaysRequest,
    auth: Auth,
  ): Promise<GetAllGatewaysResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          let sort: string | undefined;
          if (sortKey && sortDirection) {
            sort = `${sortKey},${sortDirection}`;
          }
          const response: AxiosResponse<GetAllGatewaysResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${GatewayService.GATEWAY}`,
            headers: {
              Authorization: `Bearer ${auth.id_token}`,
            },
            params: {
              page,
              size,
              sort,
              ...(installedYn && {
                instlYn: installedYn,
              }),
              ...(searchKey &&
                searchValue && { [searchKey]: exact ? searchValue : `contains(${searchValue})` }),
              ...(madeBy && {
                madeBy,
              }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getGateway(id: number | string, auth: Auth): Promise<IotGateway> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response: AxiosResponse<IotGateway> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${GatewayService.GATEWAY}/${id}`,
            headers: {
              Authorization: `Bearer ${auth.id_token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addGateway(gateway: IotGateway, auth: Auth): Promise<IotGateway> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response: AxiosResponse<IotGateway> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${GatewayService.GATEWAY}`,
            data: trimRequest(gateway),
            headers: {
              Authorization: `Bearer ${auth.id_token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async updateGateway(
    id: number | string,
    gateway: IotGateway,
    auth: Auth,
  ): Promise<IotGateway> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response: AxiosResponse<IotGateway> = await axios({
            method: "patch",
            url: `${API_SERVER_URL}/${GatewayService.GATEWAY}/${id}`,
            data: trimRequest(gateway),
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${auth.id_token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async connectLift(
    auth: Auth,
    id: number | string,
    liftId?: number | string,
  ): Promise<IotGateway> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response: AxiosResponse<IotGateway> = await axios({
            method: "patch",
            url: `${API_SERVER_URL}/${GatewayService.GATEWAY}/${id}`,
            data: {
              id,
              equipments: liftId ? [{ id: liftId }] : [],
            },
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${auth.id_token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default GatewayService;
