import { Save } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import LiftService from "../../services/LiftService";
import { parseDate } from "../../utils/parse";
import Calender from "../Calender";
import InfoBox from "../Info/InfoBox";
import InfoList from "../Info/InfoList";

interface Props {
  liftId?: string | number;
  initValue?: string;
  checkbox?: boolean;
  onAfterSubmit?: (ymd: string) => void;
}

const LiftCheckDate = ({ liftId, initValue, checkbox = false, onAfterSubmit }: Props) => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentLift", { returnObjects: true });

  const [value, setValue] = useState<Date>();

  const onChange = (name: string, date: Date) => setValue(date);
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const parsedDate = parseDate(value);
      if (!liftId || !parsedDate || !auth) return;
      await LiftService.updateCheckDate(auth, liftId, parsedDate);
      onAfterSubmit?.(parsedDate);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const checkDateValue = useMemo(() => {
    if (value) return value;
    if (initValue) return new Date(initValue);
    return null;
  }, [initValue, value]);

  return (
    <form onSubmit={onSubmit}>
      <Box pb={2}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.LiftCheckDate.정기검사_예정일}
        </Typography>
        <InfoList>
          <InfoBox title={lang.LiftCheckDate.예정일}>
            <Calender name="checkDate" value={checkDateValue} onChange={onChange} />
          </InfoBox>
          {checkbox && (
            <Box textAlign="right" sx={{ bgcolor: (theme) => theme.palette.background.paper }}>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={lang.LiftCheckDate.동일건물_일괄적용}
                componentsProps={{ typography: { variant: "subtitle2" } }}
              />
            </Box>
          )}
        </InfoList>
      </Box>
      <Box display="flex" justifyContent="end">
        {initValue ? (
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            size="small"
            startIcon={<Save />}
          >
            {lang.LiftCheckDate.수정}
          </Button>
        ) : (
          <Button variant="contained" type="submit" size="small" startIcon={<Save />}>
            {lang.LiftCheckDate.등록}
          </Button>
        )}
      </Box>
    </form>
  );
};

LiftCheckDate.defaultProps = {
  liftId: undefined,
  initValue: undefined,
  checkbox: false,
  onAfterSubmit: undefined,
};

export default LiftCheckDate;
