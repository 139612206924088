import { ArrowBack, Edit } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoBox from "../../components/Info/InfoBox";
import InfoList from "../../components/Info/InfoList";
import InfoRow from "../../components/Info/InfoRow";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { RootState } from "../../redux/reducers";
import EnumService from "../../services/EnumService";
import SensorService from "../../services/SensorService";
import { getEnumToOptions } from "../../utils/parse";

const SensorRegist = () => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("SensorDetail", { returnObjects: true });

  const { id } = useParams();
  const navigate = useNavigate();
  const handleClickBack = () => navigate("/sensor");
  const [options, setOptions] = useState<{
    sensorPosition: SelectOption[];
    sensorDirection: SelectOption[];
    sensorType: SelectOption[];
  }>({
    sensorPosition: [{ label: "위치", value: "" }],
    sensorDirection: [{ label: "방향", value: "" }],
    sensorType: [{ label: "타입", value: "" }],
  });

  const [value, setValue] = useState<Partial<Sensor>>({
    sensorType: "N",
    sn: "",
    bleMac: "",
    wifiMac: "",
    fwVer: "",
  });

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: inputValue } = event.target;
    setValue((prev) => ({ ...prev, [name]: inputValue }));
  }, []);

  const onChangeOption = useCallback((name: string, option: SelectOption) => {
    setValue((prev) => ({ ...prev, [name]: option.value }));
  }, []);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      event.preventDefault();
      if (!auth.auth) return;
      if (id) {
        await SensorService.updateSensor(parseInt(id, 10), value as Sensor, auth);
      } else {
        await SensorService.addSensor(value as Sensor, auth);
      }
      navigate(`/sensor`);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const getSensorDetail = useCallback(async () => {
    try {
      if (!id || !auth) return;
      const sensor = await SensorService.getSensor(id, auth);
      setValue(sensor);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }, [id, auth]);

  const getOptions = useCallback(async () => {
    if (!auth.auth) return;
    const sensorPosition = getEnumToOptions(await EnumService.getEnum("SensorPosition", auth));
    const sensorDirection = getEnumToOptions(await EnumService.getEnum("SensorDirection", auth));
    const sensorType = getEnumToOptions(await EnumService.getEnum("SensorType", auth));
    setOptions({ sensorPosition, sensorDirection, sensorType });
  }, [auth]);

  useEffect(() => {
    (async () => {
      await getOptions();
      await getSensorDetail();
    })();
  }, [getOptions, getSensorDetail]);

  const isEdit = Boolean(id);

  const {
    sensorType,
    sn,
    btryVlt,
    bleMac,
    wifiMac,
    fwVer,
    sampleRate,
    axial,
    radial,
    vertical,
    sensorPosition,
    mesureTime,
  } = value;

  const handleClickCancel = () => navigate(-1);

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>
      <Grid item lg={8}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.센서_정보}
        </Typography>
        <form onSubmit={onSubmit}>
          <InfoList>
            <InfoRow>
              <InfoBox title={lang.Type} flex={1}>
                <Select
                  options={options.sensorType}
                  name="sensorType"
                  value={sensorType}
                  onChange={onChangeOption}
                  required
                />
              </InfoBox>
              <InfoBox title={lang.SN} flex={1}>
                <Input name="sn" value={sn} onChange={onChange} required fullWidth />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.BLE_MAC} flex={1}>
                <Input name="bleMac" value={bleMac} onChange={onChange} required fullWidth />
              </InfoBox>
              <InfoBox title={lang.WIFI_MAC} flex={1}>
                <Input name="wifiMac" value={wifiMac} onChange={onChange} required fullWidth />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.FWVer} flex={1}>
                <Input name="fwVer" value={fwVer} onChange={onChange} fullWidth />
              </InfoBox>
              <InfoBox title={lang.Battery_Volt} flex={1}>
                <Input
                  name="btryVlt"
                  type="number"
                  value={btryVlt}
                  onChange={onChange}
                  required
                  fullWidth
                />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.Sample_Rate} flex={1}>
                <Input
                  name="sampleRate"
                  type="number"
                  value={sampleRate}
                  onChange={onChange}
                  required
                  fullWidth
                />
              </InfoBox>
              <InfoBox title={lang.Measure_Time} flex={1}>
                <Input
                  name="mesureTime"
                  type="number"
                  onChange={onChange}
                  value={mesureTime}
                  required
                  fullWidth
                />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.설치위치} flex={1}>
                <Select
                  name="sensorPosition"
                  value={sensorPosition}
                  options={options.sensorPosition}
                  onChange={onChangeOption}
                  required
                />
              </InfoBox>
              <InfoBox title={lang.축방향} flex={1}>
                <Select
                  name="axial"
                  value={axial}
                  options={options.sensorDirection}
                  onChange={onChangeOption}
                  required
                />
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <InfoBox title={lang.반경방향} flex={1}>
                <Select
                  name="radial"
                  value={radial}
                  options={options.sensorDirection}
                  onChange={onChangeOption}
                />
              </InfoBox>
              <InfoBox title={lang.수직방향} flex={1}>
                <Select
                  name="vertical"
                  value={vertical}
                  options={options.sensorDirection}
                  onChange={onChangeOption}
                />
              </InfoBox>
            </InfoRow>
          </InfoList>
          <Box pt={2} display="flex" justifyContent="end">
            {isEdit ? (
              <Button variant="contained" type="submit" color="secondary" startIcon={<Edit />}>
                {lang.수정}
              </Button>
            ) : (
              <Button variant="contained" type="submit">
                {lang.등록}
              </Button>
            )}

            <Button onClick={handleClickCancel} sx={{ ml: 1 }}>
              {lang.취소}
            </Button>
          </Box>
        </form>
      </Grid>
    </>
  );
};

export default SensorRegist;
