import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Paper from "../../components/Paper";
import SearchBar from "../../components/SearchBar";
import Select from "../../components/Select";
import Table from "../../components/Table/Table";
import useTable from "../../hooks/useTable";
import useTableParams from "../../hooks/useTableParams";
import { RootState } from "../../redux/reducers";
import { setStatus } from "../../redux/reducers/config";
import SensorService from "../../services/SensorService";
import { parseKSTDate } from "../../utils/parse";
import useEnum from "../../hooks/useEnum";

const Sensor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const lang: any = t("selectbox", { returnObjects: true });
  const col: any = t("columns", { returnObjects: true });
  const common: any = t("common", { returnObjects: true });

  const gatewayOptions: SelectOption[] = [
    { label: lang.설치여부, value: "" },
    { label: lang.설치, value: "true" },
    { label: lang.미설치, value: "false" },
  ];

  const sensorTypeOptions: SelectOption[] = [
    { label: lang.센서타입, value: "" },
    { label: lang.진동, value: "V" },
    { label: lang.소음, value: "N" },
  ];

  const searchOption: SelectOption[] = [
    { label: lang.검색조건, value: "" },
    { label: lang.SN, value: "sn" },
    { label: lang.BLE, value: "bleMac" },
    { label: lang.MAC, value: "wifiMac" },
    { label: lang.승강기번호, value: "lift.equipmentNo" },
    { label: lang.업체명, value: "lift.liftMntContracts.liftMntCpny.name" },
    { label: lang.건물명, value: "lift.site.buldNm" },
  ];

  const { data, totalElement, onChangeData } = useTable();
  const {
    searchParams,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    onChangeOrder,
    onChangeOption,
    onChangeSelect,
    onClearSearchParams,
    onSearch,
  } = useTableParams();

  const gatewayYn = searchParams.get("gatewayYn") || "";
  const sensorType = searchParams.get("sensorType") || "";

  useEffect(() => {
    (async () => {
      try {
        if (!auth.auth) return;
        dispatch(setStatus("loading"));
        const response = await SensorService.getAllSensors(
          {
            page,
            size: rowsPerPage,
            sortKey,
            sortDirection,
            searchKey,
            searchValue,
            gatewayYn,
            sensorType,
          },
          auth,
        );
        onChangeData({
          data: response._embedded
            ? response._embedded.liftsensors.map((sensor) => ({
                ...sensor,
                createdAt: parseKSTDate(sensor.createdAt, false),
              }))
            : [],
          totalPage: response.page.totalPages,
          totalElement: response.page.totalElements,
        });
        dispatch(setStatus("ok"));
      } catch (error) {
        dispatch(setStatus("error"));
      }
    })();
  }, [
    auth,
    sortKey,
    sortDirection,
    searchKey,
    searchValue,
    gatewayYn,
    sensorType,
    page,
    rowsPerPage,
    onChangeData,
    dispatch,
  ]);

  const enumName = useEnum(auth);

  const onClickAdd = () => navigate("./regist");
  const onClickRow = (row: Sensor) => navigate(`./detail/${row.id}`);

  const sensorColumns: Column<Sensor>[] = [
    { field: "id", heading: col.No },
    {
      field: "sensorType",
      heading: col.Type,
      setValue: (param: any) => enumName("SensorType", param),
    },
    { field: "sn", heading: col.SN },
    { field: "bleMac", heading: col.BLE_MAC },
    { field: "wifiMac", heading: col.WIFI_MAC },
    { field: "fwVer", heading: col.FW },
    { field: "btryVlt", heading: col.Battery },
    {
      field: "sensorPosition",
      heading: col.설치위치,
      setValue: (param: any) => enumName("SensorPosition", param),
    },
    {
      field: "axial",
      heading: col.축,
      setValue: (param: any) => enumName("SensorDirection", param),
    },
    {
      field: "radial",
      heading: col.반경,
      setValue: (param: any) => enumName("SensorDirection", param),
    },
    {
      field: "vertical",
      heading: col.수직,
      setValue: (param: any) => enumName("SensorDirection", param),
    },
    { field: "createdAt", heading: col.등록일 },
  ];

  return (
    <Grid item md={12}>
      <Box pb={2} textAlign="end">
        <Button variant="contained" onClick={onClickAdd} startIcon={<Add />}>
          {common.센서} {common.추가}
        </Button>
      </Box>
      <Paper>
        <Box display="flex" justifyContent="end" alignItems="center" p={2}>
          <Box width={120} mr={1}>
            <Select
              value={gatewayYn}
              name="gatewayYn"
              options={gatewayOptions}
              required
              onChange={onChangeSelect}
            />
          </Box>
          <Box width={120} mr={1}>
            <Select
              value={sensorType}
              name="sensorType"
              options={sensorTypeOptions}
              required
              onChange={onChangeSelect}
            />
          </Box>
          <Box width={120} mr={1}>
            <Select
              value={searchKey}
              name="searchKey"
              options={searchOption}
              required
              onChange={onChangeSelect}
            />
          </Box>
          <SearchBar onSearch={onSearch} onClear={onClearSearchParams} />
        </Box>
        <Divider />
        <Table
          order={sortDirection}
          orderBy={sortKey}
          page={page}
          data={data}
          columns={sensorColumns}
          rowsPerPage={rowsPerPage}
          totalElement={totalElement}
          onClickRow={onClickRow}
          onChangeOption={onChangeOption}
          onChangeOrder={onChangeOrder}
        />
      </Paper>
    </Grid>
  );
};

export default Sensor;
