import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { parseStatus } from "../../../../utils/parse";
import InfoBox from "../../../Info/InfoBox";
import InfoList from "../../../Info/InfoList";
import InfoRow from "../../../Info/InfoRow";
import DiagnosisDetailEsChains from "./DiagnosisDetailEsChains";
import DiagnosisDetailNoises from "./DiagnosisDetailNoises";
import DiagnosisDetailVibrations from "./DiagnosisDetailVibrations";

interface Props {
  diagnosis?: SensorDiagnosis;
  result: {
    vibrations: LiftVibration[];
    noises: LiftNoises[];
    esChains: EsChain[];
  };
}

const DiagnosisDetailSensorResult = ({ diagnosis, result }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  const { vibrations, noises, esChains } = result;

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {lang.DiagnosisDetailSensorResult.센서_상태정보}
      </Typography>
      <InfoList>
        <InfoRow>
          <InfoBox title={lang.DiagnosisDetailSensorResult.점검ID} flex={1}>
            <Typography variant="body2">{diagnosis?.id}</Typography>
          </InfoBox>
          <InfoBox title={lang.DiagnosisDetailSensorResult.점검_일시} flex={1}>
            <Typography variant="body2">{diagnosis?.dgnssAt}</Typography>
          </InfoBox>
          <InfoBox title={lang.DiagnosisDetailSensorResult.결과} flex={1}>
            <Typography variant="body2">{parseStatus(diagnosis?.status)}</Typography>
          </InfoBox>
        </InfoRow>
      </InfoList>
      <DiagnosisDetailVibrations vibrations={vibrations} />
      <DiagnosisDetailNoises noises={noises} />
      <DiagnosisDetailEsChains esChains={esChains} />
    </>
  );
};

DiagnosisDetailSensorResult.defaultProps = {
  diagnosis: undefined,
};

export default DiagnosisDetailSensorResult;
