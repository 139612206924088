import { Box, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "../../components/Paper";
import SearchBar from "../../components/SearchBar";
import Select from "../../components/Select";
import Table from "../../components/Table/Table";
import useTable from "../../hooks/useTable";
import useTableParams from "../../hooks/useTableParams";
import { RootState } from "../../redux/reducers";
import { setStatus } from "../../redux/reducers/config";
// import SensorService from "../../services/SensorService";
import { siteColumns } from "../../utils/columns";
import SiteService from "../../services/SiteService";

/** 지역 커스텀 해야함 */
const sidoOptions: SelectOption[] = [
  { label: "지역", value: "" },
  { label: "서울특별시", value: "서울특별시" },
  { label: "인천광역시", value: "인천광역시" },
  { label: "부산광역시", value: "부산광역시" },
  { label: "대구광역시", value: "대구광역시" },
  { label: "대전광역시", value: "대전광역시" },
  { label: "울산광역시", value: "울산광역시" },
  { label: "광주광역시", value: "광주광역시" },
  { label: "세종특별자치시", value: "세종특별자치시" },
  { label: "강원도", value: "강원도" },
  { label: "경기도", value: "경기도" },
  { label: "경상남도", value: "경상남도" },
  { label: "경상북도", value: "경상북도" },
  { label: "전라남도", value: "전라남도" },
  { label: "전라북도", value: "전라북도" },
  { label: "제주도", value: "제주도" },
  { label: "충청남도", value: "충청남도" },
  { label: "충청북도", value: "충청북도" },
];

const Site = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("selectbox", { returnObjects: true });

  const searchOption: SelectOption[] = [
    { label: lang.검색조건, value: "" },
    // { label: "S/N", value: "sn" },
    // { label: "BLE", value: "bleMac" },
    // { label: "MAC", value: "wifiMac" },
    // { label: "승강기번호", value: "lift.equipmentNo" },
    // { label: "업체명", value: "lift.liftMntContracts.liftMntCpny.name" },
    { label: lang.건물명, value: "buldNm" },
    { label: lang.주소, value: "address1" },
  ];

  const { data, totalElement, onChangeData } = useTable();
  const {
    searchParams,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    onChangeOrder,
    onChangeOption,
    onChangeSelect,
    onClearSearchParams,
    onSearch,
  } = useTableParams();

  const sido = searchParams.get("sido") || "";

  useEffect(() => {
    (async () => {
      try {
        if (!auth.auth) return;
        dispatch(setStatus("loading"));
        const response = await SiteService.getAllSites(
          {
            page,
            size: rowsPerPage,
            sortKey,
            sortDirection,
            sido,
            searchKey,
            searchValue,
          },
          auth,
        );
        onChangeData({
          data: response._embedded ? response._embedded.sites : [],
          totalPage: response.page.totalPages,
          totalElement: response.page.totalElements,
        });
        // onChangeData({
        //   data: response._embedded
        //     ? response._embedded.liftsensors.map((sensor) => ({
        //         ...sensor,
        //         createdAt: parseKSTDate(sensor.createdAt, false),
        //       }))
        //     : [],
        //   totalPage: response.page.totalPages,
        //   totalElement: response.page.totalElements,
        // });
        dispatch(setStatus("ok"));
      } catch (error) {
        dispatch(setStatus("error"));
      }
    })();
  }, [
    auth,
    sortKey,
    sortDirection,
    searchKey,
    searchValue,
    sido,
    // gatewayYn,
    // sensorType,
    page,
    rowsPerPage,
    onChangeData,
    dispatch,
  ]);

  const onClickRow = (row: Site) => navigate(`./${row.id}`);

  return (
    <Grid item md={12}>
      <Paper>
        <Box display="flex" justifyContent="end" alignItems="center" p={2}>
          <Box width={120} mr={1}>
            <Select
              value={sido}
              name="sido"
              options={sidoOptions}
              required
              onChange={onChangeSelect}
            />
          </Box>
          <Box width={120} mr={1}>
            <Select
              value={searchKey}
              name="searchKey"
              options={searchOption}
              required
              onChange={onChangeSelect}
            />
          </Box>
          <SearchBar onSearch={onSearch} onClear={onClearSearchParams} />
        </Box>
        <Divider />
        <Table
          order={sortDirection}
          orderBy={sortKey}
          page={page}
          data={data}
          columns={siteColumns}
          rowsPerPage={rowsPerPage}
          totalElement={totalElement}
          onClickRow={onClickRow}
          onChangeOption={onChangeOption}
          onChangeOrder={onChangeOrder}
        />
      </Paper>
    </Grid>
  );
};

export default Site;
