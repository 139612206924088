import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography, Divider } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/reducers";
import { enqueueSnackbar } from "../../../redux/reducers/snackbar";
import MaintenanceService from "../../../services/MaintenanceService";
import { maintenanceTaskColumns } from "../../../utils/columns";
import { parseTaskType } from "../../../utils/parse";
import LiftMaintainenceCompanyInfo from "../../Lift/LiftMaintainenceCompanyInfo";
import Paper from "../../Paper";
import Table from "../../Table/Table";
import MaintenanceDetailForm from "./MaintenanceDetailForm";

interface Props {
  lift?: Lift;
}

const MaintenanceDetailList = ({ lift }: Props) => {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState<MaintenanceTask[]>([]);
  const [activeTask, setActiveTask] = useState<MaintenanceTask>();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("common", { returnObjects: true });

  const [year, setYear] = useState("2022");

  const getMaintenanceList = useCallback(async () => {
    try {
      if (!lift || !auth) return;
      const response = await MaintenanceService.getMaintenanceList(lift.id, auth, year);
      setTasks(
        response._embedded
          ? response._embedded.liftMaintenanceTasks.map((task) => ({
              ...task,
              parsedType: parseTaskType(task.type),
            }))
          : [],
      );
    } catch (error: any) {
      dispatch(enqueueSnackbar({ message: error, options: { variant: "error" } }));
    }
  }, [lift, auth, year, dispatch]);

  useEffect(() => {
    getMaintenanceList();
  }, [getMaintenanceList]);

  const onAfterSubmit = useCallback(async () => {
    await getMaintenanceList();
    if (activeTask) setActiveTask(undefined);
  }, [activeTask, getMaintenanceList]);

  const onClickRow = useCallback((task: MaintenanceTask) => setActiveTask(task), []);

  const handleChangeYear = (number: number) =>
    setYear((prev) => {
      const parsedYear = parseInt(prev, 10);
      return (parsedYear + number).toString();
    });

  const onClear = useCallback(() => setActiveTask(undefined), []);

  if (!lift) return null;

  return (
    <>
      <Grid item lg={8} md={12}>
        <Box mb={2}>
          <LiftMaintainenceCompanyInfo lift={lift} />
        </Box>
        <Paper>
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={() => handleChangeYear(-1)}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="subtitle2" component="span">
              {year}
              {lang.년}
            </Typography>
            <IconButton onClick={() => handleChangeYear(1)}>
              <ChevronRight />
            </IconButton>
          </Box>
          <Divider />
          <Table
            columns={maintenanceTaskColumns}
            data={tasks}
            onClickRow={onClickRow}
            hidePagination
          />
        </Paper>
      </Grid>
      <Grid item lg={4} md={12}>
        <MaintenanceDetailForm
          activeTask={activeTask}
          liftId={lift.id}
          liftType={lift.liftType}
          onAfterSubmit={onAfterSubmit}
          onClear={onClear}
        />
      </Grid>
    </>
  );
};

MaintenanceDetailList.defaultProps = {
  lift: undefined,
};

export default MaintenanceDetailList;
