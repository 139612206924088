import {
  ClearDashboardContents,
  DashboardAction,
  DashboardgActionTypes,
  DashboardState,
  SetDashboardLabel,
  SetDashboardBuildings,
  GetDashboardContentsRequest,
  GetDashboardContentsSuccess,
  SetBuildingInfo,
} from "../../@types/redux/dashboard.interface";

const {
  GET_DASHBOARD_CONTENTS_REQUEST,
  GET_DASHBOARD_CONTENTS_SUCCESS,
  CLEAR_DASHBOARD_CONTENTS,
  SET_DASHBAROD_LABEL,
  SET_DASHBOARD_BUILDINGS,
  SET_BULIDING_INFO,
} = DashboardgActionTypes;

export const setDashboardLabel = (
  type: DashboardLabelType,
  value: DateRangeType,
): SetDashboardLabel => ({
  payload: { type, value },
  type: SET_DASHBAROD_LABEL,
});

export const setDashboardBuildings = (
  type: DashboardLabelType,
  buildings: Building[],
): SetDashboardBuildings => ({
  payload: { type, buildings },
  type: SET_DASHBOARD_BUILDINGS,
});

export const clearDashboardContents = (): ClearDashboardContents => ({
  payload: {},
  type: CLEAR_DASHBOARD_CONTENTS,
});

export const getDashboardContentsRequest = (): GetDashboardContentsRequest => ({
  payload: {},
  type: GET_DASHBOARD_CONTENTS_REQUEST,
});

export const getDashboardContentsSuccess = (
  buildings: Building[],
): GetDashboardContentsSuccess => ({
  payload: { buildings },
  type: GET_DASHBOARD_CONTENTS_SUCCESS,
});

export const setBuildingInfo = (building?: Building): SetBuildingInfo => ({
  payload: { building },
  type: SET_BULIDING_INFO,
});

const initialState: DashboardState = {
  status: "ok",
  buildings: [],
  diagnosis: "first",
  inspection: "first",
  selfCheck: "first",
};

export default function DashboradReducer(
  state = initialState,
  action: DashboardAction,
): DashboardState {
  switch (action.type) {
    case GET_DASHBOARD_CONTENTS_REQUEST:
      return { ...state, status: "loading" };
    case GET_DASHBOARD_CONTENTS_SUCCESS:
      return { ...state, buildings: action.payload.buildings };
    case CLEAR_DASHBOARD_CONTENTS: {
      return { ...state, diagnosis: "hide", inspection: "hide", selfCheck: "hide", buildings: [] };
    }
    case SET_DASHBAROD_LABEL:
      return { ...state, status: "loading", [action.payload.type]: action.payload.value };
    case SET_DASHBOARD_BUILDINGS:
      // eslint-disable-next-line
      console.log(state);
      return {
        ...state,
        status: "ok",
        buildings: state.buildings
          .filter((building) => building.type !== action.payload.type)
          .concat(action.payload.buildings),
      };
    case SET_BULIDING_INFO:
      return { ...state, info: action.payload.building };
    default:
      return state;
  }
}
