import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "../../Paper";
import { a11yProps } from "../../../utils/parse";
import TabPanel from "../../TabPanel";
import DiagnosisDetailControllers from "./controller/DiagnosisDetailController";

interface Props {
  liftId?: number | string;
  onChangeDiagnosis: (
    data: SensorDiagnosis | ControllerDiagnosis,
    type: "sensor" | "controller",
  ) => void;
}

const DiagnosisDetailList = ({ liftId, onChangeDiagnosis }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, index: number) => setTabIndex(index);

  const { state } = useLocation();

  useEffect(() => {
    if (!state) setTabIndex(1);
  }, [state]);

  if (!liftId) return null;
  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {lang.DiagnosisDetailList.최근_상태_점검_목록}
      </Typography>
      <Paper mb={2}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={handleChangeTab} variant="fullWidth">
            <Tab label={lang.DiagnosisDetailList.제어반_상태} {...a11yProps(0)} />
            {/* <Tab label={lang.DiagnosisDetailList.센서_상태} {...a11yProps(1)} /> */}
            {/* <Tab label="승차감 정보" {...a11yProps(1)} /> */}
          </Tabs>
        </Box>
        <TabPanel index={0} value={tabIndex}>
          <DiagnosisDetailControllers liftId={liftId} onChangeDiagnosis={onChangeDiagnosis} />
        </TabPanel>
        {/* 센서 상태
        <TabPanel index={1} value={tabIndex}>
          <DiagnosisDetailSensors liftId={liftId} onChangeDiagnosis={onChangeDiagnosis} />
        </TabPanel> */}
        {/* 승차감 정보
        <TabPanel index={1} value={tabIndex}>
          <DiagnosisCompotableInfo liftId={liftId} onChangeDiagnosis={onChangeDiagnosis} />
        </TabPanel> */}
      </Paper>
    </>
  );
};

DiagnosisDetailList.defaultProps = {
  liftId: undefined,
};

export default DiagnosisDetailList;
