import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";

const Loading = () => {
  const { status } = useSelector((state: RootState) => state.config);
  return (
    <Dialog
      open={status === "loading"}
      BackdropProps={{ sx: { bgcolor: `rgba(0, 0, 0, 0.0)`, backdropFilter: `blur(2px)` } }}
    >
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default Loading;
