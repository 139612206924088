import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputBase,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  list: SearchResult[];
  open: boolean;
  onClose: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickItem?: (item: any) => void;
}

const SearchDialog = ({ open, list, onClose, onChange, onClickItem }: Props) => {
  const { t } = useTranslation();
  const common: any = t("common", { returnObjects: true });

  const handleClickItem = (item: any) => () => {
    onClickItem?.(item);
    onClose();
  };

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullscreen}
      open={open}
      onClose={onClose}
      scroll="paper"
      sx={!fullscreen ? { "& .MuiDialog-paper": { height: 400 } } : undefined}
      BackdropProps={{ sx: { bgcolor: `rgba(0, 0, 0, 0.1)`, backdropFilter: `blur(2px)` } }}
    >
      <Box
        p={2}
        display="flex"
        alignItems="center"
        sx={!fullscreen ? { minWidth: 500 } : undefined}
      >
        <Typography color="primary" sx={{ lineHeight: 0, mr: 1 }}>
          <Search />
        </Typography>
        <InputBase fullWidth placeholder={common.검색하기} onChange={onChange} />
      </Box>

      <DialogContent dividers sx={{ p: 0 }}>
        {!list.length ? (
          <Typography variant="body2" p={2}>
            {common.검색결과없음}
          </Typography>
        ) : (
          list.map((item, idx) => (
            <ListItem
              divider={list.length - 1 !== idx}
              disablePadding
              key={item.id}
              onClick={handleClickItem(item.data)}
            >
              <ListItemButton>
                <ListItemText
                  primary={item.title}
                  secondary={item.subtitle}
                  secondaryTypographyProps={{ variant: "caption" }}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{common.닫기}</Button>
      </DialogActions>
    </Dialog>
  );
};

SearchDialog.defaultProps = {
  onClickItem: undefined,
};

export default SearchDialog;
