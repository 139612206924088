import axios, { AxiosResponse } from "axios";
import QueryString from "qs";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";

export interface GetMaintenanceListResponse extends ServerResponse {
  _embedded?: {
    liftMaintenanceTasks: MaintenanceTask[];
  };
}

export interface AddMaintenceTaskBody {
  type: TaskType;
  equipmentCtgr: {
    id: number;
  };
  troubleYmd: string;
  workYmd: string;
  workDtl: string;
  cost: number;
  worker: {
    id: number;
  };
}

interface GetMaintenanceRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: "asc" | "desc";
  searchKey?: string;
  searchValue?: string | number;
  installedYn?: string;
  madeBy?: string;
  exact?: boolean;
}

class MaintenanceService {
  private static MAINTENANCE = "liftMaintenanceTasks";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") MaintenanceService.PATH = "api";
    else MaintenanceService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getAllMaintenances(
    {
      page,
      size,
      sortKey,
      sortDirection,
      searchKey,
      searchValue,
      installedYn,
      madeBy,
      exact,
    }: GetMaintenanceRequest,
    auth: Auth,
  ): Promise<GetMaintenanceListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          let sort: string | undefined;
          if (sortKey && sortDirection) {
            sort = `${sortKey},${sortDirection}`;
          }
          const token = MaintenanceService.pathSetter(auth);
          const response: AxiosResponse<GetMaintenanceListResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${MaintenanceService.PATH}/${MaintenanceService.MAINTENANCE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              sort,
              ...(installedYn && {
                instlYn: installedYn,
              }),
              ...(searchKey &&
                searchValue && { [searchKey]: exact ? searchValue : `contains(${searchValue})` }),
              ...(madeBy && {
                madeBy,
              }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getMaintenanceList(
    liftId: string | number,
    auth: Auth,
    year?: string,
  ): Promise<GetMaintenanceListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = MaintenanceService.pathSetter(auth);
          const response: AxiosResponse<GetMaintenanceListResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${MaintenanceService.PATH}/${MaintenanceService.MAINTENANCE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "lift.id": liftId,
              ...(year && {
                workYmd: `gte(${year}-01-01)&workYmd=and(lte(${year}-12-31))`,
              }),
            },
            paramsSerializer: (params) => {
              return QueryString.stringify(params, { arrayFormat: "brackets" })
                .replace(/%5B/g, "")
                .replace(/%5D/g, "")
                .replace(/%28/g, "(")
                .replace(/%29/g, ")")
                .replace(/%2C/g, ",")
                .replace(/%26/g, "&")
                .replace(/%3D/g, "=");
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addMaintenanceTask(
    liftId: string | number,
    task: AddMaintenceTaskBody,
    auth: Auth,
  ): Promise<GetMaintenanceListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = MaintenanceService.pathSetter(auth);
          const response: AxiosResponse<GetMaintenanceListResponse> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${MaintenanceService.PATH}/${MaintenanceService.MAINTENANCE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { lift: { id: liftId }, ...task },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async editMaintenanceTask(
    liftId: string | number,
    taskId: string | number,
    task: AddMaintenceTaskBody,
    auth: Auth,
  ): Promise<GetMaintenanceListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = MaintenanceService.pathSetter(auth);
          const response: AxiosResponse<GetMaintenanceListResponse> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${MaintenanceService.PATH}/${MaintenanceService.MAINTENANCE}/${taskId}`,
            data: { id: taskId, lift: { id: liftId }, ...task },
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async removeMaintenanceTask(
    taskId: string | number,
    auth: Auth,
  ): Promise<GetMaintenanceListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = MaintenanceService.pathSetter(auth);
          const response: AxiosResponse<GetMaintenanceListResponse> = await axios({
            method: "DELETE",
            url: `${API_SERVER_URL}/${MaintenanceService.PATH}/${MaintenanceService.MAINTENANCE}/${taskId}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default MaintenanceService;
