import React from "react";
import { CircularProgress } from "@mui/material";

const SlowCircularProgress = () => {
  return (
    <CircularProgress
      size={20}
      thickness={6}
      color="info"
      style={{ animationDuration: "10s", verticalAlign: "text-top" }}
    />
  );
};

export default SlowCircularProgress;
