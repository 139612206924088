import { useCallback, useState } from "react";

const useTable = (row = 20) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [tableData, setTableData] = useState<TableData>({
    totalElement: 0,
    totalPage: 0,
    data: [],
  });
  const [tableOption, setTableOption] = useState<TableOption>({
    page: 0,
    rowsPerPage: row,
  });

  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<"desc" | "asc">("asc");

  const { page, rowsPerPage } = tableOption;
  const { data, totalElement, totalPage } = tableData;

  const onChangeData = useCallback((newData: TableData) => {
    setTableData(newData);
  }, []);

  const onChangeOption = useCallback((newOption: number, key: keyof TableOption) => {
    setTableOption((prev) => ({ ...prev, [key]: newOption }));
  }, []);

  const onChangeOrder = useCallback(
    (event: any, property: any) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const onChangeCheckBox = useCallback((newSelected: number[]) => {
    setSelected(newSelected);
  }, []);

  const onClearOption = useCallback(() => {
    setOrder("asc");
    setOrderBy("");
  }, []);

  return {
    selected,
    orderBy,
    order,
    page,
    rowsPerPage,
    data,
    totalElement,
    totalPage,
    onChangeData,
    onChangeOption,
    onChangeOrder,
    onChangeCheckBox,
    onClearOption,
  };
};

export default useTable;
