import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "../../components/Paper";
import SearchBar from "../../components/SearchBar";
import Select from "../../components/Select";
import Table from "../../components/Table/Table";
import useTable from "../../hooks/useTable";
import useTableParams from "../../hooks/useTableParams";
import { RootState } from "../../redux/reducers";
import { setStatus } from "../../redux/reducers/config";
import GatewayService from "../../services/GatewayService";
import { gatewayColumns } from "../../utils/columns";
import { parseKSTDate } from "../../utils/parse";

const Gateway = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("selectbox", { returnObjects: true });
  const common: any = t("common", { returnObjects: true });

  const companyOptions: SelectOption[] = [
    { label: lang.검색조건, value: "" },
    { label: lang.SN, value: "sn" },
    { label: lang.BLE, value: "bleMac" },
    { label: lang.MAC, value: "wifiMac" },
    { label: lang.IP, value: "apGwIp" },
    { label: lang.건물명, value: "equipments.site.buldNm" },
    { label: lang.업체명, value: "equipments.mntContracts.mntCpny.name" },
    { label: lang.승강기번호, value: "equipments.equipmentNo" },
  ];

  const installYnOptions: SelectOption[] = [
    { label: lang.설치여부, value: "" },
    { label: lang.설치, value: "true" },
    { label: lang.미설치, value: "false" },
  ];

  /** 제조사 커스텀 해야함 */
  const madebyOptions: SelectOption[] = [
    { label: lang.제조사, value: "" },
    { label: "NAON", value: "NAON" },
    { label: "ERC", value: "ERC" },
  ];

  const { data, totalElement, onChangeData } = useTable();
  const {
    searchParams,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    onChangeOrder,
    onChangeOption,
    onChangeSelect,
    onClearSearchParams,
    onSearch,
  } = useTableParams();

  const installedYn = searchParams.get("installedYn") || "";
  const madeBy = searchParams.get("madeBy") || "";

  useEffect(() => {
    (async () => {
      try {
        if (!auth.auth) return;
        dispatch(setStatus("loading"));
        const response = await GatewayService.getAllGateways(
          {
            page,
            size: rowsPerPage,
            sortKey,
            sortDirection,
            searchKey,
            searchValue,
            installedYn,
            madeBy,
          },
          auth,
        );
        onChangeData({
          data: response._embedded
            ? response._embedded.iotGateways.map((gateway) => ({
                ...gateway,
                createdAt: parseKSTDate(gateway.createdAt, false),
              }))
            : [],
          totalPage: response.page.totalPages,
          totalElement: response.page.totalElements,
        });
        dispatch(setStatus("ok"));
      } catch (error) {
        dispatch(setStatus("error"));
      }
    })();
  }, [
    auth,
    searchKey,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    installedYn,
    madeBy,
    onChangeData,
    dispatch,
  ]);

  const onClickAdd = () => navigate("./regist");
  const onClickRow = (row: IotGateway) => navigate(`./detail/${row.id}`);

  return (
    <Grid item md={12}>
      <Box pb={2} textAlign="end">
        <Button variant="contained" onClick={onClickAdd} startIcon={<Add />}>
          {common.게이트웨이} {common.추가}
        </Button>
      </Box>
      <Paper>
        <Box display="flex" justifyContent="end" alignItems="center" p={2}>
          <Box width={120} mr={1}>
            <Select
              value={installedYn}
              name="installedYn"
              options={installYnOptions}
              required
              onChange={onChangeSelect}
            />
          </Box>
          <Box width={120} mr={1}>
            <Select
              value={madeBy}
              name="madeBy"
              options={madebyOptions}
              required
              onChange={onChangeSelect}
            />
          </Box>
          <Box width={120} mr={1}>
            <Select
              name="searchKey"
              value={searchKey}
              options={companyOptions}
              onChange={onChangeSelect}
              required
            />
          </Box>
          <SearchBar onSearch={onSearch} onClear={onClearSearchParams} />
        </Box>
        <Divider />
        <Table
          order={sortDirection}
          orderBy={sortKey}
          page={page}
          rowsPerPage={rowsPerPage}
          data={data}
          columns={gatewayColumns}
          totalElement={totalElement}
          onClickRow={onClickRow}
          onChangeOption={onChangeOption}
          onChangeOrder={onChangeOrder}
        />
      </Paper>
    </Grid>
  );
};

export default Gateway;
