import React, { ChangeEvent, useMemo, useState } from "react";
import { debounce, IconButton, InputAdornment } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import Input from "./Input";

interface Props {
  placeholder?: string;
  delayQueryTime?: number;
  onSearch: (value: string) => void;
  onClear?: () => void;
  type?: string;
}

const SearchBar = ({ type, onSearch, onClear, placeholder, delayQueryTime = 800 }: Props) => {
  const [value, setValue] = useState<string>();

  const delayQuery = useMemo(
    () =>
      debounce((inputValue: string) => {
        onSearch(inputValue);
      }, delayQueryTime),
    [onSearch, delayQueryTime],
  );

  const handleChange = ({ target: { value: inputValue } }: ChangeEvent<HTMLInputElement>) => {
    setValue(inputValue);
    delayQuery(inputValue || "");
  };

  const handleClear = () => {
    setValue("");
    onClear?.();
  };

  return (
    <Input
      type={type}
      placeholder={placeholder}
      fullWidth
      required
      value={value}
      onChange={handleChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handleClear}>
            {value ? <Close fontSize="small" /> : <Search fontSize="small" />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

SearchBar.defaultProps = {
  delayQueryTime: 1000,
  onClear: undefined,
  placeholder: "",
  type: undefined,
};

export default SearchBar;
