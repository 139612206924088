import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "../../components/Paper";
import SearchBar from "../../components/SearchBar";
import Select from "../../components/Select";
import Table from "../../components/Table/Table";
import useTable from "../../hooks/useTable";
import useTableParams from "../../hooks/useTableParams";
import { RootState } from "../../redux/reducers";
import UserService from "../../services/UserService";
import { userColumns } from "../../utils/columns";
import { parseKSTDate } from "../../utils/parse";

const User = () => {
  const navigate = useNavigate();
  const { data, totalElement, onChangeData } = useTable();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("selectbox", { returnObjects: true });
  const common: any = t("common", { returnObjects: true });

  const enableOption = [
    { label: lang.전체, value: "" },
    { label: lang.활성, value: "true" },
    { label: lang.비활성, value: "false" },
  ];

  const searchKeyOptions = [
    { label: lang.검색조건, value: "" },
    { label: lang.이름, value: "name" },
    { label: lang.업체명, value: "company.name" },
    { label: lang.아이디, value: "loginId" },
    { label: lang.자체점검ID, value: "koeisaId" },
    { label: lang.이메일, value: "email" },
    { label: lang.연락처, value: "telNo" },
  ];

  const {
    searchParams,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    onChangeOrder,
    onChangeOption,
    onChangeSelect,
    onSearch,
    onClearSearchParams,
  } = useTableParams();

  const enabledYn = searchParams.get("enabledYn") || "";
  const onClickRow = (row: any) => navigate(`./detail/${row.id}`);
  const handleClickAdd = () => navigate("./regist");

  useEffect(() => {
    (async () => {
      if (!auth.auth) return;
      const response = await UserService.getUserList(
        {
          page,
          size: rowsPerPage,
          sortKey,
          sortDirection,
          enabledYn,
          searchKey,
          searchValue,
        },
        auth,
      );
      onChangeData({
        data: response._embedded
          ? response._embedded.users.map((user) => ({
              ...user,
              createdAt: parseKSTDate(user.createdAt, false),
            }))
          : [],
        totalPage: response.page.totalPages,
        totalElement: response.page.totalElements,
      });
    })();
  }, [
    auth,
    searchKey,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    enabledYn,
    onChangeData,
  ]);

  return (
    <Grid item md={12}>
      {auth && auth.auth === "A" ? (
        <Box pb={2} textAlign="end">
          <Button variant="contained" onClick={handleClickAdd} startIcon={<Add />}>
            {common.사용자} {common.추가}
          </Button>
        </Box>
      ) : (
        ""
      )}
      <Paper>
        <Box display="flex" justifyContent="end" alignItems="center" p={2}>
          <Box width={120} mr={1}>
            <Select
              name="enabledYn"
              value={enabledYn}
              options={enableOption}
              onChange={onChangeSelect}
              required
            />
          </Box>
          <Box width={120} mr={1}>
            <Select
              name="searchKey"
              value={searchKey}
              options={searchKeyOptions}
              onChange={onChangeSelect}
              required
            />
          </Box>
          <SearchBar onSearch={onSearch} onClear={onClearSearchParams} />
        </Box>
        <Divider />
        <Table
          order={sortDirection}
          orderBy={sortKey}
          page={page}
          data={data}
          columns={userColumns}
          totalElement={totalElement}
          rowsPerPage={rowsPerPage}
          onClickRow={onClickRow}
          onChangeOption={onChangeOption}
          onChangeOrder={onChangeOrder}
        />
      </Paper>
    </Grid>
  );
};

export default User;
