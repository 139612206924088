import axios, { AxiosResponse } from "axios";
import QueryString from "qs";
import { API_SERVER_URL, REPORT_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetLiftsResponse extends ServerResponse {
  _embedded?: {
    lifts: Lift[];
  };
}

interface GetLiftsRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: "asc" | "desc";
  fixedSortKey?: string;
  searchKey?: string;
  searchValue?: string | number | string[];
  iotYn?: string;
  mntYn?: string;
  contains?: boolean;
  stateKey?: string;
  stateValue?: string | string[];
}

interface GetMaintenanceTasksRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: SortDirectionType;
  searchKey?: string;
  searchValue?: string | number;
  type?: string;
  liftTypeCode?: string;
  categoryName?: string;
  itemName?: string;
}

interface AddOpenDateLiftRequest {
  buildingName: string;
  sido: string;
  sigungu: string;
}

interface GetRegistedLiftRequest extends AddOpenDateLiftRequest {
  page?: number | string;
  size?: number | string;
  mntCpnyId?: number | string;
  equipmentNo?: number | string;
  searchKey?: string;
  searchValue?: string | number | string[];
}

interface GetLatestControllerStatusRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: "asc" | "desc";
  searchKey?: string;
  searchValue?: string | number | string[];
  contains?: boolean;
  status?: DiagnosisStatus | DiagnosisStatus[] | string;
}

interface GetLatestControllerStatusResponse {
  page: PageData;
  resultLiftCntlrs: ControllerStatus[];
}

class LiftService {
  private static LIFTS = "lifts";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") LiftService.PATH = "api";
    else LiftService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async searchOpenDataCode(
    equipmentNo: number | string,
    auth: Auth,
  ): Promise<OpenDataLift> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<OpenDataLift> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/opendataLiftInfo`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              equipmentNo,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getAllLifts(
    {
      page,
      size,
      sortKey,
      sortDirection,
      fixedSortKey,
      searchKey,
      searchValue,
      iotYn,
      mntYn,
      contains = true,
      stateKey,
      stateValue,
    }: GetLiftsRequest,
    auth: Auth,
  ): Promise<GetLiftsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        const token = LiftService.pathSetter(auth);
        const sort = [];
        if (sortKey && sortDirection) sort.push(`${sortKey},${sortDirection}`);
        if (fixedSortKey) sort.push(`${fixedSortKey},desc`);
        try {
          const response: AxiosResponse<GetLiftsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              ...(sort.length > 0 && {
                sort,
              }),
              ...(iotYn && {
                iotYn: iotYn === "O",
              }),
              ...(mntYn && {
                mntYn: mntYn === "O",
              }),
              ...(searchKey &&
                searchValue && {
                  [searchKey]: contains ? `contains(${searchValue})` : searchValue,
                }),
              ...(stateKey &&
                stateValue && {
                  [stateKey]: stateValue,
                }),
            },
            paramsSerializer: (params) => {
              return QueryString.stringify(params, { arrayFormat: "brackets" })
                .replaceAll(/%5B/g, "")
                .replaceAll(/%5D/g, "")
                .replace(/%28/g, "(")
                .replace(/%29/g, ")")
                .replace(/%2C/g, ",");
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getAllLifts2(
    { page, size, sortKey, sortDirection, fixedSortKey, searchKey, searchValue }: GetLiftsRequest,
    auth: Auth,
  ): Promise<GetLiftsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        const token = LiftService.pathSetter(auth);
        const sort = [];
        if (sortKey && sortDirection) sort.push(`${sortKey},${sortDirection}`);
        if (fixedSortKey) sort.push(`${fixedSortKey},desc`);
        try {
          const response: AxiosResponse<GetLiftsResponse> = await axios({
            method: "GET",
            url: `${REPORT_URL}/${LiftService.PATH}/${LiftService.LIFTS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              ...(sort.length > 0 && {
                sort,
              }),
              ...(searchKey &&
                searchValue && {
                  [searchKey]: searchValue,
                }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getLift(id: number | string, auth: Auth): Promise<Lift> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<Lift> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addLift(lift: LiftRegistType, auth: Auth): Promise<Lift> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          console.log(lift);
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<Lift> = await axios({
            method: "post",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: lift,
          });
          resolve(response.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  public static async syncLift(auth: Auth, liftId: string | number): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<void> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/opendataImportByLiftId`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              id: liftId,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async updateCheckDate(
    auth: Auth,
    id: number | string,
    fdrmChkYmd?: string,
  ): Promise<Lift> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<Lift> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/${id}:updateFdrmChkYmd`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id,
              fdrmChkYmd,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async importOpenDataLiftByBuilding(
    { buildingName, sido, sigungu }: AddOpenDateLiftRequest,
    auth: Auth,
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/opendataImportByBuilding`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              buldNm: buildingName,
              sido,
              sigungu,
            },
          });

          if (response.data.message) {
            throw new Error(response.data.message);
          }

          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async importOpenDataLiftByNo(
    equipmentNo: number | string,
    auth: Auth,
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/opendataImportByEquipmentNo`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              equipmentNo,
            },
          });

          if (response.data.message) {
            throw new Error(response.data.message);
          }

          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getRegiestedLifts(
    { equipmentNo, buildingName, mntCpnyId, sido, sigungu, page, size }: GetRegistedLiftRequest,
    auth: Auth,
  ): Promise<GetLiftsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<GetLiftsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              "site.buldNm": `contains(${buildingName})`,
              "liftMntContracts.liftMntCpany.id": mntCpnyId,
              "site.sido": `contains(${sido})`,
              "site.sigungu": `contains(${sigungu})`,
              equipmentNo,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getMaintenanceTasks(
    {
      page,
      size,
      searchKey,
      searchValue,
      liftTypeCode,
      type,
      categoryName,
      itemName,
    }: GetMaintenanceTasksRequest,
    auth: Auth,
  ): Promise<GetLiftsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<GetLiftsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              sort: "lastMntTask.workYmd,desc",
              ...(liftTypeCode && {
                liftType: liftTypeCode,
              }),
              ...(type && {
                "lastMntTask.type": type,
              }),
              ...(categoryName && {
                "lastMntTask.equipmentCtgr.parent.name": categoryName,
              }),
              ...(itemName && {
                "lastMntTask.equipmentCtgr.name": itemName,
              }),
              ...(searchKey &&
                searchValue && {
                  [searchKey]: `contains(${searchValue})`,
                }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getLiftCheckupForm(
    liftId: string | number,
    chkYyyymm: any,
    auth: Auth,
  ): Promise<SelfCheckupResult[]> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<SelfCheckupResult[]> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/${liftId}:newSelfCheckup`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              chkYyyymm,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async importLiftEquipments(
    liftId: string | number,
    sourceId: string | number,
    auth: Auth,
  ): Promise<SelfCheckupResult[]> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<SelfCheckupResult[]> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/${liftId}:copyEquipmentPart`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              sourceLift: sourceId,
            },
            data: {
              id: liftId,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async linkMaintainCompany(
    auth: Auth,
    liftId: string | number,
    companyId: string | number,
  ): Promise<SelfCheckupResult[]> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<SelfCheckupResult[]> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/${liftId}:registerMntCompany`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: liftId,
              ...(companyId && {
                liftMntCpny: {
                  id: companyId,
                },
              }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async unlinkMaintainCompany(auth: Auth, liftId: string | number): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<void> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/${liftId}:unlinkMntCompany`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: liftId,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getLatestControllerStatus(
    {
      page,
      size,
      status,
      sortKey,
      sortDirection,
      searchKey,
      searchValue,
    }: GetLatestControllerStatusRequest,
    auth: Auth,
  ): Promise<GetLatestControllerStatusResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = LiftService.pathSetter(auth);
          const response: AxiosResponse<GetLatestControllerStatusResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${LiftService.PATH}/${LiftService.LIFTS}/:latestCntlrStatus`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              ...(sortKey &&
                sortDirection && {
                  sort: `${sortKey},${sortDirection}`,
                }),
              ...(searchKey &&
                searchValue && {
                  [searchKey]: searchValue,
                }),
              status,
            },
            paramsSerializer: (params) => {
              return QueryString.stringify(params, { arrayFormat: "brackets" })
                .replaceAll(/%5B/g, "")
                .replaceAll(/%5D/g, "")
                .replace(/%28/g, "(")
                .replace(/%29/g, ")")
                .replace(/%2C/g, ",");
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default LiftService;
