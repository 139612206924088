import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./css/print.css";
import SelfCheckupService from "../../services/SelfCheckupService";
import { setStatus } from "../../redux/reducers/config";
import { Auth } from "../../services/AuthService";
import LiftService from "../../services/LiftService";
import { parseDate } from "../../utils/parse";

// interface Props {
//   open: boolean;
// }

const ResultReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const liftId = searchParams.get("liftId");
  const selChkYm = searchParams.get("selChkYm");
  const selChkDt = searchParams.get("selChkDt");
  const createdAt = searchParams.get("createdAt");
  const token = localStorage.getItem("tok");
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const print = () => {
    window.print();
  };
  const zoomIn = () => {
    setZoomLevel((prev) => prev + 0.1);
  };
  const zoomOut = () => {
    setZoomLevel((prev) => prev - 0.1);
  };

  const [lift, setLift] = useState<Lift>();
  const [results, setResults] = useState<SelfCheckupResult[][]>([]);
  const [prevResults, setPrevResults] = useState<SelfCheckupResult[][]>([]);

  const reportRow = (result: SelfCheckupResult, prevResult: SelfCheckupResult) => {
    return (
      <tr key={result.id}>
        <td>{result.liftSelChkItem.selChkItemCtgr?.titNo}</td>
        <td>{result.liftSelChkItem.selChkItemCtgr?.selChkItemNm}</td>
        <td>{result.liftSelChkItem.selChkItemDtlNm}</td>
        <td className="txtCenter">{result.liftSelChkItem.selChkCycle}</td>
        <td className="txtCenter">{prevResult.selChkRslt}</td>
        <td className="txtCenter">{result.selChkRslt}</td>
      </tr>
    );
  };

  const reportPage = (
    resultArr: SelfCheckupResult[],
    prevResultArr: SelfCheckupResult[],
    arrIndex: number,
  ) => {
    return (
      <div className="page">
        <div className="innerPage">
          <div className="section">
            <h2>Result Report</h2>
          </div>
          <div className="section">
            {arrIndex === 0 ? <h3>점검 항목별 자체 점검 결과</h3> : ""}
            <div className="whiteBox">
              <span className="subTxt txtRight">
                * 점검결과 : A-양호, B-주의관찰, C-긴급수리, 제외-점검주기 아님
              </span>
              <div className="tableWrap">
                <table className="table type3">
                  <colgroup>
                    <col width="6%" />
                    <col width="32%" />
                    <col width="44%" />
                    <col width="6%" />
                    <col width="6%" />
                    <col width="6%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={2} rowSpan={2}>
                        점검항목
                      </th>
                      <th rowSpan={2}>점검내용</th>
                      <th rowSpan={2}>점검주기</th>
                      <th colSpan={2}>점검결과</th>
                    </tr>
                    <tr>
                      <th>직전</th>
                      <th>당월</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultArr.map((result, index) => {
                      return reportRow(result, prevResultArr[index]);
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {arrIndex === results.length - 1 ? (
              <div className="whiteBox">
                <h4>특이사항</h4>
                <p className="dec" />
                <br />
                <br />
                <br />
                <br />
              </div>
            ) : (
              ""
            )}
          </div>
          <p className="pageNum">{arrIndex + 2}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const newAuth: Auth = {
          auth: "",
          exp: 0,
          id: 0,
          sub: "",
          id_token: token ?? "",
          name: "",
          userId: "",
        };
        if (!liftId || !selChkYm) return;
        const newLift = await LiftService.getLift(liftId, newAuth);
        setLift(newLift);
        const newResults = await SelfCheckupService.getCheckupResults(
          {
            liftId,
            selChkYm,
          },
          newAuth,
        );
        const newPrevResults = await SelfCheckupService.getCheckupResults(
          {
            liftId,
            selChkYm: (Number(selChkYm) - 1).toString(),
          },
          newAuth,
        );
        if (newResults._embedded && newPrevResults._embedded) {
          const newR1: SelfCheckupResult[] =
            newResults._embedded.liftSelfCheckupItemResults.reverse();
          const newR2: SelfCheckupResult[] =
            newPrevResults._embedded.liftSelfCheckupItemResults.reverse();
          const tempR1: SelfCheckupResult[] = [];
          const tempR2: SelfCheckupResult[] = [];
          const arr1: SelfCheckupResult[][] = [];
          const arr2: SelfCheckupResult[][] = [];
          for (let i = 0; i < newR1.length; i += 1) {
            tempR1.push(newR1[i]);
            tempR2.push(newR2[i]);
            if (
              i === 16 ||
              i === 38 ||
              i === 68 ||
              i === 98 ||
              i === 118 ||
              i === 136 ||
              i === 158 ||
              i === 178 ||
              i === newR1.length - 1
            ) {
              arr1.push([...tempR1]);
              arr2.push([...tempR2]);
              tempR1.length = 0;
              tempR2.length = 0;
            }
          }
          setResults(arr1);
          setPrevResults(arr2);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [liftId, selChkYm, token]);

  return (
    // <Dialog open={open} maxWidth="xl">
    <>
      <div className="printArea" style={{ zoom: zoomLevel }}>
        <div className="page front2">
          <div className="innerPage">
            <div className="section">
              <h1 className="logo">
                <img src="img/logo.png" alt="" />
              </h1>
              <h2>Result Report</h2>
            </div>

            <div className="section wr60" style={{ height: "680px" }}>
              <h3>
                Elevator Self-Inspection Result Report
                <span className="koTitle">승강기 자체 점검 결과 보고서</span>
                <span className="date">
                  작성일: <span>{new Date().toISOString().substring(0, 10)}</span>
                </span>
              </h3>

              <h4>기본정보</h4>
              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>승강기번호</th>
                      <td>{lift?.equipmentNo}</td>
                      <th>제조업체</th>
                      <td>{lift?.mfCpnyNm}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>건물명</th>
                      <td colSpan={3}>{lift?.site.buldNm}</td>
                    </tr>
                    <tr>
                      <th>소재지</th>
                      <td colSpan={3}>{lift?.site.address1}</td>
                    </tr>
                    <tr>
                      <th>설치위치</th>
                      <td>{lift?.itlpc}</td>
                      <th>종류</th>
                      <td>{lift?.liftType}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>모델명</th>
                      <td>{lift?.modelName}</td>
                      <th>적재하중</th>
                      <td>{lift?.loadngLd}</td>
                    </tr>
                    <tr>
                      <th>최대정원</th>
                      <td>{lift?.ratedCap}</td>
                      <th>정격속도</th>
                      <td>{lift?.ratedSpd}</td>
                    </tr>
                    <tr>
                      <th>운행구간</th>
                      <td>{lift?.operSctn}</td>
                      <th>운행층수</th>
                      <td>{lift?.operFlrCnt}</td>
                    </tr>
                    <tr>
                      <th>지상층수</th>
                      <td>{lift?.groundFlrCnt}</td>
                      <th>지하층수</th>
                      <td>{lift?.undgrndFloorCnt}</td>
                    </tr>
                    <tr>
                      <th>유효종료일</th>
                      <td colSpan={3}>{parseDate(lift?.validEndYmd)}</td>
                    </tr>
                    <tr>
                      <th>점검일시</th>
                      <td colSpan={3}>
                        {selChkDt} (전산등록일: {createdAt})
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="tableWrap">
                <table className="table">
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>관리주체</th>
                      <td colSpan={3}>{lift?.mangeNm}</td>
                    </tr>
                    <tr>
                      <th>유지보수업체</th>
                      <td colSpan={3}>{lift?.mntCpnyNm ?? lift?.mntWorker.company.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {results.map((result, index) => {
          return reportPage(result, prevResults[index], index);
        })}
      </div>

      <div className="btnArea no-print">
        <button type="button" className="btnPrint" id="zoom-in" onClick={() => zoomIn()}>
          <i className="icoZoomIn" aria-hidden="true" />
          <span className="sr-only">확대</span>
        </button>
        <button type="button" className="btnPrint" id="zoom-out" onClick={() => zoomOut()}>
          <i className="icoZoomOut" aria-hidden="true" />
          <span className="sr-only">축소</span>
        </button>
        <button type="button" className="btnPrint" onClick={() => print()}>
          <i className="icoPrint" aria-hidden="true" />
          <span className="sr-only">프린트</span>
        </button>
      </div>
    </>
  );
};

export default ResultReport;
