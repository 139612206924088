import { Box, BoxProps, styled, SxProps, Typography, TypographyProps } from "@mui/material";
import React from "react";

interface Props extends BoxProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  subtitleProps?: TypographyProps;
  titleSx?: SxProps;
  valueSx?: SxProps;
}

const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: theme.palette.grey[100],
  flex: 1,
  overflow: "hidden",
}));

const Container = styled(Box)(() => ({
  display: "flex",
  minHeight: 40,
  flexWrap: "wrap",
}));

const ValueBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
  background: theme.palette.background.paper,
  overflow: "hidden",
}));

const InfoBox = ({
  children,
  title,
  subtitle,
  titleSx,
  valueSx,
  subtitleProps,
  ...restProps
}: Props) => {
  return (
    <Container {...restProps}>
      <TitleBox sx={{ ...titleSx }}>
        <Box px={1.5} py={0.5} width="100%">
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
          {subtitle ? (
            <Typography variant="caption" lineHeight={1} {...subtitleProps}>
              {subtitle}
            </Typography>
          ) : null}
        </Box>
      </TitleBox>
      <ValueBox sx={{ ...valueSx }}>
        <Box px={1.5} width="100%">
          {children}
        </Box>
      </ValueBox>
    </Container>
  );
};

InfoBox.defaultProps = {
  titleSx: undefined,
  valueSx: undefined,
  subtitle: "",
  subtitleProps: undefined,
};

export default InfoBox;
