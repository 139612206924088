import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers";
import InfoBox from "../Info/InfoBox";
import InfoList from "../Info/InfoList";
import InfoRow from "../Info/InfoRow";

interface Props {
  site?: Site;
  hideTitle?: boolean;
}

const SiteInfo = ({ site, hideTitle = false }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentSite", { returnObjects: true });
  // eslint-disable-next-line
  const { auth } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {!hideTitle && (
        <Typography variant="subtitle2" gutterBottom>
          {lang.SiteInfo.건물_정보}
        </Typography>
      )}
      <InfoList>
        <InfoRow>
          <InfoBox title={lang.SiteInfo.지역} flex={1}>
            <Typography variant="body2">{site?.sido}</Typography>
          </InfoBox>
          <InfoBox title={lang.SiteInfo.건물명} flex={1}>
            <Typography variant="body2">{site?.buldNm}</Typography>
          </InfoBox>
        </InfoRow>
        <InfoRow>
          <InfoBox title={lang.SiteInfo.주소} flex={1} valueSx={{ flex: 3 }}>
            <Typography variant="body2">{site?.address1}</Typography>
          </InfoBox>
        </InfoRow>
      </InfoList>
    </>
  );
};

SiteInfo.defaultProps = {
  site: undefined,
  hideTitle: false,
};

export default SiteInfo;
