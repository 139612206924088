import { Box, Button, Container, Divider, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loginRequest } from "../redux/reducers/auth";
import { RootState } from "../redux/reducers";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("common", { returnObjects: true });

  const [values, setValue] = useState({ id: "", password: "" });

  const handleChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement>) =>
    setValue((prev) => ({ ...prev, [name]: value }));

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(loginRequest(values.id, values.password, navigate));
  };

  // const handleLoginAdmin = () => {
  //   dispatch(loginRequest("admin", "111121", navigate));
  // };
  // const handleLoginCompanyAdmin = () => {
  //   dispatch(loginRequest("companyAdmin", "111121", navigate));
  // };
  // const handleLoginCompanyWorker = () => {
  //   dispatch(loginRequest("companyWorker", "111121", navigate));
  // };

  useEffect(() => {
    if (auth.auth) {
      navigate("/", { replace: true });
    }
  }, [auth, navigate]);

  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minWidth: "100vw",
          minHeight: "100vh",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        }}
      >
        <Container maxWidth="md">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                padding: "30px",
                borderRadius: "15px",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                style={{ padding: "5px", borderRadius: "10px" }}
              >
                <img
                  alt=""
                  src="/images/kemic_logo.png"
                  style={{ height: "40px", marginTop: "5px", marginRight: "20px" }}
                />
                <div style={{ fontSize: "30px", fontWeight: "bold" }}>{lang.타이틀}</div>
                {/* <Button variant="contained" onClick={handleLoginAdmin}>
              관리자 로그인
            </Button>
            <Button variant="contained" color="secondary" onClick={handleLoginCompanyAdmin}>
              업체관리자 로그인
            </Button>
            <Button variant="contained" color="error" onClick={handleLoginCompanyWorker}>
              업체담당자 로그인
            </Button> */}
              </Box>
              <Divider sx={{ my: 2 }} />
              <form onSubmit={onSubmit}>
                <TextField
                  fullWidth
                  label={lang.아이디}
                  margin="normal"
                  name="id"
                  onChange={handleChange}
                  value={values.id}
                  variant="outlined"
                  required
                />
                <TextField
                  fullWidth
                  label={lang.비밀번호}
                  margin="normal"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  required
                />
                <Box sx={{ py: 2 }}>
                  <Box textAlign="center" pb={1}>
                    {status === "error" && (
                      <Typography variant="body2" color="error">
                        {lang.로그인오류}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#b5404f", color: "white" }}
                  >
                    {lang.로그인}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Login;
