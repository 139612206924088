import { Box, Button, ListItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useMemo } from "react";

interface Props {
  to: string;
  title: string;
  icon?: React.ReactNode;
  open: boolean;
}

const NavLink = ({ open, to, title, icon }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const active = useMemo(() => {
    if (to === "/") {
      return pathname === to;
    }
    return pathname.includes(to);
  }, [pathname, to]);

  const handleClick = () => {
    navigate(to);
  };

  return (
    <ListItem
      sx={{
        display: "flex",
        mb: 1,
        py: 0,
        px: 2,
      }}
    >
      <Button
        startIcon={icon}
        component="a"
        disableRipple
        onClick={handleClick}
        sx={{
          minWidth: "auto",
          backgroundColor: active ? "rgba(255,255,255, 0.08)" : undefined,
          borderRadius: 1,
          color: active ? "secondary.main" : "neutral.300",
          fontWeight: active ? "fontWeightBold" : undefined,
          justifyContent: "start",
          px: open ? 3 : 1.4,
          textAlign: "left",
          whiteSpace: "nowrap",
          textTransform: "none",
          width: "100%",
          minHeight: 37,
          "& .MuiButton-startIcon": {
            color: active ? "secondary.main" : "neutral.400",
          },
          "&:hover": {
            backgroundColor: "rgba(255,255,255, 0.08)",
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{open ? title : null}</Box>
      </Button>
    </ListItem>
  );
};

NavLink.defaultProps = {
  icon: undefined,
};

export default NavLink;
