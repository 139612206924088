import {
  AddPath,
  FilpPath,
  PathAction,
  PathActionTypes,
  PathState,
  RemovePath,
  SetPaths,
  ClearPaths,
  SetOrigin,
  SetDestination,
  OptimizePathsSuccess,
  OptimizePathsRequest,
} from "../../@types/redux/path.interface";

const {
  ADD_PATH,
  REMOVE_PATH,
  FILP_PATH,
  SET_PATHS,
  CLEAR_PATHS,
  SET_ORIGIN,
  SET_DESTINATION,
  OPTIMIZE_PATHS_REQUEST,
  OPTIMIZE_PATHS_SUCCESS,
} = PathActionTypes;

export const getNewPath = () => ({
  id: Date.now(),
  title: undefined,
});

export const addPath = (): AddPath => ({
  type: ADD_PATH,
  payload: {},
});

export const removePath = (id: number): RemovePath => ({
  type: REMOVE_PATH,
  payload: { id },
});

export const filpPath = (): FilpPath => ({
  type: FILP_PATH,
  payload: {},
});

export const setPath = (paths: Path[]): SetPaths => ({
  type: SET_PATHS,
  payload: { paths },
});

export const clearPaths = (): ClearPaths => ({
  type: CLEAR_PATHS,
  payload: {},
});

export const setOrigin = (path: Path): SetOrigin => ({
  type: SET_ORIGIN,
  payload: { path },
});

export const setDestination = (path: Path): SetDestination => ({
  type: SET_DESTINATION,
  payload: { path },
});

export const optimizePathsRequest = (): OptimizePathsRequest => ({
  type: OPTIMIZE_PATHS_REQUEST,
  payload: {},
});

export const optimizePathsSuccess = (paths: Path[]): OptimizePathsSuccess => ({
  type: OPTIMIZE_PATHS_SUCCESS,
  payload: { paths },
});

export const initialState: PathState = {
  paths: [{ id: Date.now() }, { id: Date.now() + 1 }],
  status: "ok",
};

const PathReducer = (state = initialState, action: PathAction): PathState => {
  switch (action.type) {
    case ADD_PATH: {
      const newPaths = [...state.paths];
      newPaths.splice(newPaths.length - 1, 0, getNewPath());
      return { ...state, paths: newPaths };
    }
    case REMOVE_PATH: {
      const newPaths = state.paths.filter((path) => path.id !== action.payload.id);
      return { ...state, paths: newPaths };
    }
    case FILP_PATH: {
      const newPaths = state.paths.reverse();
      return { ...state, paths: newPaths };
    }
    case SET_PATHS:
      return { ...state, paths: action.payload.paths };
    case CLEAR_PATHS:
      return { ...state, paths: [{ id: Date.now() }, { id: Date.now() + 1 }] };
    case SET_ORIGIN: {
      const newPaths = [...state.paths];
      newPaths[0] = action.payload.path;
      return { ...state, paths: newPaths };
    }
    case SET_DESTINATION: {
      const newPaths = [...state.paths];
      const last = newPaths.length - 1;
      newPaths[last] = action.payload.path;
      return { ...state, paths: newPaths };
    }
    case OPTIMIZE_PATHS_REQUEST:
      return { ...state, status: "loading" };
    case OPTIMIZE_PATHS_SUCCESS:
      return { ...state, status: "ok", paths: action.payload.paths };
    default:
      return state;
  }
};

export default PathReducer;
