import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetCompanyListResponse extends ServerResponse {
  _embedded?: {
    liftMaintenanceCompanies: Company[];
  };
}

interface GetOpenDataCompanyList extends ServerResponse {
  _embedded?: {
    openDataLiftMaintenanceCompanyInfoes: OpenDataCompany[];
  };
}

export interface AddCompanyRequest {
  file?: File;
  fileId?: number;
  company: Company;
}

export interface UpdateCompanyRequest extends AddCompanyRequest {
  id: number | string;
}

export interface GetCompaniesRequest {
  page?: number | string;
  size?: number | string;
  key?: string;
  sort?: "asc" | "desc";
  companyUnionId?: number | string;
  koeisaServiceKey?: string;
  enabledYn?: string | boolean;
  searchKey?: string;
  searchValue?: string;
  exact?: boolean;
}

class CompanyService {
  // private static ADMIN = "api/admin";

  private static COMPANY = "liftMaintenanceCompanies";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") CompanyService.PATH = "api";
    else CompanyService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async searchCompanyOpenData(
    sido: string,
    name: string,
    auth: Auth,
  ): Promise<GetOpenDataCompanyList> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = CompanyService.pathSetter(auth);
          const response: AxiosResponse<GetOpenDataCompanyList> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}/opendataByName`,
            params: {
              sido,
              name,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getCompanies(
    {
      page,
      size,
      key,
      sort,
      companyUnionId,
      searchKey,
      searchValue,
      exact,
      koeisaServiceKey,
      enabledYn,
    }: GetCompaniesRequest,
    auth: Auth,
  ): Promise<GetCompanyListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = CompanyService.pathSetter(auth);
          const response: AxiosResponse<GetCompanyListResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}`,
            params: {
              page,
              size,
              koeisaServiceKey,
              enabledYn: enabledYn === "true" || enabledYn === "false" ? enabledYn : undefined,
              sort: key ? `${key},${sort}` : undefined,
              "companyUnion.id": companyUnionId,
              ...(searchKey &&
                searchValue && { [searchKey]: exact ? searchValue : `contains(${searchValue})` }),
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addCompany(
    { company, file, fileId }: AddCompanyRequest,
    auth: Auth,
  ): Promise<Lift> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = CompanyService.pathSetter(auth);
          const formData = new FormData();

          const newRequest = {
            ...company,
            ...(fileId && {
              bizrRegistFile: {
                id: fileId,
              },
            }),
          };
          formData.append(
            "dto",
            new Blob([JSON.stringify(newRequest)], { type: "application/json" }),
          );
          if (file) formData.append("file", file, file.name);
          const response: AxiosResponse<Lift> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}`,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            data: {
              formData,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async updateCompany(
    { id, company, file, fileId }: UpdateCompanyRequest,
    auth: Auth,
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      (async () => {
        // eslint-disable-next-line
        console.log(company);
        try {
          const token = CompanyService.pathSetter(auth);
          const formData = new FormData();
          const newRequest = {
            ...company,
            ...(fileId && {
              bizrRegistFile: {
                id: fileId,
              },
            }),
          };
          formData.append(
            "dto",
            new Blob([JSON.stringify(newRequest)], { type: "application/json" }),
          );
          if (file) formData.append("file", file, file.name);
          const response: AxiosResponse<Company> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}/${id}`,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            data: formData,
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getCompanyDetail(id: number | string, auth: Auth): Promise<Company> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = CompanyService.pathSetter(auth);
          const response: AxiosResponse<Company> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getCompanyCount(auth: Auth, enabledYn?: boolean): Promise<number> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = CompanyService.pathSetter(auth);
          const response: AxiosResponse<number> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}/count`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              companyType: "M",
              enabledYn,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static updateCompanyByOpenData(id: number | string, auth: Auth): Promise<Company> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = CompanyService.pathSetter(auth);
          const response: AxiosResponse<Company> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}/${id}:updateByOpenData`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static updateCompanyStatus(
    id: number | string,
    status: boolean,
    auth: Auth,
  ): Promise<Company> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = CompanyService.pathSetter(auth);
          const statusURL = status ? "activate" : "deactivate";
          const response: AxiosResponse<Company> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${CompanyService.PATH}/${CompanyService.COMPANY}/${id}:${statusURL}`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default CompanyService;
