/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Paper from "../../components/Paper";
import StateMaintenance from "../../components/State/StateMaintenance";
// import StateDiagnosisSensor from "../../components/State/StateDiagnosisSensor";
import TabPanel from "../../components/TabPanel";
import StateDiagnosisController from "../../components/State/StateDiagnosisController";

/* 상태관리 탭 */
const State = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const lang: any = t("State", { returnObjects: true });

  const handleChangeTab = (event: SyntheticEvent, index: number) => {
    setSearchParams({ tabIndex: index.toString() });
  };

  const tabIndex = searchParams.get("tabIndex") ? Number(searchParams.get("tabIndex")) : 0;

  return (
    <Grid item xs={12}>
      <Paper>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex} onChange={handleChangeTab}>
            {/* <Tab label={lang.센서_상태_관리} tabIndex={0} /> */}
            <Tab label={lang.제어반_상태_관리} tabIndex={0} />
            <Tab label={lang.유지보수일지} tabIndex={1} />
          </Tabs>
        </Box>
        {/* <TabPanel index={0} value={tabIndex}>
          <StateDiagnosisSensor />
        </TabPanel> */}
        <TabPanel index={0} value={tabIndex}>
          <StateDiagnosisController />
        </TabPanel>
        <TabPanel index={1} value={tabIndex}>
          <StateMaintenance />
        </TabPanel>
      </Paper>
    </Grid>
  );
};

export default State;
