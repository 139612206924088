import { ArrowBack, Edit } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoBox from "../../components/Info/InfoBox";
import InfoList from "../../components/Info/InfoList";
import InfoRow from "../../components/Info/InfoRow";
import Paper from "../../components/Paper";
import Table from "../../components/Table/Table";
import { RootState } from "../../redux/reducers";
import { setStatus } from "../../redux/reducers/config";
import UserService from "../../services/UserService";
import { parseDate } from "../../utils/parse";
import useEnum from "../../hooks/useEnum";

interface Responses {
  user?: User;
  tasks: MaintenanceTask[];
  sites: Site[];
}

const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);

  const enumName = useEnum(auth);

  const { t } = useTranslation();
  const lang: any = t("UserDetail", { returnObjects: true });
  const col: any = t("columns", { returnObjects: true });

  const columns: Column<Site>[] = [
    { heading: lang.지역, field: "address1" },
    { heading: lang.건물명, field: "buldNm" },
  ];

  const { id } = useParams();
  const [responses, setResponses] = useState<Responses>({ user: undefined, tasks: [], sites: [] });
  const handleClickEdit = () => navigate(`/user/regist/${id}`);
  const handleClickBack = () => navigate(`/user`);

  const maintenanceTaskColumns: Column<MaintenanceTask>[] = [
    {
      field: "type",
      heading: col.구분,
      setValue: (param: any) => enumName("MaintenanceType", param),
    },
    { field: "equipmentCtgr.parent.name", heading: col.분류 },
    { field: "equipmentCtgr.name", heading: col.품목 },
    { field: "workDtl", heading: col.내역, width: 200 },
    { field: "cost", heading: col.비용 },
    { field: "workYmd", heading: col.작업일 },
  ];

  useEffect(() => {
    if (!id || !auth) return;
    (async () => {
      try {
        dispatch(setStatus("loading"));
        const promiseArray: Promise<any>[] = [];
        promiseArray.push(UserService.getUser(id, auth));
        promiseArray.push(UserService.getRelatedSites(id, auth));
        promiseArray.push(UserService.getRelatedTasks(id, auth));
        const [user, sites, tasks] = await Promise.all(promiseArray);
        setResponses({
          user,
          sites: sites._embedded ? sites._embedded.sites : [],
          tasks: tasks._embedded ? tasks._embedded.liftMaintenanceTasks : [],
        });
        dispatch(setStatus("ok"));
      } catch (error) {
        dispatch(setStatus("error"));
      }
    })();
  }, [auth, id, dispatch]);

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>
      <Grid item md={12} lg={8}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.사용자_정보}
        </Typography>
        <InfoList>
          <InfoRow>
            <InfoBox title={lang.아이디} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{responses.user?.loginId}</Typography>
            </InfoBox>
            <InfoBox title={lang.자체점검ID} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{responses.user?.koeisaId}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.역할_소속} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">
                {responses.user?.company?.name || enumName("RoleType", responses.user?.role.name)}
              </Typography>
            </InfoBox>
            <InfoBox title={lang.이름} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{responses.user?.name}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.연락처} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{responses.user?.telNo}</Typography>
            </InfoBox>
            <InfoBox title={lang.이메일} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{responses.user?.email}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.등록일} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">{parseDate(responses.user?.createdAt)}</Typography>
            </InfoBox>
            <InfoBox title={lang.상태} flex={1} valueSx={{ flex: 2 }}>
              <Typography variant="body2">
                {responses.user?.enabledYn ? lang.활성 : lang.비활성}
              </Typography>
            </InfoBox>
          </InfoRow>
        </InfoList>
        <Box display="flex" justifyContent="end" py={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickEdit}
            startIcon={<Edit />}
          >
            {lang.수정}
          </Button>
        </Box>
        <Typography variant="subtitle2" gutterBottom>
          {lang.유지보수_내역}
        </Typography>
        <Paper>
          <Table columns={maintenanceTaskColumns} data={responses.tasks} hidePagination />
        </Paper>
      </Grid>
      <Grid item lg={4}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.담당_승강기}
        </Typography>
        <Paper>
          <Table columns={columns} data={responses.sites} hidePagination />
        </Paper>
      </Grid>
    </>
  );
};

export default UserDetail;
