import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  CSSObject,
  Divider,
  Drawer,
  IconButton,
  styled,
  Theme,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/reducers";
import { toggleSidebar } from "../redux/reducers/config";
import EnumService from "../services/EnumService";
import NavLinkList from "./NavLinkList";

interface Props {
  width: number;
}

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxSizing: "border-box",
  background: theme.palette.neutral[900],
  color: "#FFF",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  boxSizing: "border-box",
  background: theme.palette.neutral[900],
  color: "#FFF",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8.2)} + 1px)`,
  },
});

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })<{
  width: number;
  open: boolean;
}>(({ width, open, theme }) => ({
  width,
  flexShrink: 0,
  ...(open && {
    ...openedMixin(theme, width),
    "& .MuiDrawer-paper": openedMixin(theme, width),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.04)",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  padding: `12px ${theme.spacing(3)}`,
  borderRadius: theme.shape.borderRadius,
}));

const Sidebar = ({ width }: Props) => {
  const navigate = useNavigate();
  const { sidebarOpen } = useSelector((state: RootState) => state.config);
  const { auth } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const onClick = () => dispatch(toggleSidebar());
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (!auth.auth) return;
    (async () => {
      const roleTypes = await EnumService.getEnum("RoleType", auth);
      const newTitle = roleTypes.find((role) => role.code === auth.auth)?.title || "";
      setTitle(newTitle);
    })();
  }, [auth]);

  if (!auth.auth) return null;

  return (
    <StyledDrawer variant="permanent" open={sidebarOpen} width={width}>
      <Box textAlign="right" height={64} p={1}>
        <IconButton onClick={onClick}>
          {sidebarOpen ? <ChevronLeft fontSize="large" /> : <ChevronRight fontSize="large" />}
        </IconButton>
      </Box>
      <Box sx={{ px: 2, pt: 2 }}>
        <StyledBox
          sx={{ opacity: !sidebarOpen ? 0 : 1 }}
          onClick={() => navigate(`/profile/${auth.id}`)}
        >
          <div>
            <Typography color="inherit" variant="subtitle1" noWrap>
              {auth?.name}
            </Typography>
            <Typography color="neutral.400" variant="body2" noWrap>
              {title}
            </Typography>
          </div>
        </StyledBox>
      </Box>
      <Divider sx={{ borderColor: "#2D3748", my: 2 }} />
      <NavLinkList />
      <Divider sx={{ borderColor: "#2D3748", my: 2 }} />
    </StyledDrawer>
  );
};

export default Sidebar;
