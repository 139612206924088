import { NavigateFunction } from "react-router-dom";
import {
  AuthAction,
  AuthActionTypes,
  AuthState,
  LoginFailure,
  LoginRequest,
  LoginSuccess,
  Logout,
  SetUser,
} from "../../@types/redux/auth.interface";
import { Auth } from "../../services/AuthService";

const { SET_USER, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } = AuthActionTypes;

export const setUser = (user: User): SetUser => ({ payload: { user }, type: SET_USER });

export const loginRequest = (
  loginId: string,
  password: string,
  navigate: NavigateFunction,
): LoginRequest => ({
  payload: { loginId, password, navigate },
  type: LOGIN_REQUEST,
});

export const loginSuccess = (auth: Auth): LoginSuccess => ({
  payload: { auth },
  type: LOGIN_SUCCESS,
});

export const loginFailure = (): LoginFailure => ({ payload: {}, type: LOGIN_FAILURE });

export const logout = (): Logout => ({ payload: {}, type: LOGOUT });

const initialState: AuthState = {
  auth: {
    auth: "",
    id: 0,
  },
  status: "ok",
};

const AuthReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, status: "loading" };
    case LOGIN_SUCCESS:
      return { ...state, status: "ok", auth: action.payload.auth };
    case LOGOUT:
      return { ...state, auth: initialState.auth };
    case LOGIN_FAILURE:
      return { ...state, status: "error" };
    default:
      return state;
  }
};

export default AuthReducer;
