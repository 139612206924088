import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { RouteActionTypes, RoutesRequest } from "../../@types/redux/route.interface";
import RouteService, { GetRouteRequset } from "../../services/RouteService";
import { routesFailure, routesSuccess } from "../reducers/route";
import { enqueueSnackbar } from "../reducers/snackbar";

const { ROUTE_REQUEST } = RouteActionTypes;

function* routeRequset({ payload }: RoutesRequest) {
  try {
    const { places } = payload;
    const temp: Promise<Route>[] = [];

    for (let i = 0; i < places.length; i += 1) {
      const origin = places[i];
      const destination = places[i + 1];
      if (origin && destination) {
        const request: GetRouteRequset = {
          origin,
          destination,
        };
        temp.push(RouteService.getRoute(request));
      }
    }
    const results: Route[] = yield call(() => Promise.all(temp));
    yield put(routesSuccess(results));
  } catch (error: any) {
    yield put(routesFailure());
    yield put(
      enqueueSnackbar({
        message: "경로 탐색 중 오류가 발생했습니다. \n잠시 후에 다시 시도해주세요",
        options: { variant: "error" },
      }),
    );
  }
}

function* routeRequestWatcher() {
  yield takeLatest(ROUTE_REQUEST, routeRequset);
}

export default function* RouteSaga() {
  yield all([fork(routeRequestWatcher)]);
}
