import { useQuery } from "react-query";
import { Auth } from "../services/AuthService";
import EnumService from "../services/EnumService";

const useEnums = (auth: Auth) => {
  return useQuery<Enums>("getAllEnums", () => EnumService.getAllEnum(auth));
};

const useEnum = (auth: Auth) => {
  const { isLoading, data } = useEnums(auth);
  // const [enumLoaded, setEnumLoaded] = useState(false);
  const EnumName = (enumType: keyof Enums, enumCode: string | undefined) => {
    if (!auth.auth) return "";
    if (!isLoading && enumCode !== undefined) {
      // setEnumLoaded(true);
      const matchingItem = data?.[enumType]?.find((r) => r.code === enumCode);
      return matchingItem?.title || "";
    }
    // setEnumLoaded(false);
    return "";
  };

  return EnumName;
};

export default useEnum;
