export enum DashboardgActionTypes {
  GET_DASHBOARD_CONTENTS_REQUEST = "GET_DASHBOARD_CONTENTS_REQUEST",
  GET_DASHBOARD_CONTENTS_SUCCESS = "GET_DASHBOARD_CONTENTS_SUCCESS",
  CLEAR_DASHBOARD_CONTENTS = "CLEAR_DASHBOARD_CONTENTS",
  SET_DASHBAROD_LABEL = "SET_DASHBAROD_LABEL",
  SET_DASHBOARD_BUILDINGS = "SET_DASHBAROD_BUILDINGS",
  SET_BULIDING_INFO = "SET_BULIDING_INFO",
}

export type DashboardAction =
  | GetDashboardContentsRequest
  | GetDashboardContentsSuccess
  | ClearDashboardContents
  | SetDashboardLabel
  | SetDashboardBuildings
  | SetBuildingInfo;

export interface DashboardState {
  status: Status;
  buildings: Building[];
  diagnosis: DateRangeType;
  inspection: DateRangeType;
  selfCheck: DateRangeType;
  info?: Building;
}

export interface GetDashboardContentsRequest {
  payload: {};
  type: DashboardgActionTypes.GET_DASHBOARD_CONTENTS_REQUEST;
}

export interface GetDashboardContentsSuccess {
  payload: { buildings: Building[] };
  type: DashboardgActionTypes.GET_DASHBOARD_CONTENTS_SUCCESS;
}

export interface ClearDashboardContents {
  payload: {};
  type: DashboardgActionTypes.CLEAR_DASHBOARD_CONTENTS;
}

export interface SetDashboardLabel {
  payload: { type: DashboardLabelType; value: DateRangeType };
  type: DashboardgActionTypes.SET_DASHBAROD_LABEL;
}

export interface SetDashboardBuildings {
  payload: { buildings: Building[]; type: DashboardLabelType };
  type: DashboardgActionTypes.SET_DASHBOARD_BUILDINGS;
}

export interface SetBuildingInfo {
  payload: { building?: Building };
  type: DashboardgActionTypes.SET_BULIDING_INFO;
}
