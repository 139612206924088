import React from "react";
import { Remove, Save } from "@mui/icons-material";
import { Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfoList from "../Info/InfoList";
import InfoBox from "../Info/InfoBox";
import InfoRow from "../Info/InfoRow";
import { RootState } from "../../redux/reducers";
import LiftService from "../../services/LiftService";

interface Props {
  lift?: Lift;
  showButtons?: boolean;
  onAfterSubmit?: () => {};
}

const LiftMaintainenceCompanyInfo = ({ lift, showButtons = false, onAfterSubmit }: Props) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentLift", { returnObjects: true });

  const company = lift?.liftMntContracts?.[0];
  const handleClickAdd = () => {
    navigate(`/lift/regist`, { state: { equipmentNo: lift?.equipmentNo } });
  };
  const handleClickRemove = async () => {
    if (!auth || !lift) return;
    await LiftService.unlinkMaintainCompany(auth, lift.id);
    onAfterSubmit?.();
  };

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {lang.LiftMaintainenceCompanyInfo.관리_업체_정보}
      </Typography>
      <InfoList>
        <InfoRow>
          <InfoBox
            title={lang.LiftMaintainenceCompanyInfo.관리_업체명}
            flex={1}
            valueSx={{ flex: 2 }}
          >
            <Typography variant="body2">{company?.liftMntCpny.name || "-"}</Typography>
          </InfoBox>
          <InfoBox
            title={lang.LiftMaintainenceCompanyInfo.관리_업체_연락처}
            flex={1}
            valueSx={{ flex: 2 }}
          >
            <Typography variant="body2">{company?.liftMntCpny.telNo || "-"}</Typography>
          </InfoBox>
        </InfoRow>
      </InfoList>
      {showButtons && auth?.auth === "A" && (
        <Box pt={2} textAlign="right">
          <Button
            size="small"
            variant="contained"
            disabled={Boolean(company)}
            sx={{ mr: 1 }}
            startIcon={<Save />}
            onClick={handleClickAdd}
          >
            {lang.LiftMaintainenceCompanyInfo.등록}
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={Boolean(!company)}
            color="error"
            startIcon={<Remove />}
            onClick={handleClickRemove}
          >
            {lang.LiftMaintainenceCompanyInfo.삭제}
          </Button>
        </Box>
      )}
    </>
  );
};

LiftMaintainenceCompanyInfo.defaultProps = {
  lift: undefined,
  showButtons: false,
  onAfterSubmit: undefined,
};

export default LiftMaintainenceCompanyInfo;
