import { Place } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { setDestination, setOrigin, setPath } from "../../redux/reducers/path";
import GeoService from "../../services/GeoService";

interface Props {
  menu: { x: number; y: number; coords: Coords } | null;
  onClose: () => void;
}

const MapMenu = ({ menu, onClose }: Props) => {
  const dispatch = useDispatch();
  const { paths } = useSelector((state: RootState) => state.path);

  const getPath = async () => {
    const place = await GeoService.pointToAddress(menu!.coords.lng, menu!.coords.lat);
    return { id: Date.now(), title: place.name, place };
  };

  const handleClickOrigin = async () => {
    const path = await getPath();
    dispatch(setOrigin(path));
    onClose();
  };

  const handleClickDestination = async () => {
    const path = await getPath();
    dispatch(setDestination(path));
    onClose();
  };

  const handleClickWayPoint = async () => {
    const path = await getPath();
    const newPaths = [...paths];

    let targetIndex: number | undefined;
    for (let i = 1; i < newPaths.length - 1; i += 1) {
      if (!newPaths[i].place) {
        targetIndex = i;
        break;
      }
    }

    if (targetIndex === undefined) {
      newPaths.splice(paths.length - 1, 0, path);
    } else {
      newPaths[targetIndex] = path;
    }

    dispatch(setPath(newPaths));
    onClose();
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <Menu
      open={Boolean(menu)}
      anchorReference="anchorPosition"
      anchorPosition={menu ? { top: menu.y, left: menu.x } : undefined}
      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={onClose}
      autoFocus={false}
      componentsProps={{ backdrop: { onContextMenu: handleContextMenu } }}
    >
      <MenuItem onClick={handleClickOrigin}>
        <ListItemIcon>
          <Place fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText primary="출발지 지정" primaryTypographyProps={{ variant: "body2" }} />
      </MenuItem>
      <MenuItem onClick={handleClickWayPoint}>
        <ListItemIcon>
          <Place fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="경유지 추가" primaryTypographyProps={{ variant: "body2" }} />
      </MenuItem>
      <MenuItem onClick={handleClickDestination}>
        <ListItemIcon>
          <Place fontSize="small" color="secondary" />
        </ListItemIcon>
        <ListItemText primary="도착지 지정" primaryTypographyProps={{ variant: "body2" }} />
      </MenuItem>
    </Menu>
  );
};

export default MapMenu;
