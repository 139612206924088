import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../../../Info/InfoBox";
import InfoList from "../../../Info/InfoList";
import InfoRow from "../../../Info/InfoRow";
import Paper from "../../../Paper";

const StyledHead = styled(Grid)(({ theme }) => ({
  background: theme.palette.grey[100],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledCell = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

interface Props {
  esChains: EsChain[];
}

const DiagnosisDetailEsChains = ({ esChains }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  return (
    <>
      {esChains.map((chain) => (
        <Box key={chain.id} pt={2}>
          <InfoList>
            <InfoRow>
              <InfoBox title={lang.DiagnosisDetailEsChains.센서ID} flex={1}>
                <Typography variant="body2">-</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailEsChains.구분} flex={1}>
                <Typography variant="body2">lang.DiagnosisDetailEsChains.체인</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailEsChains.에러코드} flex={1}>
                <Typography variant="body2">{chain.errCode}</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailEsChains.진단_요약} flex={1}>
                <Typography variant="body2">{chain.dcode?.diagnosisMsg}</Typography>
              </InfoBox>
            </InfoRow>
          </InfoList>
          <Paper sx={{ mt: 2 }}>
            <Grid container>
              <StyledRow container item md={12}>
                <StyledHead item md={2} />
                <StyledHead item md={3}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailEsChains.RS체인}</Typography>
                </StyledHead>
                <StyledHead item md={3.5}>
                  <Typography variant="subtitle2">
                    {lang.DiagnosisDetailEsChains.왼쪽_스텝_체인}
                  </Typography>
                </StyledHead>
                <StyledHead item md={3.5}>
                  <Typography variant="subtitle2">
                    {lang.DiagnosisDetailEsChains.오른쪽_스텝_체인}
                  </Typography>
                </StyledHead>
              </StyledRow>
              <StyledRow container item md={12}>
                <StyledHead item md={2}>
                  <Typography variant="subtitle2">
                    {lang.DiagnosisDetailEsChains.전체_길이}
                  </Typography>
                </StyledHead>
                <StyledCell item md={3}>
                  <Typography variant="subtitle2">{chain.rsChainLength}</Typography>
                </StyledCell>
                <StyledCell item md={3.5}>
                  <Typography variant="subtitle2">{chain.lchainLength}</Typography>
                </StyledCell>
                <StyledCell item md={3.5}>
                  <Typography variant="subtitle2">{chain.rchainLength}</Typography>
                </StyledCell>
              </StyledRow>
              <StyledRow container item md={12}>
                <StyledHead item md={2}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailEsChains.신율}</Typography>
                </StyledHead>
                <StyledCell item md={3}>
                  <Typography variant="subtitle2">{chain.rsChainElong}</Typography>
                </StyledCell>
                <StyledCell item md={3.5}>
                  <Typography variant="subtitle2">{chain.lchainElong}</Typography>
                </StyledCell>
                <StyledCell item md={3.5}>
                  <Typography variant="subtitle2">{chain.rchainElong}</Typography>
                </StyledCell>
              </StyledRow>
              <StyledRow container item md={12}>
                <StyledHead item md={2}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailEsChains.틀어짐}</Typography>
                </StyledHead>
                <StyledCell item md={3}>
                  <Typography variant="subtitle2">-</Typography>
                </StyledCell>
                <StyledCell item md={7}>
                  <Typography variant="subtitle2">{chain.twistedYn ? "Y" : "N"}</Typography>
                </StyledCell>
              </StyledRow>
            </Grid>
          </Paper>
        </Box>
      ))}
    </>
  );
};

export default DiagnosisDetailEsChains;
