export enum PathActionTypes {
  ADD_PATH = "ADD_PATH",
  REMOVE_PATH = "REMOVE_PATH",
  FILP_PATH = "FILP_PATH",
  SET_PATHS = "SET_PATHS",
  CLEAR_PATHS = "CLEAR_PATHS",
  SET_ORIGIN = "SET_ORIGIN",
  SET_DESTINATION = "SET_DESTINATION",
  OPTIMIZE_PATHS_REQUEST = "OPTIMIZE_PATHS_REQUEST",
  OPTIMIZE_PATHS_SUCCESS = "OPTIMIZE_PATHS_SUCCESS",
}

export type PathAction =
  | AddPath
  | RemovePath
  | FilpPath
  | SetPaths
  | ClearPaths
  | SetOrigin
  | SetDestination
  | OptimizePathsRequest
  | OptimizePathsSuccess;

export interface PathState {
  paths: Path[];
  status: "ok" | "loading" | "error";
}

export interface AddPath {
  type: PathActionTypes.ADD_PATH;
  payload: {};
}

export interface RemovePath {
  type: PathActionTypes.REMOVE_PATH;
  payload: { id: number };
}

export interface FilpPath {
  type: PathActionTypes.FILP_PATH;
  payload: {};
}

export interface SetPaths {
  type: PathActionTypes.SET_PATHS;
  payload: { paths: Path[] };
}

export interface ClearPaths {
  type: PathActionTypes.CLEAR_PATHS;
  payload: {};
}

export interface SetOrigin {
  type: PathActionTypes.SET_ORIGIN;
  payload: { path: Path };
}

export interface SetDestination {
  type: PathActionTypes.SET_DESTINATION;
  payload: { path: Path };
}

export interface OptimizePathsRequest {
  type: PathActionTypes.OPTIMIZE_PATHS_REQUEST;
  payload: {};
}

export interface OptimizePathsSuccess {
  type: PathActionTypes.OPTIMIZE_PATHS_SUCCESS;
  payload: { paths: Path[] };
}
