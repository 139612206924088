import { Box, BoxProps, styled } from "@mui/material";
import React from "react";

interface Props extends BoxProps {
  children: React.ReactNode;
}

const StyldBox = styled(Box)(({ theme }) => ({
  "& > *": {
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 0,
    overflow: "hidden",
  },
  "& > *:first-of-type": {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  "& > *:last-child": {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
}));

const InfoList = ({ children, ...restProps }: Props) => {
  return <StyldBox {...restProps}>{children}</StyldBox>;
};

export default InfoList;
