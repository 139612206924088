import axios, { AxiosResponse } from "axios";
import { Buffer } from "buffer";
import { API_SERVER_URL } from "../utils/const";

interface GetTokenRespone {
  id_token: string;
}

export interface TokenData {
  /** 사용자 권한 A(관리자), CA(업체관리자), CW(업체작업자) */
  auth: "A" | "CA" | "CW" | string;
  exp?: number;
  id: number;
  sub?: string;
  name?: string;
  userId?: string;
}

export interface Auth extends TokenData {
  id_token?: string;
}

export default class AuthService {
  private static LOGIN = "api/public/login/authenticate";

  private static jwtParser(token: string) {
    const base64 = Buffer.from(token.split(".")[1], "base64");
    const data = JSON.parse(base64.toString());
    return data;
  }

  public static async getToken(loginId: string, password: string): Promise<TokenData> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response: AxiosResponse<GetTokenRespone> = await axios({
            method: "post",
            url: `${API_SERVER_URL}/${AuthService.LOGIN}`,
            data: {
              loginId,
              password,
            },
          });
          resolve({
            id_token: response.data.id_token,
            ...AuthService.jwtParser(response.data.id_token),
          });
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
