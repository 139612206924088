import React, { useCallback, useMemo } from "react";
import { AppBar, Button, IconButton, styled, Toolbar, Typography } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";
import { useLocation, useNavigate, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../redux/reducers";
import { logout } from "../redux/reducers/auth";
import { toggleMapDialog } from "../redux/reducers/config";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Header = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const common: any = t("common", { returnObjects: true });
  const menu: any = t("menu", { returnObjects: true });

  const titles = useMemo(
    () => [
      { pattern: "/", title: menu.대시보드 },
      { pattern: "/state", title: menu.상태_관리 },
      { pattern: "/state/:id", title: `${menu.상태_관리} - ${common.상세}` },
      { pattern: "/state/maintenance/:id", title: `${menu.상태_관리} - ${common.유지보수}` },
      { pattern: "/inspection", title: menu.점검_관리 },
      { pattern: "/inspection/:id", title: `${menu.점검_관리} - ${common.상세}` },
      { pattern: "/inspection/regist/:id", title: `${menu.점검_관리} - ${common.등록}` },
      { pattern: "/lift", title: menu.승강기_관리 },
      { pattern: "/lift/detail/:id", title: `${menu.승강기_관리} - ${common.상세}` },
      { pattern: "/lift/regist", title: `${menu.승강기_관리} - ${common.등록}` },
      { pattern: "/company", title: menu.업체_관리 },
      { pattern: "/company/detail/:id", title: `${menu.업체_관리} - ${common.상세}` },
      { pattern: "/company/regist/", title: `${menu.업체_관리} - ${common.등록}` },
      { pattern: "/company/regist/:id", title: `${menu.업체_관리} - ${common.수정}` },
      { pattern: "/site", title: menu.건물_관리 },
      { pattern: "/site/:id", title: `${menu.건물_관리} - ${common.상세}` },
      { pattern: "/user", title: menu.사용자_관리 },
      { pattern: "/user/detail/:id", title: `${menu.사용자_관리} - ${common.상세}` },
      { pattern: "/user/regist", title: `${menu.사용자_관리} - ${common.등록}` },
      { pattern: "/user/regist/:id", title: `${menu.사용자_관리} - ${common.수정}` },
      { pattern: "/gateway", title: menu.게이트웨이_관리 },
      { pattern: "/gateway/detail/:id", title: `${menu.게이트웨이_관리} - ${common.상세}` },
      { pattern: "/gateway/regist", title: `${menu.게이트웨이_관리} - ${common.등록}` },
      { pattern: "/gateway/regist/:id", title: `${menu.게이트웨이_관리} - ${common.수정}` },
      { pattern: "/sensor", title: menu.센서_관리 },
      { pattern: "/sensor/detail/:id", title: `${menu.센서_관리} - ${common.상세}` },
      { pattern: "/sensor/regist", title: `${menu.센서_관리} - ${common.등록}` },
      { pattern: "/sensor/regist/:id", title: `${menu.센서_관리} - ${common.수정}` },
      { pattern: "/setting", title: menu.설정 },
      { pattern: "/profile/:id", title: menu.프로필 },
      { pattern: "/maintenance", title: menu.유지보수목록 },
    ],
    [common, menu],
  );

  const handleLogout = () => dispatch(logout());
  const handleClickProfile = () => auth && navigate(`/profile/${auth.id}`);

  const title = useMemo(() => {
    let text = "";
    for (let i = 0; i < titles.length; i += 1) {
      if (matchPath(titles[i].pattern, location.pathname)) {
        text = titles[i].title;
        break;
      }
    }
    return text;
  }, [location, titles]);
  // eslint-disable-next-line
  const onToggleDialog = useCallback(() => dispatch(toggleMapDialog()), [dispatch]);
  return (
    <StyledAppBar color="inherit" position="relative">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {/* {title === "대시보드" && (
          <Button onClick={onToggleDialog} startIcon={<Map />}>
            지도보기
          </Button>
        )} */}
        <IconButton color="primary" sx={{ mr: 1 }} onClick={handleClickProfile}>
          <AccountCircle />
        </IconButton>
        <Button onClick={handleLogout} size="small" variant="outlined" startIcon={<Logout />}>
          {common.로그아웃}
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
