import axios, { AxiosResponse } from "axios";
import QueryString from "qs";
import { API_SERVER_URL, REPORT_URL } from "../utils/const";
import { Auth } from "./AuthService";

export interface GetLiftCountListResponse {
  /** 일주일 안에 수행할 자체점검(오늘 자체점검 포함) */
  day7SelChekCount: number;

  /** 일주일 안에 정기점검 계획일(오늘 포함) */
  day7FdrmChkYmdCount: number;

  /** 30일 안에 검사 신청해야하는 대상(작년 기준) */
  day30FdrmChkYmdReqCount: number;

  /** 45일 안에 검사 신청해야하는 대상 */
  day45FdrmChkYmdReqCount: number;

  /** 경과된 자체점검(한달이상 경과된 대상) */
  delayedSelChekCount: number;

  /** 오늘 정기점검 계획 */
  todayFdrmChkYmdCount: number;

  /** 오늘 자체점검 */
  todaySelChekCount: number;

  /** 전체 건수 */
  totalCount: number;

  // result_lift_cntlr 테이블 기준 마지막 status가 11인 것
  "cntlrStatus-11": number;

  // result_lift_cntlr 테이블 기준 total
  "cntlrStatus--total": number;

  // diagnosis_excution 테이블 기준 마지막 status가 11인 것
  "diagnosisStatus-11": number;

  // diagnosis_excution 테이블 기준 total
  "diagnosisStatus--total": number;
}

export interface GetSiteListResponse extends ServerResponse {
  sites: MapSite[];
}

export interface DashboardContentResponse extends ServerResponse {
  sites: MapSite[];
  dateInfo?: DateInfo;
}

export interface GetOwnerDahsboardResponse extends ServerResponse {
  lifts: LiftStatus[];
  string?: string;
}

// export interface GetEquipmentPartGroupByStatusResponse extends ServerResponse {
//   data: (string | number)[];
// }

export interface GetLatestDiagnosisesResponse extends PageData {
  liftDiagnosisExecutions: DiagnosisExecutionResult[];
}

// export interface GetOwnerDashboardRequest {
//   page?: number | string;
//   size?: number | string;
// }

export interface GetDeviceCountResponse {
  gateway: number;
  "sensor-N": number;
  "sensor-V": number;
}

export interface DateInfo {
  first: boolean;
  second: boolean;
  third: boolean;
  targetDay: string;
  nextDay: string;
  next7Day: string;
  prev7Day: string;
  prev1Month: string;
  prev1Month7Day: string;
  prev1YearDay: string;
  prev1YearNext30Day: string;
  prev1YearNext45Day: string;
}

export default class DashboardService {
  private static DASHBOARD = "dashboards";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") DashboardService.PATH = "api";
    else DashboardService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getSiteCount(auth: Auth): Promise<number> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<number> = await axios({
            method: "GET",
            url: `${REPORT_URL}/${DashboardService.PATH}/${DashboardService.DASHBOARD}/siteCount`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getLiftCountList(auth: Auth): Promise<GetLiftCountListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          // `${API_SERVER_URL}/${DashboardService.PATH}/${DashboardService.DASHBOARD}/liftCount`
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<GetLiftCountListResponse> = await axios({
            method: "GET",
            url: `${REPORT_URL}/${DashboardService.PATH}/${DashboardService.DASHBOARD}/liftCount`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getDiagnosises(
    status: DiagnosisStatus[],
    auth: Auth,
  ): Promise<GetLatestDiagnosisesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<GetLatestDiagnosisesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${DashboardService.PATH}/lifts/:latestDiagnosisExecution`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              size: 200,
              status,
            },
            paramsSerializer: (params) => {
              return QueryString.stringify(params, { arrayFormat: "brackets" })
                .replaceAll(/%5B/g, "")
                .replaceAll(/%5D/g, "")
                .replace(/%28/g, "(")
                .replace(/%29/g, ")")
                .replace(/%2C/g, ",");
            },
          });
          // console.log("latestDiagnosisExcution");
          // console.log(response.data);
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getSelfChecks(
    type: DateRangeType,
    auth: Auth,
  ): Promise<DashboardContentResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<DashboardContentResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${DashboardService.PATH}/${DashboardService.DASHBOARD}/sites/:selChkYmd`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              [type]: true,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getRegularChecks(
    type: DateRangeType,
    auth: Auth,
  ): Promise<DashboardContentResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<DashboardContentResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${DashboardService.PATH}/${DashboardService.DASHBOARD}/sites/:fdrmChkApplyYmd`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              [type]: true,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getInpections(
    type: DateRangeType,
    auth: Auth,
  ): Promise<DashboardContentResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<DashboardContentResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${DashboardService.PATH}/${DashboardService.DASHBOARD}/sites/:fdrmChkYmd`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              [type]: true,
            },
          });
          // console.log(response.data);
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getDevices(auth: Auth): Promise<DashboardContentResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<DashboardContentResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${DashboardService.PATH}/${DashboardService.DASHBOARD}/iotCount`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getOwnerDashboard(
    page: number,
    auth: Auth,
  ): Promise<GetOwnerDahsboardResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<GetOwnerDahsboardResponse> = await axios({
            method: "GET",
            url: `${REPORT_URL}/${DashboardService.PATH}/dashboards/liftStatuses`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size: 40,
              sort: "lift.equipment.equipmentNo,asc",
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getEquipmentPartGroupByStatus(auth: Auth): Promise<any> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/api/dashboards/countEquipmentPartGroupByStatus`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getEquipmentPartGroupByLiftStatus(auth: Auth, liftId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/api/dashboards/countEquipmentPartGroupByStatus?liftId=${liftId}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getOpModeCountLiftStatusGroup(auth: Auth): Promise<any> {
    // lift_status테이블의 opMode기준으로 opMode에 PO(power out)가 포함되거나 stopStatus(/util/parse.ts의 stopStatus)
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = DashboardService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "GET",
            url: `${REPORT_URL}/api/dashboards/countLifStatusGroupByOpMode`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
