import { Box } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const InfoRow = ({ children }: Props) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {children}
    </Box>
  );
};

export default InfoRow;
