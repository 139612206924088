import { styled, Box, BoxProps } from "@mui/material";
import React from "react";

const StyledDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  overflow: "hidden",
}));

interface Props extends BoxProps {
  children?: React.ReactNode;
}
const Paper = ({ children, ...restProps }: Props) => {
  return <StyledDiv {...restProps}>{children}</StyledDiv>;
};

Paper.defaultProps = {
  children: undefined,
};

export default Paper;
