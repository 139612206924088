import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL, REPORT_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetAllSitesResponse extends ServerResponse {
  _embedded?: {
    sites: Site[];
  };
}

interface GetAllLiftsBySiteResponse extends ServerResponse {
  _embedded?: {
    lifts: Lift[];
  };
}

interface GetAllOwnerResponse extends ServerResponse {
  _embedded?: {
    users: User[];
  };
}

interface GetAllSitesRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: SortDirectionType;
  sido?: string;
  searchKey?: string;
  searchValue?: string | number;
  gatewayYn?: string;
  sensorType?: string;
  exact?: boolean;
}

interface GetAllLiftsBySiteRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: SortDirectionType;
  searchKey?: string;
  searchValue?: string | number;
  siteId?: number | string;
  exact?: boolean;
}

interface GetAllOwnerRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: SortDirectionType;
  searchKey?: string;
  searchValue?: string | number;
  siteId?: number | string;
  exact?: boolean;
}

class SiteService {
  private static SITE = "sites";

  private static LIFT = "lifts";

  private static USER = "users";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") SiteService.PATH = "api";
    else SiteService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getAllSites(
    { page, size, sortKey, sortDirection, sido, searchKey, searchValue, exact }: GetAllSitesRequest,
    auth: Auth,
  ): Promise<GetAllSitesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SiteService.pathSetter(auth);
          const response: AxiosResponse<GetAllSitesResponse> = await axios({
            method: "GET",
            url: `${REPORT_URL}/${SiteService.PATH}/${SiteService.SITE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              sido,
              sort: sortKey ? `${sortKey},${sortDirection}` : undefined,
              ...(searchKey &&
                searchValue && {
                  [searchKey]: exact ? searchValue : `contains(${searchValue})`,
                }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getAllLiftBySite(
    { page, size, sortKey, sortDirection, siteId }: GetAllLiftsBySiteRequest,
    auth: Auth,
  ): Promise<GetAllLiftsBySiteResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SiteService.pathSetter(auth);
          const response: AxiosResponse<GetAllLiftsBySiteResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SiteService.PATH}/${SiteService.LIFT}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              sort: sortKey ? `${sortKey},${sortDirection}` : undefined,
              "site.id": siteId,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getAllOwner(
    { page, size, sortKey, sortDirection, searchKey, searchValue, exact }: GetAllOwnerRequest,
    auth: Auth,
  ): Promise<GetAllOwnerResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          let sort: string | undefined;
          if (sortKey && sortDirection) {
            sort = `${sortKey},${sortDirection}`;
          }
          const response: AxiosResponse<GetAllOwnerResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SiteService.PATH}/${SiteService.USER}`,
            headers: {
              Authorization: `Bearer ${auth.id_token}`,
            },
            params: {
              page,
              size,
              sort,
              "role.id": 2,
              ...(searchKey &&
                searchValue && { [searchKey]: exact ? searchValue : `contains(${searchValue})` }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addSite(site: Site, auth: Auth): Promise<Site> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SiteService.pathSetter(auth);
          const response: AxiosResponse<Site> = await axios({
            method: "post",
            url: `${API_SERVER_URL}/${SiteService.PATH}/${SiteService.SITE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: site,
          });
          resolve(response.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  public static async addOwner(siteId: number, ownerId: number, auth: Auth): Promise<Site> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SiteService.pathSetter(auth);
          const response: AxiosResponse<Site> = await axios({
            method: "patch",
            url: `${API_SERVER_URL}/${SiteService.PATH}/${SiteService.SITE}/${siteId}`,
            headers: {
              "Content-Type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: siteId,
              owner: {
                id: ownerId,
              },
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getSite(id: number | string, auth: Auth): Promise<Site> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SiteService.pathSetter(auth);
          const response: AxiosResponse<Site> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SiteService.PATH}/${SiteService.SITE}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async deleteOwner(siteId: number, auth: Auth): Promise<Site> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SiteService.pathSetter(auth);
          const response: AxiosResponse<Site> = await axios({
            method: "patch",
            url: `${API_SERVER_URL}/${SiteService.PATH}/${SiteService.SITE}/${siteId}`,
            headers: {
              "Content-Type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: siteId,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default SiteService;
