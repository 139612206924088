/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { Point } from "ol/geom";
// import BaseEvent from "ol/events/Event";
import { RootState } from "../../redux/reducers";
// import { PathState } from "../../@types/redux/path.interface";

declare global {
  interface Window {
    kakao: any;
  }
}

// interface Props {
//   location: {
//     lat: number;
//     lng: number;
//   };
//   paths: PathState;
// }

// const DST = "EPSG:900913";
// const SRC = "EPSG:4326";

const { kakao } = window;

const KakaoMap = (/* { paths }: Props */) => {
  const containerRef = useRef(null);
  // const dispatch = useDispatch();
  // const { map } = useSelector((state: RootState) => state.map);
  const { auth } = useSelector((state: RootState) => state.auth);
  const { paths } = useSelector((state: RootState) => state.path);
  const { mapDialog } = useSelector((state: RootState) => state.config);
  const { buildings } = useSelector((state: RootState) => state.dashboard);
  const [map, setMap] = useState<any>(null);
  // const [buildingMarker, setBuildingMarker] = useState<any>([]);
  const [buildingLocation, setBuildingLocation] = useState<any>([]);
  // const [pathMarker, setPathMarker] = useState<any>(null);
  const pathMarker = useRef<any | null>(null);

  const infoWindow = (buldNm: string) => {
    return `<div style="width:150px;padding:5px;text-align:center;font-size:8px;">${buldNm}</div>`;
  };

  const initializeMap = () => {
    const container = containerRef.current;
    const options = {
      center: new kakao.maps.LatLng(33.450701, 126.570667), // 초기 중심 좌표 설정
      level: 8, // 초기 줌 레벨 설정
    };

    const newMap = new kakao.maps.Map(container, options);
    const mapControl = new kakao.maps.MapTypeControl();
    newMap.addControl(mapControl, kakao.maps.ControlPosition.TOPRIGHT);
    const zoomControl = new kakao.maps.ZoomControl();
    newMap.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
    setMap(newMap);
  };

  // 지도 처음 띄웠을 때, building 바뀌었을 때
  useEffect(() => {
    if (!containerRef.current || !auth) return;
    if (!map) initializeMap();
    // 첫 번째 건물을 중심으로 지도 표시
    if (map && buildings.length > 0) {
      const firstBuilding = buildings[0];
      const location = new kakao.maps.LatLng(firstBuilding.site.lat, firstBuilding.site.lng);
      const buildingImg = new kakao.maps.MarkerImage(
        "/images/graymarker.png",
        new kakao.maps.Size(29, 42),
        { offset: new kakao.maps.Point(14.5, 42) },
      );
      const marker = new kakao.maps.Marker({ position: location, image: buildingImg });
      map.setCenter(location); // 맵 중심을 첫 번째 건물로 설정
      marker.setMap(map);
      const infow = new kakao.maps.InfoWindow({
        position: location,
        content: infoWindow(firstBuilding.site?.buldNm),
      });
      infow.open(map, marker);
      setBuildingLocation([location]);

      // 나머지 건물을 표시
      buildings.slice(1).forEach((building) => {
        const loca = new kakao.maps.LatLng(building.site.lat, building.site.lng);
        const mark = new kakao.maps.Marker({
          position: loca,
          image: buildingImg,
        });
        mark.setMap(map);
        const info = new kakao.maps.InfoWindow({
          position: loca,
          content: infoWindow(building.site?.buldNm),
        });
        info.open(map, mark);
        setBuildingLocation((prev: any) => [...prev, loca]);
      });
      map.setCenter(location);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildings, map]);

  // 승강기/건물 지도 표시
  useEffect(() => {
    if (pathMarker.current) pathMarker.current.setMap(null);
    paths.map((path) => {
      if (path.place) {
        const location = new kakao.maps.LatLng(path.place.location.lat, path.place.location.lng);
        const marker = new kakao.maps.Marker({ position: location });
        marker.setMap(map);
        pathMarker.current = marker;
        map.setLevel(3);
        map.setCenter(location);
      }
      return "";
    });
  }, [paths, map]);

  // center 변경
  useEffect(() => {
    if (!mapDialog && pathMarker.current) {
      pathMarker.current.setMap(null);
      pathMarker.current = null;
    } else if (mapDialog && !pathMarker.current && map) {
      map.setCenter(buildingLocation[0]);
      map.setLevel(8);
    }
  }, [mapDialog, buildingLocation, map]);

  return <div id="map" ref={containerRef} style={{ height: "100%", width: "100%" }} />;
};

export default KakaoMap;
