import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetCategoriesResponse extends ServerResponse {
  _embedded?: {
    equipmentCategories: EquipmentCategory[];
  };
}

interface GetDiagnosisCodes extends ServerResponse {
  _embedded?: {
    diagnosisCodes: DiagnosisCode[];
  };
}

export interface AddDiagnosisCodeRequest {
  id: string;
  liftType: string;
  diagnosisMsg: string;
  imagePosition: string;
  equipmentCtgr: { id: EquipmentCategory["id"] };
}

export interface EditPartRequest {
  id: number;
  name: string;
  depth: number;
}

let liftTypesResponse: GetCategoriesResponse;

export default class SettingService {
  private static CATEGORIES = "equipmentCategories";

  private static DIAGNOSIS_CODE = "diagnosisCodes";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") SettingService.PATH = "api";
    else SettingService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getLiftTypes(auth: Auth): Promise<GetCategoriesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);

          if (liftTypesResponse) {
            resolve(liftTypesResponse);
            return;
          }
          const response: AxiosResponse<GetCategoriesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "parent.code": "LIFT",
              depth: 2,
            },
          });

          if (response.data._embedded) {
            liftTypesResponse = response.data;
          }
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getCategories(
    id: number | string,
    auth: Auth,
  ): Promise<GetCategoriesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<GetCategoriesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "parent.id": id,
              depth: 3,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getItems(id: number | string, auth: Auth): Promise<GetCategoriesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<GetCategoriesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "parent.id": id,
              depth: 4,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async editPart(
    id: number | string,
    part: EditPartRequest,
    auth: Auth,
  ): Promise<GetCategoriesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<GetCategoriesResponse> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}/${id}`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: part,
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async removePart(id: number | string, auth: Auth): Promise<void> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<any> = await axios({
            method: "DELETE",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data.message) {
            throw new Error(response.data.message);
          }

          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getSpecs(parentId: number | string, auth: Auth): Promise<EquipmentCategory> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<EquipmentCategory> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}/${parentId}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async setSpec(id: number, spec: Spec, auth: Auth): Promise<EquipmentCategory> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<EquipmentCategory> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}/${id}`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: {
              id,
              spec,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addCategories(
    parentId: number,
    depth: number,
    name: string,
    auth: Auth,
  ): Promise<EquipmentCategory> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<EquipmentCategory> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.CATEGORIES}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              depth,
              name,
              parent: { id: parentId },
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getDiagnosisCode(liftType: string, auth: Auth): Promise<GetDiagnosisCodes> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<GetDiagnosisCodes> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.DIAGNOSIS_CODE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              liftType,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addDiagnosisCode(
    request: AddDiagnosisCodeRequest,
    auth: Auth,
  ): Promise<EquipmentCategory> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<EquipmentCategory> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.DIAGNOSIS_CODE}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: request,
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async editDiagnosisCode(
    id: string | number,
    request: AddDiagnosisCodeRequest,
    auth: Auth,
  ): Promise<EquipmentCategory> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<EquipmentCategory> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.DIAGNOSIS_CODE}/${id}`,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
            data: request,
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async removeDiagnosisCode(
    id: number | string,
    auth: Auth,
  ): Promise<EquipmentCategory> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SettingService.pathSetter(auth);
          const response: AxiosResponse<EquipmentCategory> = await axios({
            method: "DELETE",
            url: `${API_SERVER_URL}/${SettingService.PATH}/${SettingService.DIAGNOSIS_CODE}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
