import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, InputBase, InputBaseProps, styled } from "@mui/material";
import React, { useState } from "react";

const StyledInput = styled(InputBase)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  fontSize: theme.typography.body2.fontSize,
  "& .MuiInputBase-input": {
    textIndent: 6,
  },
  "&.Mui-disabled": {
    background: theme.palette.action.disabledBackground,
  },
}));

interface Props extends InputBaseProps {
  value?: string | number;
  name?: string;
  placeholder?: string;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  required?: boolean;
  type?: string;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  multiline?: boolean;
}

const Input = ({ value, type, disabled, endAdornment, ...restProps }: Props) => {
  const [showPassword, toggleShowPassword] = useState(false);

  const checkType = () => {
    if (type === "password" && showPassword) {
      return "text";
    }
    return type;
  };

  const handleShowPassword = () => {
    toggleShowPassword((prev) => !prev);
  };

  return (
    <StyledInput
      type={checkType()}
      value={value || ""}
      disabled={disabled}
      endAdornment={
        type === "password" && value ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleShowPassword} sx={{ mr: 0.5 }}>
              {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ) : (
          endAdornment
        )
      }
      {...restProps}
    />
  );
};

Input.defaultProps = {
  value: undefined,
  placeholder: undefined,
  onClick: undefined,
  onChange: undefined,
  disabled: false,
  fullWidth: undefined,
  error: undefined,
  name: undefined,
  required: false,
  type: undefined,
  endAdornment: undefined,
  readOnly: false,
  multiline: false,
};

export default Input;
