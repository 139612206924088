import React, { lazy, Suspense } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Gateway from "./pages/gateway/Gateway";
import GatewayDetail from "./pages/gateway/GatewayDetail";
import GatewayRegist from "./pages/gateway/GatewayRegist";
import Login from "./pages/Login";
import Sensor from "./pages/sensor/Sensor";
import SensorDetail from "./pages/sensor/SensorDetail";
import SensorRegist from "./pages/sensor/SensorRegist";
import State from "./pages/state/State";
import DiagnosisDetail from "./pages/state/DiagnosisDetail";
import MaintenanceDetail from "./pages/state/MaintenanceDetail";
import User from "./pages/user/User";
import UserDetail from "./pages/user/UserDetail";
import UserRegist from "./pages/user/UserRegist";
import Profile from "./pages/Profile";
import useNotifier from "./hooks/useNotifier";
import Layout from "./components/Layout";
import MapDialog from "./components/Map/MapDialog";
import Site from "./pages/site/Site";
import SiteDetail from "./pages/site/SiteDetail";
import ResultReport from "./pages/report/ResultReport";
import QuarterlyReport from "./pages/report/QuarterlyReport";
import Maintenance from "./pages/maintenance/Maintenance";
import ReportPage from "./pages/report/ReportPage";

ChartJS.register(
  ArcElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Legend,
  Filler,
  Title,
  Tooltip,
);

const Company = lazy(() => import("./pages/company/Company"));
const CompanyDetail = lazy(() => import("./pages/company/CompanyDetail"));
const CompanyRegist = lazy(() => import("./pages/company/CompanyRegist"));
const Inspection = lazy(() => import("./pages/inspection/Inspection"));
const InspectionRegist = lazy(() => import("./pages/inspection/InspectionRegist"));
const InspectionDetail = lazy(() => import("./pages/inspection/InspectionDetail"));
const Lift = lazy(() => import("./pages/lift/Lift"));
const LiftDetail = lazy(() => import("./pages/lift/LiftDetail"));
const LiftRegist = lazy(() => import("./pages/lift/LiftRegist"));
const Report = lazy(() => import("./pages/Report"));
const Setting = lazy(() => import("./pages/Setting"));
const Home = lazy(() => import("./pages/Home"));

const queryClient = new QueryClient();

function App() {
  useNotifier();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MapDialog />
        <Suspense fallback={<Layout />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/resultReport" element={<ResultReport />} />
            <Route path="/quarterlyReport" element={<QuarterlyReport />} />
            <Route element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/report" element={<ReportPage />} />
              <Route path="/state" element={<State />} />
              <Route path="/state/:id" element={<DiagnosisDetail />} />
              <Route path="/state/maintenance/:id" element={<MaintenanceDetail />} />
              <Route path="/inspection" element={<Inspection />} />
              <Route path="/inspection/:id" element={<InspectionDetail />} />
              <Route path="/inspection/regist/:id" element={<InspectionRegist />} />
              <Route path="/lift" element={<Lift />} />
              <Route path="/lift/detail/:id" element={<LiftDetail />} />
              <Route path="/lift/regist" element={<LiftRegist />} />
              <Route path="/company" element={<Company />} />
              <Route path="/company/detail/:id" element={<CompanyDetail />} />
              <Route path="/company/regist" element={<CompanyRegist />}>
                <Route path=":id" element={<CompanyRegist />} />
              </Route>
              <Route path="/site" element={<Site />} />
              <Route path="/site/:id" element={<SiteDetail />} />
              <Route path="/user" element={<User />} />
              <Route path="/user/detail/:id" element={<UserDetail />} />
              <Route path="/user/regist" element={<UserRegist />}>
                <Route path=":id" element={<UserRegist />} />
              </Route>
              <Route path="/gateway" element={<Gateway />} />
              <Route path="/gateway/detail/:id" element={<GatewayDetail />} />
              <Route path="/gateway/regist" element={<GatewayRegist />}>
                <Route path=":id" element={<GatewayRegist />} />
              </Route>
              <Route path="/sensor" element={<Sensor />} />
              <Route path="/sensor/detail/:id" element={<SensorDetail />} />
              <Route path="/sensor/regist" element={<SensorRegist />}>
                <Route path=":id" element={<SensorRegist />} />
              </Route>
              <Route path="/setting" element={<Setting />} />
              <Route path="/profile/:id" element={<Profile />} />
              <Route path="/maintenance" element={<Maintenance />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
