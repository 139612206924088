import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/reducers";
import { setStatus } from "../../../redux/reducers/config";
import StateService from "../../../services/StateService";
import { enqueueSnackbar } from "../../../redux/reducers/snackbar";
import { isStatusStop } from "../../../utils/parse";

interface Props {
  // eslint-disable-next-line react/require-default-props
  //   liftType?: Lift["liftType"];
  liftItlpc?: string;
  //   sensorResult: {
  //     vibrations: LiftVibration[];
  //     noises: LiftNoises[];
  //     esChains: EsChain[];
  //   };
  controllerDiagnosis?: ControllerDiagnosis;
}

const DiagnosisDetailLiftAnimation = ({ liftItlpc, controllerDiagnosis }: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  const [liftStatus, setLiftStatus] = useState<LiftStatus>();

  const fetchLiftStatus = useCallback(async () => {
    try {
      if (!auth.auth) return;
      const response = await StateService.getLiftStatus(Number(id), auth);
      if (response) setLiftStatus(response);
      else setLiftStatus(undefined);
      // console.log(`${response[0].doorStatus} / ${response[0].opStatus}`);
    } catch (error: any) {
      console.log(error);
      dispatch(enqueueSnackbar({ message: error, options: { variant: "error" } }));
    }
  }, [id, auth, dispatch]);

  useEffect(() => {
    fetchLiftStatus(); // 초기 데이터 가져오기

    const intervalId = setInterval(() => {
      fetchLiftStatus(); // 5초마다 데이터 갱신
    }, 5000);

    return () => {
      // 컴포넌트가 언마운트될 때 clearInterval로 인터벌 정리
      clearInterval(intervalId);
    };
  }, [fetchLiftStatus]);

  const opModeStatus = useCallback(
    (opStatus: string) => {
      switch (opStatus) {
        // case "Auto_Drive":
        //   return lang.DiagnosisDetailLiftAnimation.운행중;
        case "GW_Comm_Err":
          return lang.DiagnosisDetailLiftAnimation.통신에러;
        case "Disc_Comm_Cable":
          return lang.DiagnosisDetailLiftAnimation.케이블;
        default:
          return opStatus;
        // return lang.DiagnosisDetailLiftAnimation.운행정지;
      }
    },
    [lang],
  );

  const door = useCallback(
    (status: LiftStatus | undefined, Itlpc: string | undefined): JSX.Element => {
      // 데이터 없을 경우
      if (status === undefined || Itlpc === undefined) {
        return (
          <>
            <div className="floor" />
            <div className="door-stop" />
            <div className="closedoor-left" />
            <div className="closedoor-right" />
            <div className="elevator" />
            <div className="status_light">
              <div className="elevator_num">
                <p>
                  <span className="circle_red" />
                  &nbsp;<span>{Itlpc}</span>
                </p>
                <span className="status status_stop">오류</span>
              </div>
              <div className="elevator_floor">
                <span />
              </div>
            </div>
          </>
        );
      }
      // 엘리베이터 닫히는 애니메이션 상태
      /** 한글 status 커스텀 해야함 (전체 다) */
      if (["도어닫히는중", "도어반쯤열린상태"].indexOf(status.doorStatus) > -1) {
        return (
          <>
            <div className="floor">{status.currentFloor}</div>
            <div className="door-stop-ani" />
            <div className="closedoor-left-ani" />
            <div className="closedoor-right-ani" />
            <div className="elevator" />
            <div className="status_light">
              <div className="elevator_num">
                <p>
                  <span className={`${status.status === "00" ? "circle_blue" : "circle_red"}`} />
                  &nbsp;<span>{Itlpc}</span>
                </p>
                <span
                  className={`status ${isStatusStop(status.opMode)} ${
                    status.opMode === "GW_Comm_Err" || status.opMode === "Disc_Comm_Cable"
                      ? "comm_err"
                      : ""
                  }`}
                >
                  {opModeStatus(status.opMode)}
                </span>
              </div>
              <div className="elevator_floor">
                {
                  /* eslint-disable-next-line */
                  liftStatus && liftStatus.opStatus !== "정지" ? (
                    liftStatus.opStatus === "상승" ? (
                      <div className="uparrow" />
                    ) : (
                      <div className="downarrow" />
                    )
                  ) : (
                    ""
                  )
                }
                <span>{status.currentFloor}</span>
              </div>
            </div>
          </>
        );
      }
      // 엘리베이터 열리는 애니메이션 상태
      if (status.doorStatus === "도어열리는중") {
        return (
          <>
            <div className="floor">{status.currentFloor}</div>
            <div className="opendoor-left-ani" />
            <div className="opendoor-right-ani" />
            <div className="elevator" />
            <div className="status_light">
              <div className="elevator_num">
                <p>
                  <span className={`${status.status === "00" ? "circle_blue" : "circle_red"}`} />
                  &nbsp;<span>{Itlpc}</span>
                </p>
                <span
                  className={`status ${isStatusStop(status.opMode)} ${
                    status.opMode === "GW_Comm_Err" || status.opMode === "Disc_Comm_Cable"
                      ? "comm_err"
                      : ""
                  }`}
                >
                  {opModeStatus(status.opMode)}
                </span>
              </div>
              <div className="elevator_floor">
                {
                  /* eslint-disable-next-line */
                  liftStatus && liftStatus.opStatus !== "정지" ? (
                    liftStatus.opStatus === "상승" ? (
                      <div className="uparrow" />
                    ) : (
                      <div className="downarrow" />
                    )
                  ) : (
                    ""
                  )
                }
                <span>{status.currentFloor}</span>
              </div>
            </div>
          </>
        );
      }
      // 엘리베이터 열린 상태
      if (status.doorStatus === "도어열림") {
        return (
          <>
            <div className="floor">{status.currentFloor}</div>
            <div className="opendoor-left" />
            <div className="opendoor-right" />
            <div className="elevator" />
            <div className="status_light">
              <div className="elevator_num">
                <p>
                  <span className={`${status.status === "00" ? "circle_blue" : "circle_red"}`} />
                  &nbsp;<span>{Itlpc}</span>
                </p>
                <span
                  className={`status ${isStatusStop(status.opMode)} ${
                    status.opMode === "GW_Comm_Err" || status.opMode === "Disc_Comm_Cable"
                      ? "comm_err"
                      : ""
                  }`}
                >
                  {opModeStatus(status.opMode)}
                </span>
              </div>
              <div className="elevator_floor">
                {
                  /* eslint-disable-next-line */
                  liftStatus && liftStatus.opStatus !== "정지" ? (
                    liftStatus.opStatus === "상승" ? (
                      <div className="uparrow" />
                    ) : (
                      <div className="downarrow" />
                    )
                  ) : (
                    ""
                  )
                }
                <span>{status.currentFloor}</span>
              </div>
            </div>
          </>
        );
      }
      // 엘리베이터 상승상태
      if (status.opStatus === "상승") {
        return (
          <>
            <div className="floor">{status.currentFloor}</div>
            <div className="door-moveup" />
            <div className="closedoor-left" />
            <div className="closedoor-right" />
            <div className="elevator" />
            <div className="status_light">
              <div className="elevator_num">
                <p>
                  <span className={`${status.status === "00" ? "circle_blue" : "circle_red"}`} />
                  &nbsp;<span>{Itlpc}</span>
                </p>
                <span
                  className={`status ${isStatusStop(status.opMode)} ${
                    status.opMode === "GW_Comm_Err" || status.opMode === "Disc_Comm_Cable"
                      ? "comm_err"
                      : ""
                  }`}
                >
                  {opModeStatus(status.opMode)}
                </span>
              </div>
              <div className="elevator_floor">
                <div className="uparrow" />
                <span>{status.currentFloor}</span>
              </div>
            </div>
          </>
        );
      }
      // 엘리베이터 하강 상태
      if (status.opStatus === "하강") {
        return (
          <>
            <div className="floor">{status.currentFloor}</div>
            <div className="door-movedown" />
            <div className="closedoor-left" />
            <div className="closedoor-right" />
            <div className="elevator" />
            <div className="status_light">
              <div className="elevator_num">
                <p>
                  <span className={`${status.status === "00" ? "circle_blue" : "circle_red"}`} />
                  &nbsp;<span>{Itlpc}</span>
                </p>
                <span
                  className={`status ${isStatusStop(status.opMode)} ${
                    status.opMode === "GW_Comm_Err" || status.opMode === "Disc_Comm_Cable"
                      ? "comm_err"
                      : ""
                  }`}
                >
                  {opModeStatus(status.opMode)}
                </span>
              </div>
              <div className="elevator_floor">
                <div className="downarrow" />
                <span>{status.currentFloor}</span>
              </div>
            </div>
          </>
        );
      }
      // 엘리베이터 닫힌 상태
      return (
        <>
          <div className="floor">{status.currentFloor}</div>
          <div className="door-stop" />
          <div className="closedoor-left" />
          <div className="closedoor-right" />
          <div className="elevator" />
          <div className="status_light">
            <div className="elevator_num">
              <p>
                <span className={`${status.status === "00" ? "circle_blue" : "circle_red"}`} />
                &nbsp;<span>{Itlpc}</span>
              </p>
              <span
                className={`status ${isStatusStop(status.opMode)} ${
                  status.opMode === "GW_Comm_Err" || status.opMode === "Disc_Comm_Cable"
                    ? "comm_err"
                    : ""
                }`}
              >
                {opModeStatus(status.opMode)}
              </span>
            </div>
            <div className="elevator_floor">
              {
                /* eslint-disable-next-line */
                liftStatus && liftStatus.opStatus !== "정지" ? (
                  liftStatus.opStatus === "상승" ? (
                    <div className="uparrow" />
                  ) : (
                    <div className="downarrow" />
                  )
                ) : (
                  ""
                )
              }
              <span>{status.currentFloor}</span>
            </div>
          </div>
        </>
      );
    },
    [opModeStatus, liftStatus],
  );

  return (
    <div className="elevator_box">
      <div style={{ width: "600px" }}>
        <div className="elevator_l">{door(liftStatus, liftItlpc)}</div>
      </div>
    </div>
  );
};

DiagnosisDetailLiftAnimation.defaultProps = {
  //   liftType: undefined,
  liftItlpc: undefined,
  controllerDiagnosis: undefined,
};

export default DiagnosisDetailLiftAnimation;
