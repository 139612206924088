import { all, fork } from "@redux-saga/core/effects";
import ConfigSaga from "./config";
import AuthSaga from "./auth";
import DashboardSaga from "./dashboard";
import RouteSaga from "./route";

function* rootSaga() {
  yield all([fork(ConfigSaga), fork(AuthSaga), fork(DashboardSaga), fork(RouteSaga)]);
}

export default rootSaga;
