import { Box, Divider } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTable from "../../hooks/useTable";
import useTableParams from "../../hooks/useTableParams";
import { RootState } from "../../redux/reducers";
import { setStatus, toggleMapDialog } from "../../redux/reducers/config";
import { clearDashboardContents } from "../../redux/reducers/dashboard";
import { setOrigin } from "../../redux/reducers/path";
import LiftService from "../../services/LiftService";
import { diagnosisColumns } from "../../utils/columns";
import { createPathbySite /* moveMapCenter */ } from "../../utils/geo";
import { parseKSTDate, parseStatus } from "../../utils/parse";
import SearchBar from "../SearchBar";
import Select from "../Select";
import Table from "../Table/Table";

const StateDiagnosisController = () => {
  const { t } = useTranslation();
  const lang: any = t("selectbox", { returnObjects: true });
  const { auth } = useSelector((state: RootState) => state.auth);
  // const { map } = useSelector((state: RootState) => state.map);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, totalElement, onChangeData } = useTable();
  const {
    searchParams,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    onChangeOrder,
    onChangeOption,
    onChangeSelect,
    onClearSearchParams,
    onSearch,
  } = useTableParams();

  const searchOption: SelectOption[] = [
    { label: lang.검색조건, value: "" },
    { label: lang.승강기번호, value: "equipmentNo" },
    { label: lang.업체명, value: "liftMntContracts.liftMntCpny.name" },
    { label: lang.담당자명, value: "mntWorker.name" },
    { label: lang.담당자ID, value: "mntWorker.loginId" },
    { label: lang.건물명, value: "site.buldNm" },
  ];

  const statusOptions: SelectOption[] = [
    { label: lang.상태, value: "" },
    { label: lang.경고, value: "11" },
    { label: lang.주의, value: "01" },
    { label: lang.정상, value: "00" },
  ];

  const status = searchParams.get("status") || "";
  const onClickRow = (row: ControllerStatus) =>
    navigate(`/state/${row.lift.id}`, { state: { controller: true } });

  useEffect(() => {
    (async () => {
      try {
        if (!auth.auth) return;
        dispatch(setStatus("loading"));
        const response = await LiftService.getLatestControllerStatus(
          {
            page,
            size: rowsPerPage,
            status,
            sortKey,
            sortDirection,
            searchKey,
            searchValue,
          },
          auth,
        );
        onChangeData({
          data: response.resultLiftCntlrs.map((lc) => ({
            ...lc,
            status: parseStatus(lc.status),
            checkedAt: parseKSTDate(lc.checkedAt, false),
          })),
          totalPage: response.page.totalPages,
          totalElement: response.page.totalElements,
        });
        dispatch(setStatus("ok"));
      } catch (error) {
        dispatch(setStatus("error"));
        // eslint-disable-next-line
        console.error(error);
      }
    })();
  }, [
    auth,
    page,
    status,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    searchValue,
    onChangeData,
    dispatch,
  ]);

  const onClickButton = useCallback(
    (row: ControllerStatus) => {
      // alert("StateDiagnosisController.tsx");
      // if (!map) return;
      dispatch(setOrigin(createPathbySite(row.site)));
      dispatch(clearDashboardContents());
      dispatch(toggleMapDialog());
      // console.log(row);
      // moveMapCenter(map, row.site.lat, row.site.lng);
    },
    [dispatch],
    // [map, dispatch],
  );

  return (
    <>
      <Box display="flex" justifyContent="end" alignItems="center" p={2}>
        <Box width={120} mr={1}>
          <Select
            value={status}
            name="status"
            options={statusOptions}
            required
            onChange={onChangeSelect}
          />
        </Box>
        <Box width={120} mr={1}>
          <Select
            value={searchKey}
            name="searchKey"
            options={searchOption}
            required
            onChange={onChangeSelect}
          />
        </Box>
        <SearchBar onSearch={onSearch} onClear={onClearSearchParams} />
      </Box>
      <Divider />
      <Table
        order={sortDirection}
        orderBy={sortKey}
        page={page}
        data={data}
        columns={diagnosisColumns}
        rowsPerPage={rowsPerPage}
        totalElement={totalElement}
        onClickRow={onClickRow}
        onChangeOption={onChangeOption}
        onChangeOrder={onChangeOrder}
        onClickButton={onClickButton}
      />
    </>
  );
};

export default StateDiagnosisController;
