import axios, { AxiosResponse } from "axios";
import { REPORT_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface TimeInfo {
  created_at: string;
  modified_at: string;
}

interface LiftMonthlyUsage extends TimeInfo {
  id: number;
  equipment_id: number;
  usg_month: number;
  tot_usg_cnt: number;
  usg_year: number;
  usg_s_date: string;
  usg_e_date: string;
  _avg?: {
    tot_usg_cnt: number;
  };
}

interface LiftMonthlyUsageAround extends LiftMonthlyUsage {
  _avg: {
    tot_usg_cnt: number;
  };
}

interface LiftMonthlyUsageTime extends TimeInfo {
  id: number;
  equipment_id: number;
  usg_month: number;
  tot_usg_time: number;
  usg_year: number;
  usg_s_date: string;
  usg_e_date: string;
  _avg?: {
    tot_usg_time: number;
  };
}

interface LiftMonthlyUsageTimeAround extends LiftMonthlyUsageTime {
  _avg: {
    tot_usg_time: number;
  };
}

interface LiftErrorCnt {
  raw_cd: string;
  cnt: number;
}

export interface LiftError {
  id: number;
  checked_at: string;
  raw_cd: string;
  status_msg: string;
}

export interface QuarterDataList {
  usage: LiftMonthlyUsage[];
  time: LiftMonthlyUsageTime[];
  usageAround: LiftMonthlyUsageAround[];
  timeAround: LiftMonthlyUsageTimeAround[];
  lift: LiftErrorCnt[];
  liftTable: LiftError[];
}

export interface QuarterList {
  key: string;
  year: number;
  quarter: number;
  label: string;
}

interface QuarterReportRequest {
  equipmentId: string;
  year: string;
  quater: string;
}

class ReportService {
  private static pathSetter(auth: Auth) {
    return auth.id_token;
  }

  private static PATH = "api/admin";

  private static REPORT = "report";

  private static YEARLY = "year";

  private static QUARTERLY = "quarter";

  private static MONTHLY = "monthly";

  private static WEEKLY = "weekly";

  private static DAILY = "daily";

  private static LIST = "list";

  public static async getQuarterReportData(
    // {
    equipmentId: number,
    year: number,
    quarter: number,
    // }: QuarterReportRequest,
    auth: Auth,
  ): Promise<QuarterDataList> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = ReportService.pathSetter(auth);
          const response: AxiosResponse<QuarterDataList> = await axios({
            method: "POST",
            url: `${REPORT_URL}/${ReportService.PATH}/${ReportService.REPORT}/${ReportService.QUARTERLY}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              equipmentId,
              year,
              quarter,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getReportList(liftId: number, auth: Auth): Promise<QuarterList[]> {
    try {
      const token = ReportService.pathSetter(auth);
      const response = await axios({
        method: "GET",
        url: `${REPORT_URL}/${ReportService.PATH}/${ReportService.REPORT}/${ReportService.LIST}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          liftId,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}

export default ReportService;
