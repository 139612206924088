import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import { TextField, useTheme } from "@mui/material";
import koLocale from "date-fns/locale/ko";
import { useTranslation } from "react-i18next";

interface Props {
  name?: string;
  value?: Date | null;
  required?: boolean;
  minDate?: any;
  maxDate?: any;
  onChange?: (name: string, date: Date) => void;
}

const Calender = ({ value, name, required, minDate, maxDate, onChange }: Props) => {
  const { t } = useTranslation();
  const common: any = t("common", { returnObjects: true });
  const handleChange = (date: unknown) => {
    if (name && onChange) {
      onChange(name, date as Date);
    }
  };

  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={koLocale}>
      <DatePicker
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            size="small"
            variant="standard"
            inputProps={{
              ...params.inputProps,
              placeholder: common.날짜_선택,
              sx: { fontSize: theme.typography.body2.fontSize, textIndent: 8 },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

Calender.defaultProps = {
  name: "",
  value: null,
  minDate: undefined,
  maxDate: undefined,
  required: false,
  onChange: undefined,
};

export default Calender;
