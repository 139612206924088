import {
  TableRow,
  TableCell,
  TableHead as MuiTableHead,
  Checkbox,
  TableSortLabel,
  styled,
} from "@mui/material";
import React from "react";

interface Props {
  numSelected: number;
  onChangeOrder?: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  columns: Column<any>[];
  order: "desc" | "asc";
  orderBy: string | null;
  rowCount: number;
  checkbox: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: 41,
}));

const TableHead = ({
  onSelectAllClick,
  columns,
  order,
  orderBy,
  numSelected,
  rowCount,
  checkbox,
  onChangeOrder,
}: Props) => {
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onChangeOrder?.(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow sx={{ bgcolor: (theme) => theme.palette.grey[100] }}>
        {checkbox ? (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </StyledTableCell>
        ) : null}
        {columns.map((col) => (
          <StyledTableCell
            key={col.field as string}
            align={col.align || "center"}
            width={col.width}
            sortDirection={orderBy === col.field ? order || undefined : false}
          >
            <TableSortLabel
              active={orderBy === col.field}
              direction={orderBy === col.field ? order || undefined : "asc"}
              onClick={createSortHandler(col.field)}
              hideSortIcon
            >
              {col.heading}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.defaultProps = {
  onChangeOrder: () => {},
};

export default TableHead;
