import {
  TableContainer,
  Table as MuiTable,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  styled,
  TablePagination,
  Theme,
  Divider,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import React, { ChangeEventHandler, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { parseValue } from "../../utils/parse";
import TableHead from "./TableHead";

interface Props<
  Data = {
    [key: string]: any;
  },
> {
  data: Data[];
  selected?: number[];
  page?: number | string | null;
  order?: Order;
  orderBy?: string | null;
  rowsPerPage?: string | number | null;
  columns: { field: string; width?: number; heading: string; setValue?: Function }[];
  checkbox?: boolean;
  hidePagination?: boolean;
  totalElement?: number;
  activeRow?: boolean;
  onClickRow?: (row: any) => void;
  onClickButton?: (row: any) => void;
  onChangeOption?: (newOption: number, key: keyof TableOption) => void;
  onChangeOrder?: (event: React.MouseEvent<unknown>, property: any) => void;
  onChangeCheckBox?: (nums: number[]) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  userSelect: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: 41,
  color: "inherit",
}));

const Table = ({
  data,
  selected = [],
  page = 0,
  rowsPerPage = 10,
  totalElement = 0,
  columns,
  onClickRow,
  onClickButton,
  onChangeOption,
  onChangeOrder,
  onChangeCheckBox,
  hidePagination,
  activeRow = false,
  checkbox = false,
  order = "asc",
  orderBy = "",
}: Props) => {
  const { t } = useTranslation();
  const lang: any = t("common", { returnObjects: true });

  const isSelected = (index: number) => selected.indexOf(index) !== -1;

  const handleClickCheckBox = (event: React.MouseEvent<HTMLTableCellElement>, index: number) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(index);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
        [],
      );
    }
    onChangeCheckBox?.(newSelected);
  };

  const [activeRowId, setActiveRowId] = useState<number>();

  const handleClickRow = (
    event: React.MouseEvent<HTMLTableRowElement>,
    row: { cells: any[]; id: number },
  ) => {
    onClickRow?.(data[row.id]);
    if (activeRow) setActiveRowId((prev) => (prev === row.id ? undefined : row.id));
  };

  const handleClickButton = (row: { cells: any[]; id: number }) => {
    onClickButton?.(data[row.id]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangeOption?.(newPage, "page");
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeOption?.(parseInt(event.target.value, 10), "rowsPerPage");
    onChangeOption?.(0, "page");
  };

  const rows = useMemo(() => {
    const rowArray: Row[] = [];

    data.forEach((values, idx) => {
      const cellArray: Cell[] = [];

      columns.forEach((column) => {
        const keys = Object.keys(values);
        let value: any = "";

        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];

          if (column.field === key) {
            // 유지보수 업체 예외
            if (column.field === "liftMntContracts" && values[key].length) {
              const liftMntContracts: LiftMaintenanceContract[] = values[key];
              const main = liftMntContracts.find((contract) => contract.type === "SUB");
              value = main ? main.liftMntCpny.name : liftMntContracts[0].liftMntCpny.name;
              break;
            }

            // setValue function 있을 경우 적용
            if (column.setValue) {
              value = column.setValue(values[key]);
              break;
            } else {
              value = values[key];
              break;
            }
          }

          if (
            cellArray.every((cell) => cell.column.field !== column.field) &&
            column.field.includes(".")
          ) {
            const splittedFields = column.field.split(".");
            if (splittedFields[0] === key) {
              value = values[splittedFields[0]];
              for (let j = 1; j < splittedFields.length; j += 1) {
                const field = splittedFields[j];
                value = value[field];
                if (typeof value !== "object") {
                  break;
                }
              }
              break;
            }
          }
        }
        cellArray.push({ value, column });
      });

      rowArray.push({ cells: cellArray, id: idx });
    });

    return rowArray;
  }, [data, columns]);

  const onSelectAll: ChangeEventHandler<HTMLInputElement> = () => {
    if (selected.length === rows.length) {
      onChangeCheckBox?.([]);
    } else {
      const newSelected = rows.map((row) => row.id);
      onChangeCheckBox?.(newSelected);
    }
  };

  /** 한글값 커스텀 해야함 */
  const getColor = (theme: Theme, values: Cell[]) => {
    if (values.some((cell) => cell.value === "경고")) {
      return theme.palette.error.main;
    }

    if (values.some((cell) => cell.value === "주의")) {
      return theme.palette.warning.main;
    }

    return theme.palette.text.primary;
  };

  useEffect(() => {
    setActiveRowId(undefined);
  }, [rows]);

  return (
    <>
      <TableContainer>
        <MuiTable size="small">
          <TableHead
            checkbox={checkbox}
            columns={columns}
            order={order}
            orderBy={orderBy}
            numSelected={selected.length}
            onSelectAllClick={onSelectAll}
            onChangeOrder={onChangeOrder}
            rowCount={data.length}
          />
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow
                hover
                key={idx}
                onClick={(event) => handleClickRow(event, row)}
                sx={{
                  color: (theme) => getColor(theme, row.cells),
                  ...(activeRowId === row.id && {
                    background: (theme) => theme.palette.action.selected,
                  }),
                  ...(onClickRow && {
                    cursor: "pointer",
                  }),
                }}
              >
                {checkbox && (
                  <StyledTableCell
                    padding="checkbox"
                    onClick={(event) => handleClickCheckBox(event, row.id)}
                    sx={rows.length - 1 === idx ? { border: 0 } : undefined}
                  >
                    <Checkbox checked={isSelected(row.id)} />
                  </StyledTableCell>
                )}
                {row.cells.map(({ value, column }, index) => (
                  <StyledTableCell
                    key={index}
                    align={column.align || "center"}
                    sx={rows.length - 1 === idx ? { border: 0 } : undefined}
                  >
                    {parseValue(value)}
                    {column.icon && (
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClickButton(row);
                        }}
                      >
                        {column.icon}
                      </IconButton>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
        {rows.length === 0 && (
          <Box textAlign="center" py={1}>
            <Typography variant="body2">{lang.데이터없음}</Typography>
          </Box>
        )}
      </TableContainer>
      {!hidePagination && (
        <>
          <Divider />
          <TablePagination
            rowsPerPage={Number(rowsPerPage)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={totalElement}
            page={Number(page) || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={lang.페이지개수}
            labelDisplayedRows={({ from, to, count }) =>
              `${lang.부터전}${from}${lang.부터} ${to}${lang.까지} - ${lang.총} ${count}${lang.개}`
            }
          />
        </>
      )}
    </>
  );
};

Table.defaultProps = {
  order: undefined,
  orderBy: undefined,
  selected: [],
  page: 0,
  rowsPerPage: 10,
  totalElement: 0,
  hidePagination: false,
  checkbox: false,
  activeRow: false,
  onClickRow: undefined,
  onClickButton: () => {},
  onChangeOption: undefined,
  onChangeOrder: () => {},
  onChangeCheckBox: undefined,
};

export default Table;
