import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { LoginRequest, AuthActionTypes } from "../../@types/redux/auth.interface";
import AuthService, { Auth } from "../../services/AuthService";
import { loginFailure, loginSuccess } from "../reducers/auth";

const { LOGIN_REQUEST } = AuthActionTypes;

function* loginRequest({ payload }: LoginRequest) {
  try {
    const { loginId, password, navigate } = payload;
    const auth: Auth = yield call(AuthService.getToken, loginId, password);
    yield put(loginSuccess(auth));
    navigate("/", { replace: true });
  } catch (error) {
    yield put(loginFailure());
  }
}

function* loginRequestWatcher() {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
}

export default function* AuthSaga() {
  yield all([fork(loginRequestWatcher)]);
}
