import { Map } from "ol";
import { createEmpty, extend } from "ol/extent";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";

export const DST = "EPSG:900913";
export const SRC = "EPSG:4326";

export function moveMapPosition(map: Map, points: { lat: number; lng: number }[]) {
  if (points.length === 0) {
    return;
  }
  const view = map.getView();
  const extent = createEmpty();
  points.forEach((point) => {
    const positionExtent = new Point(fromLonLat([point.lng, point.lat])).getExtent();
    extend(extent, positionExtent);
  });
  view.fit(extent, { duration: 700, padding: [10, 10, 10, 10], maxZoom: 15 });
}

export function createPathbyPlace(place: Place): Path {
  return { id: Date.now(), title: place.name, place };
}
export function createPathbyBuilding(building: Building): Path {
  return {
    id: Date.now(),
    title: building.site.buldNm,
    place: {
      name: building.site.buldNm,
      placeId: building.id,
      location: { lat: building.site.lat, lng: building.site.lng },
    },
  };
}

export function createPathbySite(site: Site): Path {
  return {
    id: Date.now(),
    title: site.buldNm,
    place: {
      name: site.buldNm,
      placeId: site.id,
      location: { lat: site.lat, lng: site.lng },
    },
  };
}

export function createPathbyMapSite(site: MapSite): Path {
  return {
    id: Date.now(),
    title: site.buldNm,
    place: {
      name: site.buldNm,
      placeId: site.siteId,
      location: { lat: site.lat, lng: site.lng },
    },
  };
}

export function moveMapCenter(map?: Map, lat?: number, lng?: number) {
  if (!map || !lat || !lng) return;
  const view = map.getView();
  view.setCenter(fromLonLat([lng, lat]));
}

export default {};
