import { ArrowBack, Edit } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoBox from "../../components/Info/InfoBox";
import InfoList from "../../components/Info/InfoList";
import InfoRow from "../../components/Info/InfoRow";
import Paper from "../../components/Paper";
import Table from "../../components/Table/Table";
import { RootState } from "../../redux/reducers";
import GatewayService from "../../services/GatewayService";
import LiftService from "../../services/LiftService";
import SensorService from "../../services/SensorService";
import useEnum from "../../hooks/useEnum";

interface Results {
  gateway?: IotGateway;
  lifts: Lift[];
  sensors: Sensor[];
}

const GatewayDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state: RootState) => state.auth);

  const enumName = useEnum(auth);

  const { t } = useTranslation();
  const selectbox: any = t("selectbox", { returnObjects: true });
  const lang: any = t("GatewayDetail", { returnObjects: true });

  const sensorColumns: Column<Sensor>[] = [
    { field: "id", heading: selectbox.No },
    {
      field: "sensorType",
      heading: selectbox.Type,
      setValue: (param: any) => enumName("SensorType", param),
    },
    { field: "sn", heading: selectbox.SN },
    { field: "wifiMac", heading: selectbox.WIFI_MAC },
    { field: "fwVer", heading: selectbox.FW },
    {
      field: "axial",
      heading: selectbox.축,
      setValue: (param: any) => enumName("SensorDirection", param),
    },
    {
      field: "radial",
      heading: selectbox.반경,
      setValue: (param: any) => enumName("SensorDirection", param),
    },
    {
      field: "vertical",
      heading: selectbox.수직,
      setValue: (param: any) => enumName("SensorDirection", param),
    },
  ];

  const [results, setResults] = useState<Results>({ lifts: [], sensors: [] });

  useEffect(() => {
    (async () => {
      try {
        if (!id || !auth) return;
        const gateway = await GatewayService.getGateway(id, auth);
        const liftResponse = await LiftService.getAllLifts(
          {
            searchKey: "gateway.id",
            searchValue: id,
            contains: false,
          },
          auth,
        );
        const liftIds = liftResponse._embedded?.lifts.map((lift) => lift.id);
        let sensors: Sensor[] = [];

        if (liftIds) {
          const sensorResponse = await SensorService.getConnectedSensors(liftIds, auth);
          sensors = sensorResponse._embedded ? sensorResponse._embedded.liftsensors : [];
        }
        setResults({
          gateway,
          lifts: liftResponse._embedded ? liftResponse._embedded.lifts : [],
          sensors,
        });
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    })();
  }, [id, auth]);

  const handleClickEdit = () => navigate(`/gateway/regist/${id}`);
  const handleClickBack = () => navigate(`/gateway`);

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>
      <Grid item lg={8}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.게이트웨이_정보}
        </Typography>
        <InfoList>
          <InfoRow>
            <InfoBox title={lang.제조사} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.madeBy}</Typography>
            </InfoBox>
            <InfoBox title={selectbox.SN} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.sn}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={selectbox.BLE_MAC} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.bleMac}</Typography>
            </InfoBox>
            <InfoBox title={selectbox.WIFI_MAC} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.wifiMac}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.SSID} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.apSsid}</Typography>
            </InfoBox>
            <InfoBox title={lang.PW} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.apPassword}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.IP} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.apGwIp}</Typography>
            </InfoBox>
            <InfoBox title={lang.PORT} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.apPort}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.AP_Channel} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.apChannel}</Typography>
            </InfoBox>
            <InfoBox title={lang.펌웨어_버전} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.fwVer}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.진단주기} flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{results.gateway?.intrvl}</Typography>
            </InfoBox>
            <InfoBox title="" flex={1} valueSx={{ flex: 3 }}>
              <Typography variant="body2">{null}</Typography>
            </InfoBox>
          </InfoRow>
        </InfoList>
        <Box display="flex" justifyContent="end" pt={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickEdit}
            startIcon={<Edit />}
          >
            {lang.수정}
          </Button>
        </Box>
      </Grid>
      <Grid item lg={4}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.승강기_정보}
        </Typography>
        {results.lifts.map((lift) => (
          <Box key={lift.id}>
            <InfoList pb={2}>
              <InfoRow>
                <InfoBox title={lang.지역} flex={1}>
                  <Typography variant="body2">{lift.site.sido}</Typography>
                </InfoBox>
                <InfoBox title={lang.건물명} flex={1}>
                  <Typography variant="body2">{lift.site.buldNm}</Typography>
                </InfoBox>
              </InfoRow>
              <InfoRow>
                <InfoBox title={lang.승강기번호} flex={1}>
                  <Typography variant="body2">{lift.equipmentNo}</Typography>
                </InfoBox>
                <InfoBox title={lang.위치_호기} flex={1}>
                  <Typography variant="body2">{lift.itlpc}</Typography>
                </InfoBox>
              </InfoRow>
              <InfoRow>
                <InfoBox title={lang.제조업체} flex={1} valueSx={{ flex: 3 }}>
                  <Typography variant="body2">{lift.mfCpnyNm}</Typography>
                </InfoBox>
              </InfoRow>
              <InfoRow>
                <InfoBox title={lang.유지보수업체} flex={1} valueSx={{ flex: 3 }}>
                  <Typography variant="body2">{lift.mntCpnyNm}</Typography>
                </InfoBox>
              </InfoRow>
            </InfoList>
            <Typography variant="subtitle2" gutterBottom>
              {lang.관리_업체_정보}
            </Typography>
            <InfoList>
              <InfoBox title={lang.관리_업체명} valueSx={{ flex: 3 }}>
                <Typography variant="body2">
                  {lift.liftMntContracts?.[0]?.liftMntCpny.name}
                </Typography>
              </InfoBox>
              <InfoBox title={lang.관리_업체_연락처} valueSx={{ flex: 3 }}>
                <Typography variant="body2">
                  {lift.liftMntContracts?.[0]?.liftMntCpny.telNo}
                </Typography>
              </InfoBox>
            </InfoList>
          </Box>
        ))}
      </Grid>
      <Grid item lg={8}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.센서_정보}
        </Typography>
        <Paper>
          <Table columns={sensorColumns} data={results.sensors} hidePagination />
        </Paper>
      </Grid>
    </>
  );
};

export default GatewayDetail;
