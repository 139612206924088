import axios, { AxiosResponse } from "axios";
import QueryString from "qs";
import { API_SERVER_URL, REPORT_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetDiagnosisListResponse extends ServerResponse {
  _embedded: {
    liftdiagnosisexecutions: SensorDiagnosis[];
  };
}

interface GetVibrationsResponse extends ServerResponse {
  _embedded: {
    resultLiftVibrations: LiftVibration[];
  };
}
interface GetNoisesResponse extends ServerResponse {
  _embedded: {
    resultLiftNoises: LiftNoises[];
  };
}
interface GetChainsResponse extends ServerResponse {
  _embedded: {
    resultEsChains: EsChain[];
  };
}
// 추가 Lift Status
interface GetLiftStatusResponse extends ServerResponse {
  _embedded: {
    liftStatuses: LiftStatus[];
  };
}

interface GetLatestDiagnosisesResponse {
  liftDiagnosisExecutions?: DiagnosisExecutionResult[];
  page: PageData;
}

export interface GetLiftLastestDiagnosisesResponse {
  dCodes?: DiagnosisCode[];
  liftDiagnosisExecution?: DiagnosisExecutionResult;
}

interface GetLatestDiagnosisesRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: "asc" | "desc";
  searchKey?: string;
  searchValue?: string | number;
  status?: DiagnosisStatus | string | DiagnosisStatus[];
}

interface GetDiagnosisListRequest {
  page?: number | string;
  size?: number | string;
  liftId: number | string;
}

// 추가 Lift Status Request param
// interface GetLiftStatusRequiest {
//   liftId: number | string;
// }

export default class StateService {
  private static PATH = "api/admin";

  private static DIAGNOSIS_EXECUTIONS = "liftDiagnosisExecutions";

  private static LIFTS = "lifts";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") StateService.PATH = "api";
    else StateService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getLatestDiagnosises(
    {
      page,
      size,
      sortKey,
      sortDirection,
      searchKey,
      searchValue,
      status,
    }: GetLatestDiagnosisesRequest,
    auth: Auth,
  ): Promise<GetLatestDiagnosisesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = StateService.pathSetter(auth);
          const response: AxiosResponse<GetLatestDiagnosisesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${StateService.PATH}/${StateService.LIFTS}/:latestDiagnosisExecution`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              ...(sortKey &&
                sortDirection && {
                  sort: `${sortKey},${sortDirection}`,
                }),
              ...(searchKey &&
                searchValue && {
                  [searchKey]: `${searchValue}`,
                }),
              ...(status && {
                status,
              }),
            },
            paramsSerializer: (params) => {
              return QueryString.stringify(params, { arrayFormat: "brackets" })
                .replaceAll(/%5B/g, "")
                .replaceAll(/%5D/g, "")
                .replace(/%28/g, "(")
                .replace(/%29/g, ")")
                .replace(/%2C/g, ",");
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getLiftLatestDiagnosises(
    liftId: number | string,
    auth: Auth,
  ): Promise<GetLiftLastestDiagnosisesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = StateService.pathSetter(auth);
          const response: AxiosResponse<GetLiftLastestDiagnosisesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${StateService.PATH}/${StateService.LIFTS}/${liftId}:latestDiagnosisExecution`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getDiagnosisList(
    { liftId, page, size }: GetDiagnosisListRequest,
    auth: Auth,
  ): Promise<GetDiagnosisListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = StateService.pathSetter(auth);
          const response: AxiosResponse<GetDiagnosisListResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${StateService.PATH}/${StateService.DIAGNOSIS_EXECUTIONS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "lift.id": liftId,
              page,
              size,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getVibrations(diagnosisId: number, auth: Auth): Promise<LiftVibration[]> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = StateService.pathSetter(auth);
          const response: AxiosResponse<GetVibrationsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${StateService.PATH}/resultLiftVibrations`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "diagnosisExecution.id": diagnosisId,
            },
          });
          resolve(response.data._embedded ? response.data._embedded.resultLiftVibrations : []);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getNoises(diagnosisId: number, auth: Auth): Promise<LiftNoises[]> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = StateService.pathSetter(auth);
          const response: AxiosResponse<GetNoisesResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${StateService.PATH}/resultLiftNoises`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "diagnosisExecution.id": diagnosisId,
            },
          });
          resolve(response.data._embedded ? response.data._embedded.resultLiftNoises : []);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getEsChains(diagnosisId: number, auth: Auth): Promise<EsChain[]> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = StateService.pathSetter(auth);
          const response: AxiosResponse<GetChainsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${StateService.PATH}/resultEsChains`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "diagnosisExecution.id": diagnosisId,
            },
          });
          resolve(response.data._embedded ? response.data._embedded.resultEsChains : []);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getLiftStatus(liftId: number, auth: Auth): Promise<LiftStatus> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = StateService.pathSetter(auth);
          const response: AxiosResponse<LiftStatus> = await axios({
            method: "GET",
            url: `${REPORT_URL}/${StateService.PATH}/state/liftStatuses`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              liftId,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
