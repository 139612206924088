import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";

const EnumMap = new Map<string, EnumData[]>();

export default class EnumService {
  public static async getEnum(name: string, auth: Auth): Promise<EnumData[]> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const cache = EnumMap.get(name);

          if (cache) {
            resolve(cache);
            return;
          }

          const response: AxiosResponse<EnumData[]> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/api/enums/${name}`,
            headers: {
              Authorization: `Bearer ${auth.id_token}`,
            },
          });

          EnumMap.set(name, response.data);
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getEnumName(name: string, code: string, auth?: Auth): Promise<string> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const cache = EnumMap.get(name);

          if (cache) {
            const matchingItem = cache?.find((r) => r.code === code);
            if (matchingItem !== undefined) resolve(matchingItem.title);
            resolve("");
          }
          if (auth === undefined) resolve("");
          else {
            const response: AxiosResponse<EnumData[]> = await axios({
              method: "GET",
              url: `${API_SERVER_URL}/api/enums/${name}`,
              headers: {
                Authorization: `Bearer ${auth.id_token}`,
              },
            });
            if (response.data.length > 0) {
              EnumMap.set(name, response.data);
              const matchingItem = response.data.find((r) => r.code === code);
              if (matchingItem !== undefined) resolve(matchingItem.title);
            }
            resolve("");
          }
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getAllEnum(auth: Auth): Promise<Enums> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response: AxiosResponse<Enums> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/api/enums/`,
            headers: {
              Authorization: `Bearer ${auth.id_token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
