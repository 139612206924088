import { Map } from "ol";
import { MapAction, MapActionTypes, MapState, SetMap } from "../../@types/redux/map.interface";

const { SET_MAP } = MapActionTypes;

export const setMap = (map?: Map): SetMap => ({ payload: { map }, type: SET_MAP });

const initialState: MapState = {
  map: undefined,
};

export default function MapReducer(state = initialState, action: MapAction): MapState {
  switch (action.type) {
    case SET_MAP:
      return { ...state, map: action.payload.map };
    default:
      return state;
  }
}
