export enum RouteActionTypes {
  ROUTE_REQUEST = "ROUTE_REQUEST",
  ROUTE_SUCCESS = "ROUTE_SUCCESS",
  ROUTE_FAILURE = "ROUTE_FAILURE",
  CLEAR_ROUTES = "CLEAR_ROUTES",
}

export type RouteAction = RoutesRequest | RoutesSuccess | RoutesFailure | ClearRoutes;

export interface RoutesRequest {
  type: RouteActionTypes.ROUTE_REQUEST;
  payload: { places: Place[] };
}
export interface RoutesSuccess {
  type: RouteActionTypes.ROUTE_SUCCESS;
  payload: { routes: Route[] };
}
export interface ClearRoutes {
  type: RouteActionTypes.CLEAR_ROUTES;
  payload: {};
}

export interface RoutesFailure {
  type: RouteActionTypes.ROUTE_FAILURE;
  payload: {};
}

export interface RouteState {
  routes: Route[];
  status: "ok" | "loading" | "error";
}
