import { Box, Typography, styled } from "@mui/material";
import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  value: number[];
}

const StyledBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

// [경과(경고, 정지), 임박, 유효(정상)]
const colors = ["#FF0000", "#FEB019", "#1DDB16"];

const ApexDonutChart: React.FC<Props> = ({ name, value }) => {
  const { t } = useTranslation();
  const chart: any = t("chart", { returnObjects: true });

  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    labels:
      value.length === 3 ? [chart.정상, chart.임박, chart.유효] : [name.substr(-2, 2), chart.정상],
    colors: value.length === 3 ? colors : [colors[0], colors[2]],
  };

  return (
    <>
      <div className="chart">
        <Chart type="donut" options={options} series={value} width={150} height={150} />
      </div>
      <StyledBox textAlign="center">
        <Typography variant="subtitle1" color={colors[0]}>
          {value[0]}
        </Typography>
        <Typography variant="subtitle2" color={value.length === 3 ? colors[1] : colors[2]}>
          {value[1]}
        </Typography>
        {value.length === 3 && (
          <Typography variant="subtitle2" color={colors[2]} className="11">
            {value[2]}
          </Typography>
        )}
      </StyledBox>
    </>
  );
};

export default ApexDonutChart;
