import { LocationOn, NoteAdd, ArrowDropDownCircle, Remove } from "@mui/icons-material";
import React from "react";
import SlowCircularProgress from "../components/SlowCircularProgress";
import * as ko from "../lang/ko.json";
import * as en from "../lang/en.json";
import * as ch from "../lang/ch.json";
import { LANG } from "./const";

let transFile: any;
switch (LANG) {
  case "ko":
    transFile = ko;
    break;
  case "ch":
    transFile = ch;
    break;
  case "en":
    transFile = en;
    break;
  default:
    transFile = ko;
    break;
}

const lang = transFile.columns;

// export const lang: any = () => {
//   const { t } = useTranslation();

//   return t("selectbox", { returnObjects: true });
// };

export const liftUserColumns: Column<Lift>[] = [
  { heading: lang.지역, field: "site.sido" },
  { heading: lang.승강기번호, field: "equipmentNo" },
  {
    heading: lang.건물명,
    field: "site.buldNm",
    icon: <LocationOn fontSize="small" />,
    align: "left",
  },
  { heading: lang.설치위치_호기, field: "itlpc" },
  { heading: lang.제조업체, field: "mfCpnyNm", align: "left" },
  { heading: lang.담당자, field: "mntWorker.name" },
  { heading: lang.유효종료일, field: "validEndYmd" },
  { heading: lang.최종검사결과, field: "lastInspctRsltNm" },
  // { heading: lang.IoT, field: "iotYn" },
  { heading: lang.등록일, field: "createdAt" },
];

export const liftAdminColumns: Column<Lift>[] = [
  { heading: lang.지역, field: "site.sido" },
  { heading: lang.승강기번호, field: "equipmentNo" },
  {
    heading: lang.건물명,
    field: "site.buldNm",
    icon: <LocationOn fontSize="small" />,
    align: "left",
  },
  { heading: lang.설치위치_호기, field: "itlpc" },
  { heading: lang.제조업체, field: "mfCpnyNm", align: "left" },
  { heading: lang.업체, field: "liftMntContracts" },
  { heading: lang.유효종료일, field: "validEndYmd" },
  { heading: lang.최종검사결과, field: "lastInspctRsltNm" },
  { heading: lang.IoT, field: "iotYn" },
  { heading: lang.등록일, field: "createdAt" },
];

export const diagnosisColumns: Column<DiagnosisExecutionResult>[] = [
  { heading: lang.진단일, field: "checkedAt" },
  { heading: lang.지역, field: "site.sido" },
  {
    heading: lang.건물명,
    field: "site.buldNm",
    icon: <LocationOn fontSize="small" />,
    align: "left",
  },
  { heading: lang.승강기번호, field: "lift.equipmentNo" },
  { heading: lang.설치위치_호기, field: "lift.itlpc" },
  { heading: lang.제조업체, field: "lift.mfCpnyNm", align: "left" },
  { heading: lang.담당_업체, field: "mntCpnyNm", align: "left" },
  { heading: lang.정기검사_예정일, field: "lift.fdrmChkYmd" },
  { heading: lang.상태, field: "status" },
];

export const ownerDashboardColumns: Column<any>[] = [
  { heading: lang.승강기번호, field: "lift.equipmentNo", width: 100 },
  { heading: lang.건물명, field: "lift.site.buldNm" },
  { heading: lang.설치위치, field: "lift.itlpc" },
  // { heading: lang.운행모드, field: "lift.opMode" },
  {
    heading: lang.주행상태,
    field: "opStatus",
    setValue: (param: any) => {
      switch (param) {
        case "정지":
          return <Remove style={{ verticalAlign: "middle" }} />;
        case "상승":
          return (
            <ArrowDropDownCircle
              style={{ color: "green", transform: "rotate(180deg)", verticalAlign: "middle" }}
            />
          );
        case "하강":
          return <ArrowDropDownCircle style={{ color: "red", verticalAlign: "middle" }} />;
        default:
          return <SlowCircularProgress />;
      }
    },
  },
  { heading: lang.층, field: "currentFloor", width: 60 },
  { heading: lang.도어상태, field: "doorStatus", width: 110 },
  {
    heading: lang.장애상태,
    field: "status",
    setValue: (param: any) => (param === "00" ? "정상" : "경고"),
  },
  // { heading: lang.연락처, field: "col8" },
];

export const siteColumns: Column<any>[] = [
  { field: "sido", heading: lang.지역 },
  { field: "buldNm", heading: lang.건물명, align: "left" },
  { field: "address1", heading: lang.주소, align: "left" },
  { field: "owner.loginId", heading: lang.관리주체_아이디 },
  { field: "owner.name", heading: lang.관리주체명 },
  // { field: "evCount", heading: lang.E/V 대수" },
];

export const maintenanceColumns: Column<Lift>[] = [
  { field: "lastMntTask.type", heading: lang.구분 },
  { field: "lastMntTask.workYmd", heading: lang.작업일 },
  { field: "lastMntTask.worker.name", heading: lang.작업자 },
  { field: "lastMntTask.troubleYmd", heading: lang.장애_발생일 },
  { field: "site.sido", heading: lang.지역 },
  { field: "site.buldNm", heading: lang.건물명, align: "left" },
  { field: "equipmentNo", heading: lang.승강기번호 },
  { field: "itlpc", heading: lang.설치위치_호기 },
  { field: "mfCpnyNm", heading: lang.제조업체, align: "left" },
  { field: "liftMntContracts", heading: lang.업체_담당자 },
  { field: "validEndYmd", heading: lang.유효종료일 },
];

export const companyColumns: Column<Company>[] = [
  { field: "companyUnion.name", heading: lang.소속 },
  { field: "name", heading: lang.업체명, align: "left" },
  { field: "repNm", heading: lang.대표자명 },
  { field: "address", heading: lang.주소, align: "left" },
  { field: "telNo", heading: lang.전화번호 },
  { field: "mngr.loginId", heading: lang.관리자_아이디 },
  { field: "mngr.name", heading: lang.관리자명 },
  { field: "serviceKeyRegistYmd", heading: lang.API_등록일 },
];

export const userColumns: Column<User>[] = [
  { field: "company.name", heading: lang.업체명 },
  { field: "loginId", heading: lang.아이디 },
  { field: "name", heading: lang.이름 },
  { field: "koeisaId", heading: lang.자체점검ID },
  { field: "telNo", heading: lang.연락처 },
  { field: "email", heading: lang.이메일 },
  { field: "createdAt", heading: lang.가입일 },
  { field: "enabledYn", heading: lang.상태 },
];

export const inspectionColumns: Column<Lift>[] = [
  { heading: lang.최근점검일, field: "selChkYmd" },
  { heading: lang.지역, field: "site.sido" },
  { heading: lang.건물명, field: "site.buldNm", align: "left" },
  { heading: lang.승강기번호, field: "equipmentNo" },
  { heading: lang.설치위치_호기, field: "itlpc" },
  { heading: lang.제조업체, field: "mfCpnyNm", align: "left" },
  { heading: lang.업체_담당자, field: "liftMntContracts" },
  { heading: lang.유효종료일, field: "validEndYmd" },
  { heading: lang.상태, field: "status" },
  {
    heading: lang.점검등록,
    field: "addInspect",
    icon: <NoteAdd fontSize="small" color="primary" />,
  },
];

export const sensorColumns: Column<Sensor>[] = [
  { field: "id", heading: lang.No },
  { field: "sensorType", heading: lang.Type },
  { field: "sn", heading: lang.SN },
  { field: "bleMac", heading: lang.BLE_MAC },
  { field: "wifiMac", heading: lang.WIFI_MAC },
  { field: "fwVer", heading: lang.FW },
  { field: "btryVlt", heading: lang.Battery },
  { field: "sensorPosition", heading: lang.설치위치 },
  { field: "axial", heading: lang.축 },
  { field: "radial", heading: lang.반경 },
  { field: "vertical", heading: lang.수직 },
  { field: "createdAt", heading: lang.등록일 },
];

export const maintenanceTaskColumns: Column<MaintenanceTask>[] = [
  { field: "type", heading: lang.구분 },
  { field: "equipmentCtgr.parent.name", heading: lang.분류 },
  { field: "equipmentCtgr.name", heading: lang.품목 },
  { field: "workDtl", heading: lang.내역, width: 200 },
  { field: "cost", heading: lang.비용 },
  { field: "workYmd", heading: lang.작업일 },
];

export const maintenanceListColumns: Column<MaintenanceTask>[] = [
  { field: "type", heading: lang.구분 },
  { field: "workYmd", heading: lang.작업일 },
  { field: "worker.name", heading: lang.작업자 },
  { field: "troubleYmd", heading: lang.장애_발생일 },
  { field: "workDtl", heading: lang.내역 },
  { field: "dtype", heading: "승강기종류" },
  { field: "equipment.equipmentNo", heading: lang.승강기번호 },
  { field: "equipment.itlpc", heading: lang.설치위치_호기 },
  { field: "mfCpnyNm", heading: lang.제조업체, align: "left" },
];

export const gatewayColumns: Column<IotGateway>[] = [
  { field: "id", heading: lang.No, align: "center" },
  { field: "madeBy", heading: lang.제조사 },
  { field: "sn", heading: lang.SN },
  { field: "bleMac", heading: lang.BLE },
  { field: "wifiMac", heading: lang.MAC },
  { field: "apGwIp", heading: lang.IP },
  { field: "apSsid", heading: lang.SSID },
  { field: "apPassword", heading: lang.PW },
  { field: "createdAt", heading: lang.생성일 },
];

export default {};
