import axios, { AxiosResponse } from "axios";

// const OSM_URL = "https://nominatim.openstreetmap.org";
// const SERVER_URL = "http://211.106.178.51:8888";

const OSRM_URL = "http://osrm-api.gagopar.com";
const SERVER_URL = `${process.env.NODE_ENV}` === "development" ? "http://www.kbiz.site:8080" : "";

export default class GeoService {
  private static formatPoi(place: OsmPlace): Place {
    return {
      placeId: place.place_id,
      location: { lat: parseFloat(place.lat), lng: parseFloat(place.lon) },
      name: place.namedetails?.name || place.display_name.split(",")[0],
    };
  }

  public static pointToAddress(lon: number, lat: number) {
    return new Promise<Place>((resolve, reject) => {
      (async () => {
        try {
          const request = {
            url: `${OSRM_URL}/nominatim/reverse`,
            method: "GET",
            params: { lon, lat, format: "json", addressdetails: 1, namedetails: 1 },
          };
          const response: AxiosResponse<OsmPlace> = await axios({
            url: `${SERVER_URL}/api/proxy`,
            method: "POST",
            data: request,
          });

          resolve(GeoService.formatPoi(response.data));
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
