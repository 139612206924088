import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoBox from "../../../Info/InfoBox";
import InfoList from "../../../Info/InfoList";
import InfoRow from "../../../Info/InfoRow";

interface Props {
  diagnosis: ControllerDiagnosis;
}

/* 상태관리 - 상세 > 제어반 상태 정보 */
const DiagnosisDetailControllerResult = ({ diagnosis }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  return (
    <>
      <Box pt={2}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.DiagnosisDetailControllerResult.제어반_상태_정보}
        </Typography>
      </Box>
      <InfoList>
        <InfoRow>
          <InfoBox title={lang.DiagnosisDetailControllerResult.점검일시} flex={1}>
            <Typography variant="body2">
              {new Date(diagnosis.checkedAt).toLocaleString()}
            </Typography>
          </InfoBox>
        </InfoRow>
        <InfoRow>
          <InfoBox title={lang.DiagnosisDetailControllerResult.발생코드} flex={1}>
            <Typography variant="body2">{diagnosis.liftCntlrCode?.rawCd}</Typography>
          </InfoBox>
        </InfoRow>
        <InfoRow>
          <InfoBox title={lang.DiagnosisDetailControllerResult.모델} flex={1}>
            <Typography variant="body2">{diagnosis.liftCntlrCode?.liftCntlr?.id}</Typography>
          </InfoBox>
        </InfoRow>
        <InfoRow>
          <InfoBox title={lang.DiagnosisDetailControllerResult.결과} flex={1}>
            <Typography variant="body2">{diagnosis.status}</Typography>
          </InfoBox>
        </InfoRow>
      </InfoList>
    </>
  );
};

export default DiagnosisDetailControllerResult;
