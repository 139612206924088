import axios, { AxiosRequestConfig } from "axios";

export interface GetRouteReponse {
  hints: {
    "visited_nodes.sum": number;
    "visited_nodes.average": number;
  };
  info: {
    copyrights: string[];
  };
  id: number;
  paths: {
    ascend: number;
    bbox: number[];
    details: {};
    distance: number;
    time: number;
    points: {
      type: string;
      coordinates: [number, number][];
    };
  }[];
}

export interface GetRouteRequset {
  origin: Place;
  destination: Place;
}

const routeMap = new Map<string, GetRouteReponse>();
const HOPPER_PROTOCOL = "http";
const HOPPER_SERVER_IP = "osrm-api.gagopar.com";
const SERVER_URL = `${process.env.NODE_ENV}` === "development" ? "http://www.kbiz.site:8080" : "";

class RouteService {
  static parseRoute(response: GetRouteReponse): Route {
    const { points, time, distance } = response.paths[0];
    return {
      time,
      type: points.type,
      path: points.coordinates,
      distance,
      id: response.id,
    };
  }

  static getRoute({ origin, destination }: GetRouteRequset) {
    return new Promise<Route>((resolve, reject) => {
      (async () => {
        try {
          const coords = `${origin.location.lng},${origin.location.lat},${destination.location.lng},${destination.location.lat}`;
          const rcoords = `${destination.location.lng},${destination.location.lat},${origin.location.lng},${origin.location.lat}`;

          let data: GetRouteReponse | undefined =
            routeMap.get(`${coords}`) || routeMap.get(`${rcoords}`);

          if (!data) {
            const url = `${HOPPER_PROTOCOL}://${HOPPER_SERVER_IP}/route/car/${coords}`;
            const request: AxiosRequestConfig = { method: "GET", url };
            const route = await axios({
              method: "POST",
              url: `${SERVER_URL}/api/proxy`,
              data: request,
            });
            data = route.data;

            if (!data) {
              throw new Error("no routes found at server");
            }

            data.id = Date.now();

            if (routeMap.size > 500) {
              routeMap.clear();
            }

            routeMap.set(`${coords}`, data);
          }

          resolve(RouteService.parseRoute(data));
        } catch (e) {
          reject(e);
        }
      })();
    });
  }
}

export default RouteService;
