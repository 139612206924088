import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetLiftSelfCheckupsRequest {
  liftId: string | number;
  selChkYm: string;
}

export interface AddSelfCheckUpRequest {
  liftId: string | number;
  liftMntCpnyId: string | number;
  selChkUserId: string | number;
  selChkBgnAt: string;
  selChkEndAt: string;
  selChkYm: string;
  liftSelChkItemRslts: SelfCheckupResult[];
}

interface EditSelfCheckUpReqeust extends AddSelfCheckUpRequest {
  selChkId: number | string;
}

interface GetLiftSelfCheckupsResponse extends ServerResponse {
  _embedded?: {
    liftSelfCheckups: SelfCheckup[];
  };
}

interface GetCheckupResultsResponse extends ServerResponse {
  _embedded?: {
    liftSelfCheckupItemResults: SelfCheckupResult[];
  };
}

export default class SelfCheckupService {
  private static CHECK_UPS = "liftSelfCheckups";

  private static CHECK_UP_RESULTS = "liftSelfCheckupItemResults";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") SelfCheckupService.PATH = "api";
    else SelfCheckupService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getCheckups(
    { liftId, selChkYm }: GetLiftSelfCheckupsRequest,
    auth: Auth,
  ): Promise<GetLiftSelfCheckupsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SelfCheckupService.pathSetter(auth);
          const response: AxiosResponse<GetLiftSelfCheckupsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SelfCheckupService.PATH}/${SelfCheckupService.CHECK_UPS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "lift.id": liftId,
              selChkYm: `startsWith(${selChkYm})`,
              sort: "selChkYm,desc",
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getCheckupResults(
    { liftId, selChkYm }: GetLiftSelfCheckupsRequest,
    auth: Auth,
  ): Promise<GetCheckupResultsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SelfCheckupService.pathSetter(auth);
          const response: AxiosResponse<GetCheckupResultsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SelfCheckupService.PATH}/${SelfCheckupService.CHECK_UP_RESULTS}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              "liftSelChk.lift.id": liftId,
              "liftSelChk.selChkYm": selChkYm,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  /**
   * 자체점검 제외 API
   */
  public static async excludeCheckup(
    { liftId, selChkYm }: GetLiftSelfCheckupsRequest,
    auth: Auth,
  ): Promise<GetCheckupResultsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SelfCheckupService.pathSetter(auth);
          const response: AxiosResponse<GetCheckupResultsResponse> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${SelfCheckupService.PATH}/${SelfCheckupService.CHECK_UPS}/:exclude`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              lift: {
                id: liftId,
              },
              selChkYm,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addCheckup(
    {
      liftId,
      liftMntCpnyId,
      selChkUserId,
      selChkBgnAt,
      selChkEndAt,
      selChkYm,
      liftSelChkItemRslts,
    }: AddSelfCheckUpRequest,
    auth: Auth,
  ): Promise<GetLiftSelfCheckupsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SelfCheckupService.pathSetter(auth);
          const response: AxiosResponse<GetLiftSelfCheckupsResponse> = await axios({
            method: "POST",
            url: `${API_SERVER_URL}/${SelfCheckupService.PATH}/${SelfCheckupService.CHECK_UPS}/:includeApi`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              lift: {
                id: liftId,
              },
              liftMntCpny: {
                id: liftMntCpnyId,
              },
              selChkUser: {
                id: selChkUserId,
              },
              selChkBgnAt,
              selChkEndAt,
              selChkResnCd: "GD0001",
              selChkYm,
              liftSelChkItemRslts,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async editCheckup(
    {
      liftId,
      liftMntCpnyId,
      selChkUserId,
      selChkBgnAt,
      selChkEndAt,
      selChkYm,
      selChkId,
      liftSelChkItemRslts,
    }: EditSelfCheckUpReqeust,
    auth: Auth,
  ): Promise<GetLiftSelfCheckupsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SelfCheckupService.pathSetter(auth);
          const response: AxiosResponse<GetLiftSelfCheckupsResponse> = await axios({
            method: "PATCH",
            url: `${API_SERVER_URL}/${SelfCheckupService.PATH}/${SelfCheckupService.CHECK_UPS}/${selChkId}:includeApi`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/merge-patch+json",
            },
            data: {
              id: selChkId,
              lift: {
                id: liftId,
              },
              liftMntCpny: {
                id: liftMntCpnyId,
              },
              selChkUser: {
                id: selChkUserId,
              },
              selChkBgnAt,
              selChkEndAt,
              selChkResnCd: "GD0001",
              selChkYm,
              liftSelChkItemRslts,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}
