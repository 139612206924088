import {
  RouteAction,
  ClearRoutes,
  RouteActionTypes,
  RoutesRequest,
  RoutesSuccess,
  RouteState,
  RoutesFailure,
} from "../../@types/redux/route.interface";

const { ROUTE_REQUEST, ROUTE_SUCCESS, ROUTE_FAILURE, CLEAR_ROUTES } = RouteActionTypes;

export const routesRequest = (places: Place[]): RoutesRequest => ({
  type: ROUTE_REQUEST,
  payload: { places },
});

export const routesSuccess = (routes: Route[]): RoutesSuccess => ({
  type: ROUTE_SUCCESS,
  payload: { routes },
});

export const clearRoutes = (): ClearRoutes => ({
  type: CLEAR_ROUTES,
  payload: {},
});

export const routesFailure = (): RoutesFailure => ({
  type: ROUTE_FAILURE,
  payload: {},
});

const initialState: RouteState = {
  routes: [],
  status: "ok",
};

const RouteReducer = (state = initialState, action: RouteAction): RouteState => {
  switch (action.type) {
    case ROUTE_REQUEST:
      return { ...state, status: "loading" };
    case ROUTE_SUCCESS:
      return { ...state, status: "ok", routes: action.payload.routes };
    case ROUTE_FAILURE:
      return { ...state, status: "error", routes: [] };
    case CLEAR_ROUTES:
      return { ...state, routes: [] };
    default:
      return state;
  }
};

export default RouteReducer;
