import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const useTableParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSearchParams = useCallback(
    (name: string, value: string | number) => {
      setSearchParams((prev) => {
        if (value) prev.set(name, value.toString());
        else prev.delete(name);
        return prev;
      });
    },
    [setSearchParams],
  );

  const onClearSearchParams = useCallback(() => {
    setSearchParams(undefined);
  }, [setSearchParams]);

  const onChangeOption = useCallback(
    (newOption: number, key: string) => {
      onChangeSearchParams(key, newOption.toString());
    },
    [onChangeSearchParams],
  );

  const searchValue = searchParams.get("searchValue") || "";
  const page = searchParams.get("page") || 0;
  const rowsPerPage = searchParams.get("rowsPerPage") || 20;
  const sortKey = searchParams.get("sortKey") || "";
  const sortDirection = searchParams.get("sortDirection") as Order;
  const searchKey = searchParams.get("searchKey") || "";

  const onChangeOrder = useCallback(
    (event: any, property: string) => {
      const isAsc = sortKey === property && sortDirection === "asc";
      const newDirection = isAsc ? "desc" : "asc";
      setSearchParams((prev) => {
        prev.set("sortKey", property);
        prev.set("sortDirection", newDirection);
        return prev;
      });
    },
    [sortKey, sortDirection, setSearchParams],
  );

  const onSearch = useCallback(
    (inputValue: string) => {
      onChangeSearchParams("page", 0);
      onChangeSearchParams("searchValue", inputValue);
    },
    [onChangeSearchParams],
  );

  const onChangeSelect = useCallback(
    (name: string, option: SelectOption) => {
      onChangeSearchParams(name, option.value);
    },
    [onChangeSearchParams],
  );

  return {
    searchParams,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    onChangeSearchParams,
    onClearSearchParams,
    onChangeSelect,
    onChangeOption,
    onChangeOrder,
    onSearch,
  };
};

export default useTableParams;
