import { Delete, Edit, Save } from "@mui/icons-material";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/reducers";
import { enqueueSnackbar } from "../../../redux/reducers/snackbar";
import MaintenanceService, { AddMaintenceTaskBody } from "../../../services/MaintenanceService";
import SettingService from "../../../services/SettingService";
import { parseDate } from "../../../utils/parse";
import Calender from "../../Calender";
import InfoBox from "../../Info/InfoBox";
import InfoList from "../../Info/InfoList";
import Input from "../../Input";
import Select from "../../Select";

interface Props {
  liftId: string | number;
  liftType: string;
  activeTask?: MaintenanceTask;
  onAfterSubmit: () => void;
  onClear: () => void;
}

type InitialValueType = {
  type: TaskType;
  itemId?: number;
  categoryId?: number;
  troubleYmd: string | null;
  workYmd: string | null;
  workDtl: string;
  cost: number;
};

const initialValue: InitialValueType = {
  type: "FIX",
  itemId: undefined,
  categoryId: undefined,
  troubleYmd: null,
  workYmd: null,
  workDtl: "",
  cost: 0,
};

const MaintenanceDetailForm = ({ liftId, liftType, activeTask, onAfterSubmit, onClear }: Props) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<InitialValueType>(initialValue);
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  const typeOptions: SelectOption[] = [
    { value: "FIX", label: lang.MaintenanceDetailForm.수리 },
    { value: "INS", label: lang.MaintenanceDetailForm.점검 },
    { value: "REP", label: lang.MaintenanceDetailForm.교체 },
    { value: "ETC", label: lang.MaintenanceDetailForm.기타 },
  ];

  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
  const [itemsOptions, setItemOptions] = useState<SelectOption[]>([]);

  const { cost, type, categoryId, itemId, troubleYmd, workYmd, workDtl } = values;

  const getCategories = useCallback(async () => {
    if (!auth.auth) return;
    const liftTypes = await SettingService.getLiftTypes(auth);
    const liftTypeId = liftTypes._embedded?.equipmentCategories.find(
      (cate) => cate.code === liftType,
    )?.id;
    if (!liftTypeId) return;
    const response = await SettingService.getCategories(liftTypeId, auth);
    if (response._embedded) {
      const categories = response._embedded.equipmentCategories;
      const newOptions = categories.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryOptions(newOptions);
    }
  }, [auth, liftType]);

  const getItems = useCallback(
    async (parentId: number) => {
      if (!auth.auth) return;
      setItemOptions([]);
      const response = await SettingService.getItems(parentId, auth);
      if (response._embedded) {
        const items = response._embedded.equipmentCategories;
        const newOptions = items.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setItemOptions(newOptions);
      }
    },
    [auth],
  );

  // 유지보수 일지 선택시, 수정 내용
  useEffect(() => {
    (async () => {
      if (activeTask) {
        setValues(initialValue);
        if (activeTask.equipmentCtgr?.parent) {
          await getItems(activeTask.equipmentCtgr.parent.id);
          setValues({
            categoryId: activeTask.equipmentCtgr.parent.id,
            type: activeTask.type,
            troubleYmd: activeTask.troubleYmd,
            workYmd: activeTask.workYmd,
            cost: activeTask.cost,
            workDtl: activeTask.workDtl,
            itemId: activeTask.equipmentCtgr.id,
          });
        } else {
          setValues((prev) => ({
            ...prev,
            type: activeTask.type,
            troubleYmd: activeTask.troubleYmd,
            workYmd: activeTask.workYmd,
            cost: activeTask.cost,
            workDtl: activeTask.workDtl,
          }));
        }
      }
    })();
  }, [activeTask, getItems]);

  // 승강기 카테고리 불러오기
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onChangeSelect = useCallback(
    (name: string, option: SelectOption) => {
      setValues((prev) => ({ ...prev, [name]: option.value }));
      if (name === "categoryId") {
        getItems(Number(option.value));
      }
    },
    [getItems],
  );

  const onChangeDate = useCallback((name: string, date: Date) => {
    setValues((prev) => ({ ...prev, [name]: parseDate(date) }));
  }, []);

  const onChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (!auth || !itemId || !troubleYmd || !workYmd) return;

      const request: AddMaintenceTaskBody = {
        type,
        troubleYmd,
        workYmd,
        cost,
        workDtl,
        equipmentCtgr: { id: itemId },
        worker: {
          id: auth.id,
        },
      };

      if (activeTask) {
        await MaintenanceService.editMaintenanceTask(liftId, activeTask.id, request, auth);
      } else {
        await MaintenanceService.addMaintenanceTask(liftId, request, auth);
      }
      setValues(initialValue);
      onAfterSubmit();
    } catch (error: any) {
      dispatch(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
    }
  };

  const handleRemove = async () => {
    try {
      if (!auth || !activeTask) return;
      await MaintenanceService.removeMaintenanceTask(activeTask.id, auth);
      setValues(initialValue);
      onAfterSubmit();
    } catch (error: any) {
      dispatch(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
    }
  };

  const handleClear = () => {
    onClear();
    setValues(initialValue);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="subtitle2" gutterBottom>
        {lang.MaintenanceDetailForm.유지보수_일지}
      </Typography>
      <InfoList>
        <InfoBox title={lang.MaintenanceDetailForm.구분}>
          <Select options={typeOptions} value={type} onChange={onChangeSelect} name="type" />
        </InfoBox>
        <InfoBox title={lang.MaintenanceDetailForm.분류}>
          {categoryOptions.length && (
            <Select
              options={categoryOptions}
              value={categoryId}
              onChange={onChangeSelect}
              name="categoryId"
              required
            />
          )}
        </InfoBox>
        <InfoBox title={lang.MaintenanceDetailForm.품목}>
          {itemsOptions.length ? (
            <Select
              options={itemsOptions}
              value={itemId}
              name="itemId"
              onChange={onChangeSelect}
              required
            />
          ) : (
            <Select options={itemsOptions} value={itemId} name="equipmentCtgr" disabled />
          )}
        </InfoBox>
        <InfoBox title={lang.MaintenanceDetailForm.발생일}>
          <Calender
            onChange={onChangeDate}
            value={troubleYmd ? new Date(troubleYmd) : undefined}
            name="troubleYmd"
            required
            maxDate={new Date()}
          />
        </InfoBox>
        <InfoBox title={lang.MaintenanceDetailForm.작업일}>
          <Calender
            onChange={onChangeDate}
            value={workYmd ? new Date(workYmd) : undefined}
            name="workYmd"
            required
          />
        </InfoBox>
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={35}
            sx={{ bgcolor: (theme) => theme.palette.grey[100] }}
          >
            <Typography variant="subtitle2">{lang.MaintenanceDetailForm.내역}</Typography>
          </Box>
          <Divider />
          <Paper square elevation={0} sx={{ p: 1 }}>
            <Input
              value={workDtl}
              name="workDtl"
              type="number"
              multiline
              fullWidth
              onChange={onChangeInput}
            />
          </Paper>
        </Box>
        <InfoBox title={lang.MaintenanceDetailForm.비용}>
          <Input
            value={cost}
            name="cost"
            type="number"
            onChange={onChangeInput}
            required
            fullWidth
          />
        </InfoBox>
        {activeTask && (
          <InfoBox title={lang.MaintenanceDetailForm.등록자}>
            <Typography variant="body2">{activeTask.worker.name}</Typography>
          </InfoBox>
        )}
      </InfoList>
      <Box pt={2} textAlign="right">
        {activeTask ? (
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ mr: 1 }}
              startIcon={<Delete />}
              size="small"
              onClick={handleRemove}
            >
              {lang.MaintenanceDetailForm.삭제}
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mr: 1 }}
              color="secondary"
              size="small"
              startIcon={<Edit />}
            >
              {lang.MaintenanceDetailForm.수정}
            </Button>
            <Button onClick={handleClear} size="small">
              {lang.MaintenanceDetailForm.신규_등록}
            </Button>
          </>
        ) : (
          <Button variant="contained" type="submit" startIcon={<Save />} size="small">
            {lang.MaintenanceDetailForm.등록}
          </Button>
        )}
      </Box>
    </Box>
  );
};

MaintenanceDetailForm.defaultProps = {
  activeTask: undefined,
};

export default MaintenanceDetailForm;
