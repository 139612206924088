import { Grid, GridProps } from "@mui/material";
import React from "react";

interface TabPanelProps extends GridProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, index, value, ...restProps }: TabPanelProps) => {
  if (index !== value) return null;
  return <Grid {...restProps}>{children}</Grid>;
};

export default TabPanel;
