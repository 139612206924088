import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image } from "@mui/icons-material";
import { RootState } from "../../../redux/reducers";
import { setStatus } from "../../../redux/reducers/config";
import StateService from "../../../services/StateService";
import { enqueueSnackbar } from "../../../redux/reducers/snackbar";

interface Props {
  // eslint-disable-next-line react/require-default-props
  //   liftType?: Lift["liftType"];
  liftItlpc?: string;
  //   sensorResult: {
  //     vibrations: LiftVibration[];
  //     noises: LiftNoises[];
  //     esChains: EsChain[];
  //   };
  controllerDiagnosis?: ControllerDiagnosis;
}

const DiagnosisDetailESAnimation = ({ liftItlpc, controllerDiagnosis }: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  const [liftStatus, setLiftStatus] = useState<LiftStatus>();

  const fetchLiftStatus = useCallback(async () => {
    try {
      if (!auth.auth) return;
      const response = await StateService.getLiftStatus(Number(id), auth);
      if (response) setLiftStatus(response);
      else setLiftStatus(undefined);
      // console.log(`${response[0].doorStatus} / ${response[0].opStatus}`);
    } catch (error: any) {
      console.log(error);
      dispatch(enqueueSnackbar({ message: error, options: { variant: "error" } }));
    }
  }, [id, auth, dispatch]);

  useEffect(() => {
    fetchLiftStatus(); // 초기 데이터 가져오기

    const intervalId = setInterval(() => {
      fetchLiftStatus(); // 5초마다 데이터 갱신
    }, 5000);

    return () => {
      // 컴포넌트가 언마운트될 때 clearInterval로 인터벌 정리
      clearInterval(intervalId);
    };
  }, [fetchLiftStatus]);

  const door = useCallback(
    (status: LiftStatus | undefined, Itlpc: string | undefined): JSX.Element => {
      /** 한글 status 바꿔야함 해야함 ?? */
      // 에스컬레이터 상승상태
      if (status === undefined) {
        return <img src="/es/stop.gif" alt="" />;
      }
      if (status.opStatus === "상승") {
        return <img src="/es/up.gif" alt="" />;
      }
      // 에스컬레이터 하강 상태
      if (status.opStatus === "하강") {
        return <img src="/es/down.gif" alt="" />;
      }
      return <img src="/es/stop.gif" alt="" />;
    },
    [],
  );

  return (
    <div className="elevator_box">
      <div style={{ width: "600px" }}>{door(liftStatus, liftItlpc)}</div>
    </div>
  );
};

DiagnosisDetailESAnimation.defaultProps = {
  //   liftType: undefined,
  liftItlpc: undefined,
  controllerDiagnosis: undefined,
};

export default DiagnosisDetailESAnimation;
