import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dialog, DialogActions, Divider } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
// import Map from "./Map";
import Paper from "../Paper";
// import MapSidebar from "./MapSidebar";
import { RootState } from "../../redux/reducers";
import { toggleMapDialog } from "../../redux/reducers/config";
import MapMenu from "./MapMenu";
// import BuildingMarker from "./markers/BuildingMarker";
// import BuildingInfo from "./markers/BuildingInfo";
// import PathMarker from "./markers/PathMarker";
// import { clearPaths } from "../../redux/reducers/path";
// import { clearRoutes } from "../../redux/reducers/route";
import { getDashboardContentsRequest } from "../../redux/reducers/dashboard";
import KakaoMap from "./KakaoMap";

const MapDialog = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const { mapDialog } = useSelector((state: RootState) => state.config);
  // const { buildings, info } = useSelector((state: RootState) => state.dashboard);
  // const { paths } = useSelector((state: RootState) => state.path);
  const [menu, setMenu] = useState<{ x: number; y: number; coords: Coords } | null>(null);

  // const onContextMenu = useCallback(async (event: PointerEvent, coords: Coords) => {
  //   setMenu({ x: event.x, y: event.y, coords });
  // }, []);

  const onCloseMenu = useCallback(() => setMenu(null), []);

  const handleClose = () => {
    dispatch(toggleMapDialog());
    // dispatch(clearPaths());
    // dispatch(clearRoutes());
  };

  useEffect(() => {
    if (!auth || auth.auth === "O") return;
    dispatch(getDashboardContentsRequest());
  }, [auth, dispatch]);

  return (
    <Dialog open={mapDialog} onClose={handleClose} maxWidth="lg" keepMounted disablePortal>
      <Box display="flex" width={1100} height="80vh">
        {/* <MapSidebar /> */}
        <Box flex={3} p={2}>
          <Paper height="100%">
            <KakaoMap />
            {/* <Map onContextMenu={onContextMenu}>
              {buildings.map((building) => (
                <BuildingMarker building={building} key={building.id} />
              ))}
              {info && <BuildingInfo building={info} />}
              {paths.map((path, index) => (
                <PathMarker index={index} place={path.place} key={path.id} />
              ))}
            </Map> */}
          </Paper>
        </Box>
      </Box>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>닫기</Button>
      </DialogActions>
      <MapMenu menu={menu} onClose={onCloseMenu} />
    </Dialog>
  );
};

export default MapDialog;
