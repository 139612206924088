import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";

interface GetAllSensorsResponse extends ServerResponse {
  _embedded?: {
    liftsensors: Sensor[];
  };
}

interface GetAllSensorsRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: SortDirectionType;
  searchKey?: string;
  searchValue?: string | number;
  gatewayYn?: string;
  sensorType?: string;
  exact?: boolean;
}

export interface ConnectSensorRequest {
  id: number;
  lift: {
    id: number | string;
  };
  sensorPosition: SensorPosition;
  axial: SensorDirection;
  radial: SensorDirection;
  vertical: SensorDirection;
}

class SensorService {
  private static SENSOR = "liftSensors";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") SensorService.PATH = "api";
    else SensorService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getAllSensors(
    {
      page,
      size,
      sortKey,
      sortDirection,
      searchKey,
      searchValue,
      gatewayYn,
      sensorType,
      exact,
    }: GetAllSensorsRequest,
    auth: Auth,
  ): Promise<GetAllSensorsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SensorService.pathSetter(auth);
          const response: AxiosResponse<GetAllSensorsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SensorService.PATH}/${SensorService.SENSOR}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              sort: sortKey ? `${sortKey},${sortDirection}` : undefined,
              ...(gatewayYn && {
                instlYn: gatewayYn,
              }),
              ...(searchKey &&
                searchValue && {
                  [searchKey]: exact ? searchValue : `contains(${searchValue})`,
                }),
              ...(sensorType && {
                sensorType,
              }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addSensor(sensor: Sensor, auth: Auth): Promise<Sensor> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SensorService.pathSetter(auth);
          const response: AxiosResponse<Sensor> = await axios({
            method: "post",
            url: `${API_SERVER_URL}/${SensorService.PATH}/${SensorService.SENSOR}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: sensor,
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getSensor(id: number | string, auth: Auth): Promise<Sensor> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SensorService.pathSetter(auth);
          const response: AxiosResponse<Sensor> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SensorService.PATH}/${SensorService.SENSOR}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async updateSensor(id: number, sensor: Sensor, auth: Auth): Promise<Sensor> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SensorService.pathSetter(auth);
          const response: AxiosResponse<Sensor> = await axios({
            method: "patch",
            url: `${API_SERVER_URL}/${SensorService.PATH}/${SensorService.SENSOR}/${id}`,
            data: sensor,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async connectLift(request: ConnectSensorRequest, auth: Auth): Promise<Sensor> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SensorService.pathSetter(auth);
          const response: AxiosResponse<Sensor> = await axios({
            method: "patch",
            url: `${API_SERVER_URL}/${SensorService.PATH}/${SensorService.SENSOR}/${request.id}:updateLift`,
            data: request,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async disconnectLift(id: number, auth: Auth): Promise<Sensor> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SensorService.pathSetter(auth);
          const response: AxiosResponse<Sensor> = await axios({
            method: "patch",
            url: `${API_SERVER_URL}/${SensorService.PATH}/${SensorService.SENSOR}/${id}:updateLift`,
            data: {
              id,
            },
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getConnectedSensors(
    liftIds: number[],
    auth: Auth,
  ): Promise<GetAllSensorsResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = SensorService.pathSetter(auth);
          const request: { [key: string]: string } = {};
          liftIds.forEach((id) => {
            request["lift.id"] = `or(${id})`;
          });
          const response: AxiosResponse<GetAllSensorsResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${SensorService.PATH}/${SensorService.SENSOR}`,
            params: request,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default SensorService;
