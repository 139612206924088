import axios, { AxiosResponse } from "axios";
import { API_SERVER_URL } from "../utils/const";
import { Auth } from "./AuthService";
import { GetMaintenanceListResponse } from "./MaintenanceService";

interface GetUserListResponse {
  page: PageData;
  _embedded?: {
    users: User[];
  };
}

export interface GetRelatedSitesResponse extends ServerResponse {
  _embedded?: {
    sites: Site[];
  };
}

export interface GetUserListRequest {
  page?: number | string;
  size?: number | string;
  sortKey?: string;
  sortDirection?: "asc" | "desc";
  enabledYn?: string | boolean;
  searchKey?: string;
  searchValue?: string;
  exact?: boolean;
  deletedYn?: boolean;
}

class UserService {
  private static USER = "users";

  private static PATH = "api/admin";

  private static pathSetter(auth: Auth) {
    if (auth.auth !== "A") UserService.PATH = "api";
    else UserService.PATH = "api/admin";
    return auth.id_token;
  }

  public static async getUserList(
    {
      page,
      size,
      sortKey,
      sortDirection,
      enabledYn,
      searchKey,
      searchValue,
      exact,
      deletedYn = false,
    }: GetUserListRequest,
    auth: Auth,
  ): Promise<GetUserListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<GetUserListResponse> = await axios({
            method: "GET",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page,
              size,
              sort: sortKey ? `${sortKey},${sortDirection}` : undefined,
              deletedYn,
              ...(enabledYn && {
                enabledYn,
              }),
              ...(searchKey &&
                searchValue && { [searchKey]: exact ? searchValue : `contains(${searchValue})` }),
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getUser(id: number | string, auth: Auth): Promise<User> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<User> = await axios({
            method: "get",
            url:
              auth.auth === "O"
                ? `${API_SERVER_URL}/${UserService.PATH}/myInfo`
                : `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async checkId(loginId: string, auth: Auth): Promise<boolean> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<boolean> = await axios({
            method: "get",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/checkDup`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              loginId,
            },
          });
          resolve(!response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async checkEmail(email: string, auth: Auth): Promise<boolean> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<boolean> = await axios({
            method: "get",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/checkDupEmail`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              email: encodeURI(email),
            },
          });
          resolve(!response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async addUser(user: User, auth: Auth): Promise<User> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<User> = await axios({
            method: "post",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}`,
            data: user,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async updateUser(id: number | string, user: User, auth: Auth): Promise<User> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<User> = await axios({
            method: "patch",
            url:
              auth.auth === "O"
                ? `${API_SERVER_URL}/${UserService.PATH}/myInfo`
                : `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/${id}`,
            data: user,
            headers: {
              "Content-type": "application/merge-patch+json",
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async removeUser(id: number | string, auth: Auth): Promise<User> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<User> = await axios({
            method: "DELETE",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getUserCount(auth: Auth, enabledYn: boolean): Promise<number> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<number> = await axios({
            method: "get",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/count`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              enabledYn,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getRelatedSites(
    id: User["id"] | string,
    auth: Auth,
  ): Promise<GetRelatedSitesResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<GetRelatedSitesResponse> = await axios({
            method: "get",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/${id}:sites`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getRelatedTasks(
    id: User["id"] | string,
    auth: Auth,
  ): Promise<GetMaintenanceListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<GetMaintenanceListResponse> = await axios({
            method: "get",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}/${id}:liftMntTasks`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }

  public static async getWorkers(companyId: number, auth: Auth): Promise<GetUserListResponse> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const token = UserService.pathSetter(auth);
          const response: AxiosResponse<GetUserListResponse> = await axios({
            method: "get",
            url: `${API_SERVER_URL}/${UserService.PATH}/${UserService.USER}`,
            params: {
              "company.id": companyId,
              "role.name": "CW",
              deletedYn: false,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          resolve(response.data);
        } catch (err) {
          reject(err);
        }
      })();
    });
  }
}

export default UserService;
