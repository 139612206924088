import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoBox from "../../../Info/InfoBox";
import InfoList from "../../../Info/InfoList";
import InfoRow from "../../../Info/InfoRow";
import Paper from "../../../Paper";

const StyledHead = styled(Grid)(({ theme }) => ({
  background: theme.palette.grey[100],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledCell = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledRow = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

interface Props {
  noises: LiftNoises[];
}

const DiagnosisDetailNoises = ({ noises }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentState", { returnObjects: true });

  return (
    <>
      {noises.map((noise) => (
        <Box pt={2} key={noise.id}>
          <InfoList>
            <InfoRow>
              <InfoBox title={lang.DiagnosisDetailNoises.센서ID} flex={1}>
                <Typography variant="body2">{noise.id}</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailNoises.구분} flex={1}>
                <Typography variant="body2">{lang.DiagnosisDetailNoises.소음}</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailNoises.에러코드} flex={1}>
                <Typography variant="body2">{noise.errCode}</Typography>
              </InfoBox>
              <InfoBox title={lang.DiagnosisDetailNoises.진단_요약} flex={1}>
                <Typography variant="body2">{noise.dcode?.diagnosisMsg}</Typography>
              </InfoBox>
            </InfoRow>
          </InfoList>
          <Paper sx={{ mt: 2 }}>
            <Grid container>
              <StyledRow container item md={12}>
                <StyledHead item md={3}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailNoises.RMS}</Typography>
                </StyledHead>
                <StyledHead item md={3}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailNoises.Skew}</Typography>
                </StyledHead>
                <StyledHead item md={3}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailNoises.Kurtosis}</Typography>
                </StyledHead>
                <StyledHead item md={3}>
                  <Typography variant="subtitle2">{lang.DiagnosisDetailNoises.이상소음}</Typography>
                </StyledHead>
              </StyledRow>
            </Grid>
            <Grid container item md={12}>
              <StyledCell item md={3}>
                <Typography variant="subtitle2">{noise.rmsVal}</Typography>
              </StyledCell>
              <StyledCell item md={3}>
                <Typography variant="subtitle2">{noise.skewVal}</Typography>
              </StyledCell>
              <StyledCell item md={3}>
                <Typography variant="subtitle2">{noise.kurtosisVal}</Typography>
              </StyledCell>
              <StyledCell item md={3}>
                <Typography variant="subtitle2">{noise.abnrmlYn ? "Y" : "N"}</Typography>
              </StyledCell>
            </Grid>
          </Paper>
        </Box>
      ))}
    </>
  );
};

export default DiagnosisDetailNoises;
