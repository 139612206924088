import { Box, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../redux/reducers";
import Header from "./Header";
import Loading from "./Loading";
import Sidebar from "./Sidebar";

const SIDE_BAR_WIDTH = 240;

const Main = styled(Box)(() => ({
  position: "relative",
  flexGrow: 1,
}));

const Container = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Layout = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.auth) navigate("/login");
  }, [auth, navigate, dispatch]);

  if (!auth.auth) return null;

  return (
    <Box display="flex">
      <Sidebar width={SIDE_BAR_WIDTH} />
      <Main>
        <Header />
        <Container container spacing={2}>
          <Loading />
          <Outlet />
        </Container>
      </Main>
    </Box>
  );
};

export default Layout;
