import { ArrowBack } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LiftCheckDate from "../../components/Lift/LiftCheckDate";
import LiftInfo from "../../components/Lift/LiftInfo";
import LiftLatestDiagnosises from "../../components/Lift/LiftLatestDiagnosises";
import MaintenanceDetailList from "../../components/State/Maintenance/MaintenanceDetailList";
import { RootState } from "../../redux/reducers";
import { setStatus } from "../../redux/reducers/config";
import { enqueueSnackbar } from "../../redux/reducers/snackbar";
import LiftService from "../../services/LiftService";

const MaintenanceDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [lift, setLift] = useState<Lift>();
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("common", { returnObjects: true });

  const getLift = useCallback(async (): Promise<Lift | undefined> => {
    try {
      if (!id || !auth) return;
      dispatch(setStatus("loading"));
      const response = await LiftService.getLift(id, auth);
      setLift(response);
      dispatch(setStatus("ok"));
    } catch (error: any) {
      dispatch(setStatus("error"));
      dispatch(enqueueSnackbar({ message: error.message, options: { variant: "error" } }));
    }
  }, [id, auth, dispatch]);

  useEffect(() => {
    getLift();
  }, [getLift]);

  const onAfterSubmit = useCallback((fdrmChkYmd: string) => {
    setLift((prev) => (prev ? { ...prev, fdrmChkYmd } : undefined));
  }, []);

  const navigate = useNavigate();
  const handleClickBack = () => navigate("/state/?tabIndex=1");

  if (!id) return null;

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>
      <Grid item lg={8} md={12}>
        <LiftInfo lift={lift} syncCallback={getLift} />
      </Grid>
      <Grid item lg={4} md={12}>
        <LiftLatestDiagnosises liftId={id} />
        <LiftCheckDate liftId={id} initValue={lift?.fdrmChkYmd} onAfterSubmit={onAfterSubmit} />
      </Grid>
      <MaintenanceDetailList lift={lift} />
    </>
  );
};

export default MaintenanceDetail;
