import React, { useCallback, useMemo, useState } from "react";
import { Remove, Save } from "@mui/icons-material";
import { Typography, Box, Button, debounce } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfoList from "../Info/InfoList";
import InfoBox from "../Info/InfoBox";
import InfoRow from "../Info/InfoRow";
import { RootState } from "../../redux/reducers";
// import LiftService from "../../services/LiftService";
import SearchDialog from "../SearchDialog";
import SiteService from "../../services/SiteService";

interface Props {
  siteId?: number;
  owner?: User;
  showButtons?: boolean;
  onAfterSubmit?: () => {};
}

const SiteOwnerInfo = ({ siteId, owner, showButtons = false, onAfterSubmit }: Props) => {
  const { t } = useTranslation();
  const lang: any = t("componentSite", { returnObjects: true });
  const common: any = t("common", { returnObjects: true });
  // eslint-disable-next-line
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [dialog, toggleDialog] = useState(false);
  const onToggleDialog = useCallback(() => toggleDialog((prev) => !prev), []);

  const onClickItem = async (item: User) => {
    if (!auth || !siteId) return;
    try {
      await SiteService.addOwner(siteId, Number(item.id), auth);
      onAfterSubmit?.();
    } catch (e) {
      // eslint-disable-next-line
      alert("error");
      // eslint-disable-next-line
      console.log(e);
    }
  };

  //   const company = lift?.liftMntContracts?.[0];
  const handleClickAdd = () => {
    toggleDialog(true);
  };
  const handleClickRemove = async () => {
    if (!auth || !siteId) return;
    try {
      await SiteService.deleteOwner(siteId, auth);
      onAfterSubmit?.();
    } catch (e) {
      // eslint-disable-next-line
      alert("error");
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const onSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        if (!auth.auth) return;
        const response = await SiteService.getAllOwner(
          {
            searchKey: "name",
            searchValue: value,
          },
          auth,
        );
        if (response._embedded) {
          const newResults = response._embedded.users.map((user) => ({
            id: user.id,
            title: `${user.name}(${user.loginId})`,
            subtitle: user.telNo,
            data: user,
          }));
          setSearchResults(newResults);
        }
      }, 500),
    [auth],
  );
  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value),
    [onSearch],
  );

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {lang.SiteOwnerInfo.관리주체_정보}
      </Typography>
      <InfoList>
        <InfoRow>
          <InfoBox title={lang.SiteOwnerInfo.관리주체명} flex={1}>
            <Typography variant="body2">{owner?.name}</Typography>
          </InfoBox>
          <InfoBox title={lang.SiteOwnerInfo.관리주체_연락처} flex={1}>
            <Typography variant="body2">{owner?.telNo}</Typography>
          </InfoBox>
        </InfoRow>
      </InfoList>
      <Box pt={2} textAlign="right">
        <Button
          size="small"
          variant="contained"
          disabled={Boolean(owner) && showButtons}
          sx={{ mr: 1 }}
          startIcon={<Save />}
          onClick={handleClickAdd}
        >
          {common.등록}
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={Boolean(!owner) && showButtons}
          color="error"
          startIcon={<Remove />}
          onClick={handleClickRemove}
        >
          {common.삭제}
        </Button>
      </Box>

      <SearchDialog
        open={dialog}
        list={searchResults}
        onClose={onToggleDialog}
        onChange={onChangeInput}
        onClickItem={onClickItem}
      />
    </>
  );
};

SiteOwnerInfo.defaultProps = {
  siteId: undefined,
  owner: undefined,
  showButtons: false,
  onAfterSubmit: undefined,
};

export default SiteOwnerInfo;
