import { format } from "date-fns";

const stopStatus = ["None", "Power_Out", "Emergency_Stop", "Etc_Error"];

export function parseValue(value: any) {
  // if (Date.parse(value) && typeof value === "string" && value.includes("T")) {
  //   console.log(value);
  //   const date = new Date(value);
  //   console.log(date.toString());
  //   return format(date, "yyyy-MM-dd");
  // }
  // if (isDate(value)) {
  //   const date = new Date(value);
  //   return format(date, "yyyy-MM-dd");
  // }
  if (typeof value === "boolean") {
    return value ? "O" : "X";
  }
  return value;
}

export function parseKSTDate(dateString: string, showDateTime = true) {
  if (!dateString) return "";

  const parsedDate = dateString.replace("T", " ").replace("Z", "");

  if (!showDateTime) {
    return format(new Date(parsedDate), "yyyy-MM-dd");
  }

  return parsedDate;
}

export function parseDate(dateString?: string | Date | null) {
  try {
    if (!dateString) {
      throw new Error();
    }
    const date = new Date(dateString);
    return format(date, "yyyy-MM-dd");
  } catch (error) {
    return undefined;
  }
}

export function isStatusStop(state: string) {
  if (state.includes("PO") || stopStatus.indexOf(state) > -1) {
    return "status_stop";
  }
  return "";
}

export function getTimeAndMinutes(dateString?: string | Date | null) {
  try {
    if (!dateString) {
      throw new Error();
    }
    const date = new Date(dateString);
    return format(date, "HH:mm");
  } catch (error) {
    return undefined;
  }
}

export function parseTaskType(type: TaskType): string {
  switch (type) {
    case "ETC":
      return "기타";
    case "FIX":
      return "수리";
    case "INS":
      return "점검";
    case "REP":
      return "교체";
    default:
      return type;
  }
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface SeverRequest {
  [key: string]: any;
}

export function trimRequest(request: SeverRequest): SeverRequest {
  const newRequest = { ...request };

  Object.keys(newRequest).forEach((key) => {
    if (typeof newRequest[key] === "string") {
      newRequest[key] = newRequest[key].trim();
    }
  });

  return newRequest;
}

export const parseStatus = (status?: DiagnosisStatus | string): string => {
  if (!status) return "";
  switch (status) {
    case "00":
      return "정상";
    case "01":
      return "주의";
    case "11":
      return "경고";
    default:
      return "";
  }
};

export const getMaintenanceCompany = (lift?: Lift) => {
  const temp: { main?: LiftMaintenanceContract; sub?: LiftMaintenanceContract } = {};
  if (!lift || !lift.liftMntContracts) return temp;

  lift.liftMntContracts.forEach((contract) => {
    if (contract.type === "SUB") temp.sub = contract;
    else temp.main = contract;
  });
  return temp;
};

export const getEnumToOptions = (enums: EnumData[]): SelectOption[] =>
  enums.map((e) => ({ label: e.title, value: e.code }));

export const convertToMapSite = (site: Site): MapSite => ({
  siteId: site.id,
  buldNm: site.buldNm,
  lat: site.lat,
  lng: site.lng,
});

export const comma = (num: number): string => {
  return Math.round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getQuarter = (): { year: number; quarter: number } => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
  const currentYear = today.getFullYear();
  let previousQuarterYear;
  let previousQuarter;

  if (currentMonth >= 1 && currentMonth <= 3) {
    previousQuarterYear = currentYear - 1;
    previousQuarter = 4;
  } else if (currentMonth >= 4 && currentMonth <= 6) {
    previousQuarterYear = currentYear;
    previousQuarter = 1;
  } else if (currentMonth >= 7 && currentMonth <= 9) {
    previousQuarterYear = currentYear;
    previousQuarter = 2;
  } else if (currentMonth >= 10 && currentMonth <= 12) {
    previousQuarterYear = currentYear;
    previousQuarter = 3;
  }
  return {
    year: previousQuarterYear ?? 0,
    quarter: previousQuarter ?? 0,
  };
};

export default {};
