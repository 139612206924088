import { NavigateFunction } from "react-router-dom";
import { Auth } from "../../services/AuthService";

export enum AuthActionTypes {
  SET_STATUS = "SET_STATUS",
  SET_USER = "SET_USER",
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_NETWORK_ERROR = "LOGIN_NETWORK_ERROR",
  LOGIN_AUTH_FAIL = "LOGIN_AUTH_FAIL",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",
}

export type AuthAction =
  | SetUser
  | LoginRequest
  | NetworkError
  | AuthFailError
  | LoginSuccess
  | LoginFailure
  | Logout;

export interface AuthState {
  auth: Auth;
  status: "ok" | "loading" | "network" | "auth" | "error";
}

export interface SetStatus {
  payload: { status: Status };
  type: AuthActionTypes.SET_STATUS;
}

export interface SetUser {
  payload: { user: User };
  type: AuthActionTypes.SET_USER;
}
export interface LoginRequest {
  payload: { loginId: string; password: string; navigate: NavigateFunction };
  type: AuthActionTypes.LOGIN_REQUEST;
}
export interface NetworkError {
  payload: {};
  type: AuthActionTypes.LOGIN_NETWORK_ERROR;
}
export interface AuthFailError {
  payload: {};
  type: AuthActionTypes.LOGIN_AUTH_FAIL;
}
export interface LoginSuccess {
  payload: { auth: Auth };
  type: AuthActionTypes.LOGIN_SUCCESS;
}
export interface LoginFailure {
  payload: {};
  type: AuthActionTypes.LOGIN_FAILURE;
}
export interface Logout {
  payload: {};
  type: AuthActionTypes.LOGOUT;
}
