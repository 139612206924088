import React, { useEffect, useState, useCallback } from "react";
import { Box, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTable from "../../hooks/useTable";
import SearchBar from "../SearchBar";
import Table from "../Table/Table";
import { setStatus } from "../../redux/reducers/config";
import LiftService from "../../services/LiftService";
import useTableParams from "../../hooks/useTableParams";
import Select from "../Select";
import SettingService from "../../services/SettingService";
import { maintenanceColumns } from "../../utils/columns";
import { RootState } from "../../redux/reducers";
import { parseTaskType } from "../../utils/parse";

const StateMaintenance = () => {
  const { t } = useTranslation();
  const lang: any = t("selectbox", { returnObjects: true });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state.auth);
  const { data, totalElement, onChangeData } = useTable();
  const {
    searchParams,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    searchKey,
    onChangeOrder,
    onChangeOption,
    onChangeSelect,
    onClearSearchParams,
    onSearch,
  } = useTableParams();

  const searchKeyOptions: SelectOption[] = [
    { label: lang.검색조건, value: "" },
    { label: lang.승강기번호, value: "equipmentNo" },
    { label: lang.업체명, value: "liftMntContracts.liftMntCpny.name" },
    { label: lang.담당자명, value: "mntWorker.name" },
    { label: lang.담당자ID, value: "mntWorker.loginId" },
    { label: lang.건물명, value: "site.buldNm" },
  ];

  const typeOptions: SelectOption[] = [
    { label: lang.구분, value: "" },
    { label: lang.수리, value: "FIX" },
    { label: lang.점검, value: "INS" },
    { label: lang.교체, value: "REP" },
    { label: lang.기타, value: "ETC" },
  ];

  const [liftTypeOptions, setLiftTypeOptions] = useState<SelectOption[]>([
    { label: lang.승강기_구분, value: "" },
  ]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([
    { label: lang.분류, value: "" },
  ]);
  const [itemOptions, setItemOptions] = useState<SelectOption[]>([{ label: "품목", value: "" }]);

  const type = searchParams.get("type") || "";
  const liftTypeCode = searchParams.get("liftTypeCode") || "";
  const categoryName = searchParams.get("categoryName") || "";
  const itemName = searchParams.get("itemName") || "";

  useEffect(() => {
    (async () => {
      try {
        if (!auth.auth) return;
        dispatch(setStatus("loading"));
        const response = await LiftService.getMaintenanceTasks(
          {
            searchKey,
            searchValue,
            page,
            size: rowsPerPage,
            sortKey,
            sortDirection,
            type,
            liftTypeCode,
            categoryName,
            itemName,
          },
          auth,
        );
        onChangeData({
          data: response._embedded
            ? response._embedded.lifts.map((lift) => ({
                ...lift,
                ...(lift.lastMntTask && {
                  lastMntTask: { ...lift.lastMntTask, type: parseTaskType(lift.lastMntTask.type) },
                }),
              }))
            : [],
          totalPage: response.page.totalPages,
          totalElement: response.page.totalElements,
        });
        dispatch(setStatus("ok"));
      } catch (error) {
        dispatch(setStatus("error"));
      }
    })();
  }, [
    auth,
    searchKey,
    searchValue,
    page,
    rowsPerPage,
    sortKey,
    sortDirection,
    type,
    liftTypeCode,
    categoryName,
    itemName,
    dispatch,
    onChangeData,
  ]);

  useEffect(() => {
    (async () => {
      if (!auth.auth) return;
      const response = await SettingService.getLiftTypes(auth);
      if (response._embedded) {
        const newOptions = response._embedded.equipmentCategories.map((lt) => ({
          value: lt.code,
          label: lt.name,
          data: lt,
        }));
        setLiftTypeOptions([{ label: lang.승강기_구분, value: "" }, ...newOptions]);
      }
    })();
  }, [auth, lang]);

  useEffect(() => {
    (async () => {
      if (liftTypeCode) {
        const liftTypeId = liftTypeOptions.find((op) => op.value === liftTypeCode)?.data?.id;
        if (!liftTypeId || !auth) return;
        const response = await SettingService.getCategories(liftTypeId, auth);
        if (response._embedded) {
          const categories = response._embedded.equipmentCategories;
          const newOptions = categories.map((ct) => ({ value: ct.name, label: ct.name, data: ct }));
          setCategoryOptions([{ label: lang.분류, value: "" }, ...newOptions]);
        }
      }
    })();
  }, [liftTypeCode, liftTypeOptions, auth, lang]);

  useEffect(() => {
    (async () => {
      if (categoryName) {
        const categoryId = categoryOptions.find((op) => op.value === categoryName)?.data.id;
        if (!categoryId || !auth) return;
        const response = await SettingService.getItems(categoryId, auth);
        if (response._embedded) {
          const items = response._embedded.equipmentCategories;
          const newOptions = items.map((item) => ({
            value: item.name,
            label: item.name,
            data: item,
          }));
          setItemOptions([{ label: lang.품목, value: "" }, ...newOptions]);
        }
      }
    })();
  }, [categoryName, auth, categoryOptions, lang]);

  const onClickRow = (row: Lift) => navigate(`/state/maintenance/${row.id}`);

  const handleChangeSelect = useCallback(
    (name: string, option: SelectOption) => {
      if (name === "categoryName") {
        onChangeSelect("itemName", { label: lang.품목, value: "" });
      }
      onChangeSelect(name, option);
    },
    [onChangeSelect, lang],
  );

  return (
    <>
      <Box display="flex" justifyContent="end" alignItems="center" p={2}>
        <Box width={120} mr={1}>
          <Select
            value={type}
            name="type"
            options={typeOptions}
            required
            onChange={onChangeSelect}
          />
        </Box>
        <Box width={120} mr={1}>
          <Select
            value={liftTypeCode}
            name="liftTypeCode"
            options={liftTypeOptions}
            required
            onChange={handleChangeSelect}
          />
        </Box>
        <Box width={120} mr={1}>
          <Select
            value={categoryName}
            name="categoryName"
            options={categoryOptions}
            required
            onChange={handleChangeSelect}
            disabled={Boolean(!liftTypeCode)}
          />
        </Box>
        <Box width={120} mr={1}>
          <Select
            value={itemName}
            name="itemName"
            options={itemOptions}
            disabled={Boolean(!categoryName)}
            required
            onChange={handleChangeSelect}
          />
        </Box>
        <Box width={120} mr={1}>
          <Select
            value={searchKey}
            name="searchKey"
            options={searchKeyOptions}
            required
            onChange={onChangeSelect}
          />
        </Box>
        <SearchBar onSearch={onSearch} onClear={onClearSearchParams} />
      </Box>
      <Divider />
      <Table
        order={sortDirection}
        orderBy={sortKey}
        page={page}
        data={data}
        columns={maintenanceColumns}
        rowsPerPage={rowsPerPage}
        totalElement={totalElement}
        onClickRow={onClickRow}
        onChangeOption={onChangeOption}
        onChangeOrder={onChangeOrder}
      />
    </>
  );
};

export default StateMaintenance;
