import { ArrowBack, Edit } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoBox from "../../components/Info/InfoBox";
import InfoList from "../../components/Info/InfoList";
import InfoRow from "../../components/Info/InfoRow";
import SensorDetailLift from "../../components/Sensor/SensorDetailLift";
import { RootState } from "../../redux/reducers";
import GatewayService from "../../services/GatewayService";
import SensorService from "../../services/SensorService";
import useEnum from "../../hooks/useEnum";

const SensorDetail = () => {
  const { auth } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const lang: any = t("SensorDetail", { returnObjects: true });

  const navigate = useNavigate();
  const { id } = useParams();
  const [sensor, setSensor] = useState<Sensor>();
  const [gateway, setGateway] = useState<IotGateway>();

  const enumName = useEnum(auth);

  const handleClickEdit = () => {
    navigate(`/sensor/regist/${id}`, { state: sensor });
  };

  const handleClickBack = () => {
    navigate(`/sensor`);
  };

  const getSensor = useCallback(async () => {
    try {
      if (!id || !auth) return;
      const newSensor = await SensorService.getSensor(id, auth);
      setSensor(newSensor);
      if (newSensor.lift) {
        const response = await GatewayService.getAllGateways(
          {
            searchKey: "equipments.id",
            searchValue: newSensor.lift?.id,
            exact: true,
          },
          auth,
        );
        if (response._embedded) {
          setGateway(response._embedded.iotGateways[0]);
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }, [id, auth]);

  useEffect(() => {
    getSensor();
  }, [getSensor]);

  return (
    <>
      <Grid item md={12}>
        <Button onClick={handleClickBack} startIcon={<ArrowBack />}>
          {lang.목록}
        </Button>
      </Grid>
      <Grid item lg={8}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.센서_정보}
        </Typography>
        <InfoList>
          <InfoRow>
            <InfoBox title={lang.Type} flex={1}>
              <Typography variant="body2">{enumName("SensorType", sensor?.sensorType)}</Typography>
            </InfoBox>
            <InfoBox title={lang.SN} flex={1}>
              <Typography variant="body2">{sensor?.sn}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.BLE_MAC} flex={1}>
              <Typography variant="body2">{sensor?.bleMac}</Typography>
            </InfoBox>
            <InfoBox title={lang.WIFI_MAC} flex={1}>
              <Typography variant="body2">{sensor?.wifiMac}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.FWVer} flex={1}>
              <Typography variant="body2">{sensor?.fwVer}</Typography>
            </InfoBox>
            <InfoBox title={lang.Battery} flex={1}>
              <Typography variant="body2">{sensor?.btryVlt}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.Sample_Rate} flex={1}>
              <Typography variant="body2">{sensor?.sampleRate}</Typography>
            </InfoBox>
            <InfoBox title={lang.MTime} flex={1}>
              <Typography variant="body2">{sensor?.mesureTime}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.설치위치} flex={1}>
              <Typography variant="body2">
                {enumName("SensorPosition", sensor?.sensorPosition)}
              </Typography>
            </InfoBox>
            <InfoBox title={lang.축방향} flex={1}>
              <Typography variant="body2">{enumName("SensorDirection", sensor?.axial)}</Typography>
            </InfoBox>
          </InfoRow>
          <InfoRow>
            <InfoBox title={lang.반경방향} flex={1}>
              <Typography variant="body2">{enumName("SensorDirection", sensor?.radial)}</Typography>
            </InfoBox>
            <InfoBox title={lang.수직방향} flex={1}>
              <Typography variant="body2">
                {enumName("SensorDirection", sensor?.vertical)}
              </Typography>
            </InfoBox>
          </InfoRow>
        </InfoList>
        <Box display="flex" justifyContent="end" py={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickEdit}
            startIcon={<Edit />}
          >
            {lang.수정}
          </Button>
        </Box>
        <SensorDetailLift liftId={sensor?.lift?.id} />
      </Grid>
      <Grid item lg={4}>
        <Typography variant="subtitle2" gutterBottom>
          {lang.게이트웨이_정보}
        </Typography>
        <InfoList>
          <InfoBox title={lang.시리얼넘버}>
            <Typography variant="body2">{gateway?.sn}</Typography>
          </InfoBox>
          <InfoBox title={lang.BLE}>
            <Typography variant="body2">{gateway?.bleMac}</Typography>
          </InfoBox>
          <InfoBox title={lang.MAC}>
            <Typography variant="body2">{gateway?.wifiMac}</Typography>
          </InfoBox>
          <InfoBox title={lang.IP}>
            <Typography variant="body2">{gateway?.apGwIp}</Typography>
          </InfoBox>
          <InfoBox title={lang.SSID}>
            <Typography variant="body2">{gateway?.apSsid}</Typography>
          </InfoBox>
          <InfoBox title={lang.FW}>
            <Typography variant="body2">{gateway?.fwVer}</Typography>
          </InfoBox>
        </InfoList>
      </Grid>
    </>
  );
};

export default SensorDetail;
