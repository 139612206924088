import { Map } from "ol";

export enum MapActionTypes {
  SET_MAP = "SET_MAP",
}

export type MapAction = SetMap;

export interface SetMap {
  payload: { map?: Map };
  type: MapActionTypes.SET_MAP;
}

export interface MapState {
  map?: Map;
}
